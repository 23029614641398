import React from 'react';

const universalVotingIconPlay = (
  <g id="assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-47.000000, -42.000000)" fill="#FFFFFF">
      <g
        id="538-/-icons-/-general-/-play"
        transform="translate(22.000000, 22.000000)"
      >
        <path
          d="M52.07688,32.8282541 L29.70828,21.0580541 C28.74168,20.5486541 27.60768,20.5864541 26.67708,21.1480541 C25.75188,21.7060541 25.19928,22.6816541 25.19928,23.7580541 L25.19928,48.2452541 C25.19928,49.3216541 25.75188,50.2972541 26.67708,50.8570541 C27.17028,51.1540541 27.71928,51.3034541 28.27008,51.3034541 C28.76148,51.3034541 29.25288,51.1846541 29.70828,50.9452541 L52.07688,39.1750541 C53.26308,38.5504541 53.99928,37.3354541 53.99928,36.0016541 C53.99928,34.6678541 53.26308,33.4528541 52.07688,32.8282541"
          id="Combined-Shape"
        />
      </g>
    </g>
  </g>
);

export default universalVotingIconPlay;
