import React from 'react';

import { palette } from '../theme/palette/Palette';
/** fill je ili crna ili bijela na osnovu propsa */
const iconTop50 = (
  <g
    id="feedback_v2"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g
      id="top50_logo-copy"
      fill={palette.default.white}
      //  fill={fill}
    >
      <path
        d="M162.566436,145.43095 C174.800528,138.59854 181.127003,123.603862 181.127003,101.115704 C181.127003,69.3715182 168.520854,52.5588567 144.4867,52.5588567 C143.158219,52.5588567 141.864654,52.6102243 140.606203,52.712642 L152.511832,31.2448502 C153.238919,29.9337933 154.893361,29.4591746 156.207133,30.1847594 C156.653971,30.4315445 157.022122,30.798935 157.269418,31.2448502 L218.361574,141.403984 C219.088662,142.715041 218.61306,144.366065 217.299289,145.09165 C216.896333,145.314199 216.443331,145.43095 215.982781,145.43095 L162.566436,145.43095 Z M92.8921844,145.276558 C102.511351,139.919302 108.49605,129.97694 109.108444,117.183723 C109.093607,117.092338 109.099288,117.123797 109.108444,117.146301 C109.110554,117.151487 109.105105,117.088746 109.108444,117.100677 C109.117637,117.133526 109.095031,116.974937 109.153287,117.301392 C111.585487,130.930953 117.392565,140.396652 126.406964,145.43095 C104.252755,145.394679 93.0811618,145.343215 92.8921844,145.276558 Z M158.977881,101.115704 C158.977881,126.15834 151.202126,129.45033 144.4867,129.45033 C137.771275,129.45033 129.99552,126.15834 129.99552,101.115704 C129.99552,76.0730686 137.771275,72.7810789 144.4867,72.7810789 C151.202126,72.7810789 158.977881,76.0730686 158.977881,101.115704 Z"
        id="Shape"
      />
      <path
        d="M47.1772522,59.2377261 L41.6527674,105.524548 C41.4171384,107.99354 43.3021701,109.874677 45.5406451,109.874677 L59.2071246,109.874677 C60.9743417,109.874677 62.034672,109.286822 62.7415589,107.758398 C65.6869208,101.879845 72.1667171,101.29199 74.7586356,101.29199 C82.0631332,101.29199 86.8935268,106.817829 86.8935268,115.165375 C86.8935268,124.100775 82.2987622,129.626615 74.7586356,129.626615 C69.2213552,129.626615 62.6237444,125.394057 62.6237444,119.985788 C62.6237444,117.869509 60.8565272,116.105943 58.7358666,116.105943 L44.9515727,116.105943 C42.8309121,116.105943 41.063695,117.869509 41.063695,119.985788 C41.063695,134.564599 52.8451428,149.848837 74.7586356,149.848837 C76.3471253,149.848837 77.8950528,149.766485 79.399239,149.604952 L66.1897451,173.423826 C65.942449,173.869741 65.5742979,174.237131 65.1274595,174.483916 C63.8136883,175.209501 62.1592457,174.734882 61.4321586,173.423826 L0.340002415,63.264692 C0.11699273,62.8625692 -8.81072992e-13,62.4105029 -8.81072992e-13,61.9509044 C-8.81072992e-13,60.4524574 1.21724627,59.2377261 2.71879564,59.2377261 L47.1772522,59.2377261 Z M102.916296,59.2377261 L122.957477,59.2377261 C113.494303,66.4542064 108.383265,79.5937423 107.886456,98.2378472 L106.498788,100.740039 C101.619368,88.6369011 90.4113763,81.0697674 76.0545949,81.0697674 C72.4023461,81.0697674 69.1035407,82.0103359 65.6869208,83.3036176 L66.6294367,74.6033592 L98.7927891,74.6033592 C100.795635,74.6033592 102.916296,73.4276486 102.916296,70.8410853 L102.916296,59.2377261 Z"
        id="Combined-Shape"
      />
      <path
        d="M1.88185971,12.9834625 L1.88185971,3.8129199 C1.88185971,1.90239018 2.72423322,1.06175711 4.63871849,1.06175711 L40.9373591,1.06175711 C42.8518444,1.06175711 43.6942179,1.90239018 43.6942179,3.8129199 L43.6942179,12.9834625 C43.6942179,14.8939922 42.8518444,15.7346253 40.9373591,15.7346253 L31.1351945,15.7346253 L31.1351945,52.4167959 C31.1351945,54.3273256 30.292821,55.1679587 28.3783358,55.1679587 L17.1977418,55.1679587 C15.2832566,55.1679587 14.440883,54.3273256 14.440883,52.4167959 L14.440883,15.7346253 L4.63871849,15.7346253 C2.72423322,15.7346253 1.88185971,14.8939922 1.88185971,12.9834625 Z M71.6207804,0.144702842 C86.7069243,0.144702842 96.5856683,8.32177003 96.5856683,28.1148579 C96.5856683,47.9079457 86.7069243,56.0850129 71.6207804,56.0850129 C56.5346366,56.0850129 46.6558926,47.9079457 46.6558926,28.1148579 C46.6558926,8.32177003 56.5346366,0.144702842 71.6207804,0.144702842 Z M71.6207804,41.8706718 C76.9047598,41.8706718 79.6616185,38.1260336 79.6616185,28.1148579 C79.6616185,18.1036822 76.9047598,14.3590439 71.6207804,14.3590439 C66.3368011,14.3590439 63.5799423,18.1036822 63.5799423,28.1148579 C63.5799423,38.1260336 66.3368011,41.8706718 71.6207804,41.8706718 Z M119.304831,27.0449612 L124.512231,27.0449612 C129.183575,27.0449612 131.09806,25.3636951 131.09806,20.9312661 C131.09806,16.4988372 129.183575,14.8175711 124.512231,14.8175711 L119.304831,14.8175711 L119.304831,27.0449612 Z M116.547972,55.1679587 L105.520537,55.1679587 C103.606052,55.1679587 102.763678,54.3273256 102.763678,52.4167959 L102.763678,3.8129199 C102.763678,1.90239018 103.606052,1.06175711 105.520537,1.06175711 L124.741969,1.06175711 C139.445216,1.06175711 147.792372,6.41124031 147.792372,20.9312661 C147.792372,35.451292 139.445216,40.8007752 124.741969,40.8007752 L119.304831,40.8007752 L119.304831,52.4167959 C119.304831,54.3273256 118.462457,55.1679587 116.547972,55.1679587 Z"
        id="TOP"
        fillRule="nonzero"
      />
    </g>
  </g>
);

export default iconTop50;
