import { palette } from '../palette/Palette';

const TextFrameStyles = {
  textFrame: {
    backgroundColor: palette.grey.fourth,
    padding: '60px 40px'
  },

  textFrameMd: {
    maxWidth: '100%',
    padding: '50px 40px'
  },

  textFrameSm: {
    padding: '40px 20px'
  },

  title: {
    textTransform: 'uppercase',
    paddingBottom: 30
  },
  button: {
    padding: '18px 24px'
  },
  buttonMd: {
    padding: 17
  },
  buttonSm: {
    padding: '16px 10px'
  }
};

export default TextFrameStyles;
