const ArticleComposite = {
  compositeImageHolder: {
    paddingBottom: '56.25%'
  },

  compositeImageWrapper: {},

  compositeOuterHolder: {
    '& $compositeImageHolder': {
      paddingBottom: '77.5%'
    }
  },

  compositeLeftHolder: {
    width: '64%',
    verticalAlign: 'top',
    paddingRight: '1.5%'
  },

  compositeRightHolder: {
    width: '36%',
    verticalAlign: 'top',
    paddingLeft: '1.5%'
  },

  imageRight: {
    '& $compositeLeftHolder': {
      width: '36%'
    },

    '& $compositeRightHolder': {
      width: '64%'
    }
  },

  compositeHolderSm: {
    padding: 0
  },

  imageLeftSm: {
    '& $compositeImageWrapper': {
      paddingBottom: 30
    }
  }
};

export default ArticleComposite;
