import { palette } from '../palette/Palette';

const ArticleSidebarHeader = {
  tabsRoot: {
    backgroundColor: palette.grey.fourth
  },

  tabsRootMd: {
    width: '100%',
    position: 'relative',
    top: 36
  },

  tab: {
    borderBottom: `2px solid ${palette.grey.second}`,
    color: palette.default.black
  },

  activeTab: {
    color: palette.primary.second,
    borderBottom: `2px solid ${palette.primary.second}`
  }
};

export default ArticleSidebarHeader;
