import { palette } from '../palette/Palette';

const Modal = {
  modalHeader: {
    backgroundColor: palette.default.black,
    '& p': {
      color: palette.default.white
    },
    textTransform: 'uppercase'
  },

  closeIconSvg: {
    fill: palette.primary.first
  },

  firstButton: {
    display: 'flex',
    alignItems: 'center',
    width: 180,
    height: 52,
    '& $p': {
      color: palette.default.white
    },
    '& $svg': {
      marginRight: 10,
      '& path': {
        fill: palette.default.white
      }
    }
  },

  firstButtonMd: {
    width: 189,
    minHeight: 48,
    '& svg': {
      width: 20,
      height: 20,
      marginRight: 15
    }
  },

  firstButtonSm: {
    width: 200,
    height: 44,
    '& svg': {
      width: 16,
      height: 16,
      marginRight: 10
    }
  }
};

export default Modal;
