import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_47 = (
  <g
    id="number-47"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M34.271028,39 L34.271028,32.90625 L39.1495327,32.90625 C40.1588785,32.90625 41,32.0752841 41,31.078125 L41,25.2059659 C41,24.1534091 40.1588785,23.3224432 39.1495327,23.3224432 L34.271028,23.3224432 L34.271028,0 L18.4579439,0 L6.06542056,23.1008523 C5.09995937,24.9024827 5.00667809,25.1328702 5.00039174,26.1676107 L5,31.078125 C5,32.0752841 5.78504673,32.90625 6.79439252,32.90625 L24.0093458,32.90625 L24.0093458,39 L34.271028,39 Z M58.6553911,39 L68,14.3480114 L68,0 L42,0 L42,5.04119318 C42,6.03835227 42.8794926,6.92471591 43.8689218,6.92471591 L60.359408,6.92471591 L47.9365751,39 L58.6553911,39 Z M24,23 L17,23 L24,10 L24,23 Z"
      id="4"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_47;
