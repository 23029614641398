import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_16 = (
  <g
    id="number-16"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M35,39 L35,0 L25.027248,0 C24.8092643,2.16051136 22.9564033,3.93323864 20.7765668,3.93323864 L16.7983651,3.93323864 C15.8174387,3.93323864 15,4.76420455 15,5.76136364 L15,11.1903409 C15,12.1875 15.8174387,13.0184659 16.7983651,13.0184659 L25.027248,13.0184659 L25.027248,39 L35,39 Z M68,39 L68,13.4616477 C65.5102459,11.3565341 62.2459016,10.0823864 58.317623,10.0269886 L63.6290984,0 L52.397541,0 L43.4897541,16.8409091 C41.9405738,19.7215909 41,23.15625 41,26.203125 C41,31.6321023 43.102459,36.1193182 46.5881148,39 L68,39 Z M57.47343,33 C53.8599034,33 52,30.8065844 52,26.526749 C52,22.1934156 53.8599034,20 57.47343,20 C61.1400966,20 63,22.1934156 63,26.526749 C63,30.8065844 61.1400966,33 57.47343,33 Z"
      id="1"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_16;
