import { palette } from '../palette/Palette';

const UniversalVotingSearchContent = {
  freeChoiceButton: {
    '& p': {
      color: palette.default.white,
      textTransform: 'uppercase'
    }
  }
};

export default UniversalVotingSearchContent;
