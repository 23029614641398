import { palette } from '../palette/Palette';

const Navigation = {
  mainHeader: {
    backgroundColor: palette.default.black,
    color: palette.default.white,
    height: 72,
    flex: 1
  },

  mainHeaderMd: {
    height: 60
  },

  searchActive: {},

  navItemLink: {
    '&:hover': {
      color: palette.primary.first
    }
  },

  playButton: {
    background: palette.primary.first,
    color: palette.default.black,
    '&:hover': {
      backgroundColor: palette.secondary.first
    }
  },

  iconPlayButton: {
    height: 14,
    width: 14,
    paddingRight: 8,
    '& g': {
      fill: palette.default.black
    }
  },

  iconPlayButtonMd: {
    height: 12,
    width: 12,
    paddingRight: 10
  },

  menuHolder: {
    '&:hover': {
      '& $menuText $menuTextTypography': {
        color: palette.primary.first
      },
      '& $menuIcon $svgMenu $svg $path': {
        fill: palette.primary.first
      }
    }
  },

  searchHolder: {
    '&:hover': {
      '& $searchIcon': {
        '& svg': {
          '& path': {
            fill: palette.primary.first
          }
        }
      },
      '& $searchText': {
        '& p': {
          color: palette.primary.first
        }
      }
    }
  },

  searchLinkOverlay: {
    left: 0,
    position: 'relative',
    marginLeft: 'auto'
  },

  svgLogo: {
    width: 129,
    height: 48,
    viewBox: '0 0 129 48'
  },
  svgLogoMd: {
    width: 109,
    height: 41
  },
  svgLogoSm: {
    width: 97,
    height: 36,
    viewBox: '0 0 129 48'
  },
  searchIcon: {
    '& $iconClose': {
      fill: palette.default.white
    },
    '& $svgSearch': {
      fill: palette.default.white
    }
  },
  svgClose: {
    width: 32,
    height: 32
  },
  searchText: {
    textTransform: 'uppercase'
  },
  menuText: {
    textTransform: 'uppercase',

    '& $menuTextTypography': {
      lineHeight: 'initial',
      color: palette.default.white
    }
  },
  svgSearch: {
    width: 32,
    height: 32
  },
  logIn: {
    width: 325
  },
  logInMd: {
    width: 64
  },
  userProfile: {
    width: 320
  },

  overlayLeft: {
    backgroundImage: `linear-gradient(to left, rgba(245, 245, 245, 0), ${palette.grey.fourth})`
  },
  overlayRight: {
    backgroundImage: `linear-gradient(to left, ${palette.grey.fourth}, rgba(245, 245, 245, 0))`
  },

  navItemTabletContainer: {
    order: 1,
    flex: '1 1 100%',
    width: '100%'
  },

  navItemsTabletWrapper: {
    backgroundColor: palette.default.white
  },
  navItemsTabletWrapperMd: {
    height: 52,
    flexWrap: 'wrap'
  },
  navItemsTabletWrapperSm: {
    height: 48
  },
  logoHolderMd: {
    left: 'calc(50% + 20px)'
  },
  navMenuItemMd: {
    padding: '0 16px'
  },
  navMenuItemSm: {
    padding: '0 10px'
  }
};

export default Navigation;
