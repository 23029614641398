import { palette } from '../palette/Palette';

const UniversalVotingMainScreen = {
  tabLinkText: {
    color: palette.default.white,
    textTransform: 'uppercase'
  },

  selectionIndicator: {
    backgroundColor: palette.secondary.first,
    color: palette.default.black,
    width: 32
  },

  userListFixedHeader: {
    backgroundColor: palette.grey.first
  },

  maxModalTitle: {
    textTransform: 'uppercase'
  },

  maxModalTitleSm: {
    fontSize: 16
  },

  maxModalButton: {
    backgroundColor: palette.primary.first,
    color: palette.default.black,
    '&:hover': {
      color: palette.default.white
    }
  }
};

export default UniversalVotingMainScreen;
