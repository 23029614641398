import { palette } from '../palette/Palette';

const NavigationSkeleton = {
  wrapper: {
    backgroundColor: palette.default.black
  },
  logo: {
    viewBox: '0 0 129 48'
  }
};

export default NavigationSkeleton;
