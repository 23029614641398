import { palette } from '../../palette/Palette';

const CollapseButton = {
  button: {
    height: 16,
    width: 16,
    color: palette.grey.first
  },

  hoverColor: {
    color: palette.primary.first
  },

  chevronIcon: {
    fill: palette.default.black
  }
};

export default CollapseButton;
