import { palette } from '../palette/Palette';

const SidebarRelatedArticleItem = {
  wrapper: {
    backgroundColor: palette.grey.first
  },

  iconHolder: {
    '& svg': {
      fill: palette.primary.first
    }
  },

  order: {
    color: palette.primary.second,
    borderRadius: 4,
    left: -30,
    fontSize: 32
  },

  timestamp: {
    color: palette.grey.second
  },

  titleHoverColor: {
    color: palette.default.black
  },

  title: {
    lineHeight: '1.125rem'
  },

  videoTag: {
    color: palette.primary.second,
    marginRight: 5
  }
};

export default SidebarRelatedArticleItem;
