import { palette } from '../palette/Palette';

const SwimlaneSection = {
  sliderTitle: {
    color: palette.default.black
  },

  buttonControlSvg: {
    '&:hover': {
      '& g g g:first-of-type rect:nth-of-type(2)': {
        fill: palette.secondary.first
      }
    }
  },

  overlayRight: {
    background: 'linear-gradient(to left, #fff 0%, rgba(255, 255, 255, 0) 100%)'
  }
};

export default SwimlaneSection;
