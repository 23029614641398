import { palette } from '../palette/Palette';

const SearchBar = {
  width: '88.42%',
  maxWidth: '840px',

  searchBarMd: {
    width: '92%'
  },

  input: {
    borderWidth: '0 0 2px',
    padding: '10px 78px 5px 10px',
    lineHeight: 'inherit',
    borderColor: palette.grey.fifth,
    color: palette.default.black,
    '&:hover': {
      borderColor: palette.grey.seventh
    },
    '&::placeholder': {
      color: palette.grey.third
    }
  },

  inputMd: {
    fontSize: 24,
    lineHeight: 'inherit',
    paddingRight: 99,
    paddingBottom: 5
  },

  inputSm: {
    fontSize: 20,
    padding: '10px 55px 5px 0'
  },

  inputHolderSm: {
    margin: '0 0 24px'
  },

  searchClear: {
    padding: '5px 10px 5px 30px',
    right: '0',
    top: '15px',
    fontSize: ' 14px',
    lineHeight: '1.71',
    color: palette.grey.second
  },

  searchPage: {
    backgroundColor: palette.default.white
  },

  searchJackpot: {
    '& $input': {
      '&::placeholder': {
        color: palette.grey.second
      }
    }
  },

  searchClearMd: {
    right: 10,
    top: 10
  },

  searchClearSm: {
    right: 0,
    paddingRight: 0
  },

  searchIcon: {
    right: 10,
    top: 15
  },

  searchIconMd: {
    right: 10,
    paddingLeft: 25
  },

  searchIconSm: {
    right: 0
  },

  fixed: {
    width: '83.81%',
    maxWidth: '1280px',

    inputOuter: {
      maxWidth: '840px'
    }
  },

  fixedSm: {
    width: '94%',

    inputHolder: {
      margin: '0'
    }
  },

  noResultsTitle: {
    textTransform: 'uppercase'
  },

  noResultsInner: {
    paddingTop: 0
  },

  noResultsInnerMd: {
    paddingTop: 32
  },

  noResultsInnerSm: {
    paddingTop: 0
  },

  noResultsSubtitle: {
    color: palette.default.black
  },

  searchIconColor: {
    fill: palette.grey.third
  },

  noResultsSvg: {
    viewBox: '0 0 200 200'
  }
};

export default SearchBar;
