import { palette } from '../palette/Palette';

const Clips = {
  textWrapper: {
    marginBottom: 30
  },

  sectionTitle: {
    padding: '5px 7px 3px 0px',
    maxWidth: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'top'
  },

  linkIcon: {
    verticalAlign: 'bottom',
    padding: ' 0 0 0 5px',
    width: 14,
    height: 12
  },

  seeMore: {
    paddingTop: 3,
    color: palette.primary.second,

    '& $linkIcon': {
      fill: palette.primary.second
    }
  },

  seeMoreSm: {
    paddingBottom: 0
  },

  seeMoreTitle: {
    textTransform: 'uppercase'
  },

  seeMoreTitleSm: {
    display: 'none'
  },

  textWrapperSm: {
    marginBottom: 20
  },

  titleWrapperSm: {
    width: '90%'
  }
};

export default Clips;
