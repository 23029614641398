import { palette } from '../../palette/Palette';

const VolumeButton = {
  wrapper: {
    width: 45
  },
  button: {
    width: 45,
    height: 45,
    color: palette.grey.first
  },

  hoverColor: {
    color: palette.primary.first
  },

  sliderContainer: {
    left: 45
  }
};

export default VolumeButton;
