import React from 'react';

const iconArrowUp = (
  <g
    xmlns="http://www.w3.org/2000/svg"
    id="feedback"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g
      id="538top50_04"
      transform="translate(-141.000000, -544.000000)"
      fill="#FFFFFF"
    >
      <path
        d="M184.985739,581.89011 C185.517781,582.945055 184.808391,584 183.744307,584 L142.42236,584 C141.358275,584 140.648886,582.945055 141.180928,581.89011 L161.753228,544.791209 C162.28527,543.736264 163.704049,543.736264 164.236092,544.791209 L184.985739,581.89011 Z"
        id="up"
      />
    </g>
  </g>
);

export default iconArrowUp;
