import React from 'react';

const iconSearch = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M24.82 41.366c-9.137 0-16.547-7.41-16.547-16.546 0-9.137 7.41-16.547 16.547-16.547 9.136 0 16.546 7.41 16.546 16.547 0 9.136-7.41 16.546-16.546 16.546m38.777 14.429-17.782-17.78c2.41-3.827 3.825-8.342 3.825-13.195C49.64 11.11 38.529 0 24.82 0S0 11.111 0 24.82s11.111 24.82 24.82 24.82c4.856 0 9.368-1.415 13.196-3.825l17.779 17.782a1.38 1.38 0 0 0 1.95 0l5.852-5.852a1.38 1.38 0 0 0 0-1.95"
  />
);

export default iconSearch;
