import { palette } from '../palette/Palette';

const Frequency = {
  holder: {
    verticalAlign: 'middle'
  },
  content: {
    verticalAlign: 'top',
    paddingRight: '1.6%',
    '& $description': {
      color: palette.default.black
    }
  },
  freqImage: {
    verticalAlign: 'top',
    paddingLeft: '1.6%'
  },
  title: {
    margin: '0 0 20px',
    textTransform: 'uppercase',
    '& $freqTitle': {
      color: palette.default.black
    }
  },
  inputOuter: {
    margin: '50px 0 0'
  },
  inputHolder: {
    maxWidth: '580px',
    borderWidth: 20,
    borderStyle: 'solid',
    borderColor: palette.primary.first
  },
  inputTypeText: {
    padding: '12px 40px 12px 16px',
    lineHeight: 'inherit',
    height: 50,
    backgroundColor: palette.default.white,
    color: palette.default.black,
    '&:hover': {
      outline: `2px solid ${palette.grey.seventh}`
    },
    '&::placeholder': {
      color: palette.grey.second
    },
    '&::-ms-input-placeholder': {
      color: palette.grey.second
    },
    '&:-ms-input-placeholder': {
      color: palette.grey.second
    }
  },
  infoList: {
    list: {
      color: palette.primary.first
    },
    link: {
      color: palette.primary.first
    }
  },
  dropdownList: {
    backgroundColor: palette.grey.fourth
  },
  dropdownItem: {
    '&:hover': {
      backgroundColor: palette.default.black,
      '& $freqItem': {
        color: palette.default.white
      }
    }
  },
  zipResults: {
    padding: '40px 0 0'
  },
  location: {
    verticalAlign: 'bottom'
  },
  locationIcon: {
    verticalAlign: 'middle',
    '& $svgPin': {
      fill: palette.primary.second
    }
  },
  pinIcon: {
    width: 32,
    height: 32
  },
  locationText: {
    verticalAlign: 'middle',
    paddingLeft: '31px',
    '& $stationLocation': {
      color: palette.grey.second
    }
  },
  frequency: {
    textAlign: 'right',
    verticalAlign: 'bottom',
    '& $stationFrequency': {
      color: palette.default.black
    }
  },
  noResult: {
    '& $noResultText': {
      color: palette.grey.second
    }
  },
  noResultText: {
    textTransform: 'uppercase'
  },
  searchClear: {
    padding: '5px 10px 5px 30px',
    right: '0',
    top: '50%'
  },
  iconSvg: {
    transform: 'translate(-50%, -50%)'
  },
  searchIcon: {
    right: 0,
    top: 0,
    width: '72px',
    height: '50px',
    backgroundColor: palette.default.white,
    '& $iconSvg': {
      fill: palette.grey.third
    }
  },
  searchSvg: {
    width: 24,
    height: 24
  },
  clearText: {
    textTransform: 'uppercase'
  },
  tableBottom: {
    marginTop: '100px'
  },
  infoItem: {
    verticalAlign: 'top',
    paddingRight: '1.6%'
  },
  infoTitle: {
    padding: '0 0 10px'
  },
  freqItem: {
    color: palette.default.black,
    padding: '16px 0 16px 16px'
  },
  linkText: {
    padding: '20px 0 0',
    '& $textLink': {
      color: palette.primary.second
    },
    paddingRight: 10
  },
  svgLink: {
    float: 'right',
    paddingLeft: '7px',
    '& path': {
      fill: palette.primary.second
    }
  },
  linkIcon: {
    width: 14,
    height: 12
  },
  textLink: {
    textTransform: 'uppercase'
  },
  contentMd: {
    padding: 0
  },
  inputOuterMd: {
    margin: '40px 0 0'
  },
  zipResultsSm: {
    padding: '30px 0 0'
  },
  searchIconMd: {
    paddingLeft: '25px'
  },
  tableBottomMd: {
    marginTop: '80px'
  },
  holderSm: {
    padding: '0 20px'
  },
  titleSm: {
    margin: 0
  },
  tableBottomSm: {
    marginTop: '60px'
  },
  infoItemSm: {
    padding: '0',
    paddingBottom: '45px'
  }
};

export default Frequency;
