import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_28 = (
  <g
    id="number-28"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M36.8899083,39 L36.8899083,34.0142045 C36.8899083,33.0170455 36.0642202,32.1860795 35.0733945,32.1860795 L21.8623853,32.1860795 L30.7247706,23.8764205 C32.2110092,22.4914773 37,18.2258523 37,11.0795455 C37,6.42613636 35.1834862,2.54829545 32.1559633,0 L12.0642202,0 C8.87155963,2.60369318 7,6.53693182 7,11.0795455 C7,12.1321023 7.82568807,13.0184659 8.81651376,13.0184659 L15.2568807,13.0184659 C16.2477064,13.0184659 17.0733945,12.1321023 17.0733945,11.0795455 C17.0733945,9.02982955 18.7798165,6.03835227 21.9174312,6.03835227 C25.9908257,6.03835227 26.8715596,9.69460227 26.8715596,11.0795455 C26.8715596,12.8522727 25.7155963,14.6803977 23.5137615,16.8963068 L7.71559633,32.6292614 C7.22018349,33.0724432 7,33.7372159 7,34.5127841 L7,39 L36.8899083,39 Z M68,39 L68,0 L45.432,0 C42.464,2.4375 40.616,6.09375 40.616,10.3039773 C40.616,14.015625 42.184,16.7301136 44.76,19.1676136 C41.848,21.6605114 40,24.8181818 40,28.8622159 C40,33.1832386 41.736,36.6732955 44.592,39 L68,39 Z M56,14 C53.6162791,14 51,12.9403974 51,10.0264901 C51,7.11258278 53.6162791,6 56,6 C58.3837209,6 61,7.11258278 61,10.0264901 C61,12.9403974 58.3837209,14 56,14 Z M56,33 C53.2105263,33 51,31.1226994 51,28.5276074 C51,25.9325153 53.2105263,24 56,24 C58.7894737,24 61,25.9325153 61,28.5276074 C61,31.1226994 58.7894737,33 56,33 Z"
      id="2"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_28;
