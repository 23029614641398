import React from 'react';

const iconProfile = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M62.952 54.857v7.62c0 .84-.682 1.523-1.523 1.523H3.524A1.524 1.524 0 0 1 2 62.476v-7.619c0-17.737 22.94-18.27 24.316-18.285h12.32c1.376.016 24.316.548 24.316 18.285zM32.476 0c6.732 0 12.19 6.824 12.19 15.238 0 8.415-5.458 15.238-12.19 15.238-6.732 0-12.19-6.823-12.19-15.238C20.286 6.824 25.744 0 32.476 0z"
  />
);

export default iconProfile;
