import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_6 = (
  <g
    id="feedback_def"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g
      id="number-06"
      transform="translate(1.5, 0.000000)"
      fill={palette.default.white}
      fillRule="nonzero"
    >
      <path
        d="M5.4,19.5 C5.4,10.2485795 7.72670455,3.65625 12.0477273,0 L33.3758523,0 C37.8076705,3.65625 40.0235795,10.2485795 40.0235795,19.5 C40.0235795,28.7514205 37.8076705,35.34375 33.3758523,39 L12.0477273,39 C7.72670455,35.34375 5.4,28.7514205 5.4,19.5 Z M68.0548295,39 L48.1670455,39 C44.6769886,36.1193182 42.6272727,31.6321023 42.6272727,26.203125 C42.6272727,23.15625 43.5136364,19.7215909 45.0647727,16.8409091 L53.9838068,0 L65.2295455,0 L59.9113636,10.0269886 C63.0690341,10.0823864 65.7835227,10.8579545 68.0548295,12.2982955 L68.0548295,39 Z M22.7394886,6.03835227 C19.5818182,6.03835227 15.8701705,7.64488636 15.8701705,19.5 C15.8701705,31.3551136 19.5818182,32.90625 22.7394886,32.90625 C25.9525568,32.90625 29.6088068,31.3551136 29.6088068,19.5 C29.6088068,7.64488636 25.9525568,6.03835227 22.7394886,6.03835227 Z M64.5647727,26.203125 C64.5647727,21.7159091 62.6258523,19.4446023 58.8034091,19.4446023 C55.0363636,19.4446023 53.0974432,21.7159091 53.0974432,26.203125 C53.0974432,30.6349432 55.0363636,32.90625 58.8034091,32.90625 C62.6258523,32.90625 64.5647727,30.6349432 64.5647727,26.203125 Z"
        id="6"
      />
    </g>
  </g>
);

export default chartListTop50_6;
