import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_42 = (
  <g
    id="number-42"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M34.271028,39 L34.271028,32.90625 L39.1495327,32.90625 C40.1588785,32.90625 41,32.0752841 41,31.078125 L41,25.2059659 C41,24.1534091 40.1588785,23.3224432 39.1495327,23.3224432 L34.271028,23.3224432 L34.271028,0 L18.4579439,0 L6.06542056,23.1008523 C5.09995937,24.9024827 5.00667809,25.1328702 5.00039174,26.1676107 L5,31.078125 C5,32.0752841 5.78504673,32.90625 6.79439252,32.90625 L24.0093458,32.90625 L24.0093458,39 L34.271028,39 Z M68,39 L68,32.1860795 L57.7155361,32.1860795 L66.5229759,23.8764205 C66.8512035,23.5440341 67.3982495,23.0454545 68,22.3806818 L68,0 L47.9781182,0 C44.8599562,2.60369318 43,6.53693182 43,11.0795455 C43,12.1321023 43.8205689,13.0184659 44.8052516,13.0184659 L51.1509847,13.0184659 C52.190372,13.0184659 53.0109409,12.1321023 53.0109409,11.0795455 C53.0109409,9.02982955 54.7067834,6.03835227 57.7702407,6.03835227 C61.8730853,6.03835227 62.7483589,9.69460227 62.7483589,11.0795455 C62.7483589,12.8522727 61.5995624,14.6803977 59.356674,16.8963068 L43.7111597,32.6292614 C43.2188184,33.0724432 43,33.7372159 43,34.5127841 L43,39 L68,39 Z M24,23 L17,23 L24,10 L24,23 Z"
      id="4"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_42;
