import React from 'react';
import SvgIcon from '@talpanetwork/talpa-publishing-core/SvgIcon';

const overlay = (props) => (
  <SvgIcon {...props} width={493} height={966} viewBox="0 0 493 966">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M493 0C226.246 0 10 216.246 10 483C10 749.754 226.246 966 493 966H0V0H493Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M493 0C226.246 0 10 216.246 10 483C10 749.754 226.246 966 493 966H0V0H493Z"
      fill="black"
    />
  </SvgIcon>
);

overlay.propTypes = SvgIcon.propTypes;

overlay.defaultProps = SvgIcon.defaultProps;

const SvgSliderHeaderCurve = {
  overlay
};

export default SvgSliderHeaderCurve;
