import { palette } from '../palette/Palette';

const ArticleSponsored = {
  wrapper: {
    backgroundColor: palette.grey.fourth,
    color: palette.default.black
  },

  bottomWrapperMd: {
    marginRight: 0,
    marginLeft: 0,
    width: '100%'
  },

  bottomWrapperSm: {
    marginRight: 0,
    marginLeft: 0,
    width: '100%'
  }
};

export default ArticleSponsored;
