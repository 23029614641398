import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_25 = (
  <g
    id="number-25"
    stroke="none"
    transform="translate(1.5, 0.000000)"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M36.8899083,39 L36.8899083,34.0142045 C36.8899083,33.0170455 36.0642202,32.1860795 35.0733945,32.1860795 L21.8623853,32.1860795 L30.7247706,23.8764205 C32.2110092,22.4914773 37,18.2258523 37,11.0795455 C37,6.42613636 35.1834862,2.54829545 32.1559633,0 L12.0642202,0 C8.87155963,2.60369318 7,6.53693182 7,11.0795455 C7,12.1321023 7.82568807,13.0184659 8.81651376,13.0184659 L15.2568807,13.0184659 C16.2477064,13.0184659 17.0733945,12.1321023 17.0733945,11.0795455 C17.0733945,9.02982955 18.7798165,6.03835227 21.9174312,6.03835227 C25.9908257,6.03835227 26.8715596,9.69460227 26.8715596,11.0795455 C26.8715596,12.8522727 25.7155963,14.6803977 23.5137615,16.8963068 L7.71559633,32.6292614 C7.22018349,33.0724432 7,33.7372159 7,34.5127841 L7,39 L36.8899083,39 Z M68,39 L68,13.7386364 C65.244898,11.3565341 61.7183673,9.97159091 57.4755102,9.97159091 C55.7673469,9.97159091 54.2244898,10.4147727 52.6265306,11.0241477 L53.0673469,6.92471591 L68,6.92471591 L68,0 L43.8653061,0 L41.2755102,21.4943182 C41.1653061,22.6576705 42.1020408,23.5440341 43.1489796,23.5440341 L49.5408163,23.5440341 C50.3673469,23.5440341 50.8632653,23.2670455 51.2489796,22.546875 C52.5163265,19.7215909 55.6571429,19.5 56.8693878,19.5 C60.2306122,19.5 62.544898,22.1036932 62.544898,26.0923295 C62.544898,30.3025568 60.3408163,32.90625 56.8693878,32.90625 C54.2795918,32.90625 51.1387755,30.9119318 51.1387755,28.3636364 C51.1387755,27.3664773 50.3122449,26.5355114 49.3204082,26.5355114 L42.8734694,26.5355114 C41.8265306,26.5355114 41,27.3664773 41,28.3636364 C41,32.2414773 42.8183673,36.2855114 46.1795918,39 L68,39 Z"
      id="2"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_25;
