import { palette } from '../palette/Palette';

const UniversalVotingUserListTrack = {
  track: {
    backgroundColor: palette.grey.eight,
    borderColor: palette.grey.seventh
  },

  votedTrack: {
    backgroundColor: palette.grey.seventh,
    borderColor: palette.grey.second
  },

  dragged: {
    borderColor: palette.primary.first
  },

  trackVoted: {
    borderColor: palette.grey.sixth
  },

  removeIcon: {
    '& svg': {
      fill: palette.grey.fourth
    }
  },

  hightlightedNumber: {
    color: palette.default.white
  },

  orderNumber: {
    color: palette.default.white
  },

  artist: {
    color: palette.grey.fourth,
    textTransform: 'uppercase'
  },

  title: {
    color: palette.grey.fourth,
    fontSize: 14
  },

  removeIconSvg: {
    width: 16,
    height: 16
  },

  trackBorderDragging: {
    borderLeftColor: palette.primary.first
  },

  dragDropIcon: {
    fill: palette.primary.first
  },

  mobileDragDropColor: {
    fill: palette.primary.first
  }
};

export default UniversalVotingUserListTrack;
