import { palette } from '@theme/palette/Palette';

const ContentfulRichTextStyles = (theme) => ({
  bannerWrapper: {
    borderTop: `1px solid ${palette.grey.second}`,
    borderBottom: `1px solid ${palette.grey.second}`,
    marginTop: '8px !important',
    marginBottom: '8px !important',
    minHeight: 90,
    [theme.breakpoints.down('md')]: {
      marginTop: '8px !important',
      marginBottom: '8px !important',
      minHeight: 0
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '8px !important',
      marginBottom: '8px !important',
      minHeight: 240
    }
  },

  rteContentHolder: {
    float: 'left',
    width: '100%',
    paddingRight: 40,
    boxSizing: 'border-box',
    maxWidth: 1280
  },

  rteFullWidth: {
    width: '100%',
    maxWidth: '100%',
    padding: 0
  },
  imageWrapper: {
    marginBottom: 40
  },
  image: {
    objectFit: 'contain',
    maxWidth: '100%'
  },
  imageCaption: {
    display: 'block',
    marginTop: 8,
    color: palette.default.black
  },
  [theme.breakpoints.down('ll')]: {
    rteContentHolder: {
      float: 'none',
      display: 'block',
      width: '100%',
      paddingRight: 0,
      maxWidth: '100%'
    },
    imageCaption: {
      marginTop: 5
    },
    imageWrapper: {
      marginBottom: 32
    }
  },

  [theme.breakpoints.down('sm')]: {
    rteContentHolder: {
      boxSizing: 'border-box'
    }
  }
});

export default ContentfulRichTextStyles;
