import { palette } from '@theme/palette/Palette';

const Quote = {
  quoteWrapper: {
    textAlign: 'center',
    paddingLeft: '20%',
    paddingRight: '20%'
  },

  quoteText: {
    textTransform: 'uppercase'
  },

  quoteAuthor: {
    color: palette.primary.second
  },

  quoteIconStyle: {
    margin: '0 auto',
    paddingBottom: 15
  },

  quoteBorder: {
    backgroundColor: palette.primary.first,
    margin: '0 auto 15px',
    width: 110,
    height: 2
  },

  quoteIcon: {
    width: 42,
    height: 42
  },

  quoteIconSm: {
    width: 42,
    height: 42
  }
};

export default Quote;
