const FragmentJackpotSection = {
  wrapper: {
    padding: '0 0 43px',

    '& $title': {
      padding: '5px 7px 3px 0px',
      maxWidth: '100%',
      boxSizing: 'border-box',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      verticalAlign: 'top',
      display: 'inline-block'
    }
  },

  titleHolder: {
    position: 'relative',
    marginBottom: 30
  },

  title: {
    padding: '0 0 20px'
  },

  linkHolder: {
    padding: '0 75px 49px 0'
  },

  linkIcon: {
    padding: ' 0 0 0 14px'
  },

  linkHolderMd: {
    padding: '0 40px 0 0'
  },

  linkHolderSm: {
    padding: '0 5px 0 0'
  }
};

export default FragmentJackpotSection;
