import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_10 = (
  <g
    id="number-10"
    stroke="none"
    strokeWidth="1"
    transform="translate(1.5, 0.000000)"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M34.1309659,39 L23.9931818,39 L23.9931818,13.0184659 L15.628125,13.0184659 C14.6309659,13.0184659 13.8,12.1875 13.8,11.1903409 L13.8,5.76136364 C13.8,4.76420455 14.6309659,3.93323864 15.628125,3.93323864 L19.6721591,3.93323864 C21.8880682,3.93323864 23.7715909,2.16051136 23.9931818,0 L34.1309659,0 L34.1309659,39 Z M68.034375,39 L45.5428977,39 C41.1664773,35.34375 38.8951705,28.7514205 38.8951705,19.5 C38.8951705,10.2485795 41.1664773,3.65625 45.5428977,0 L68.034375,0 L68.034375,39 Z M56.2346591,6.03835227 C53.0769886,6.03835227 49.3653409,7.64488636 49.3653409,19.5 C49.3653409,31.3551136 53.0769886,32.90625 56.2346591,32.90625 C59.3923295,32.90625 63.1039773,31.3551136 63.1039773,19.5 C63.1039773,7.64488636 59.3923295,6.03835227 56.2346591,6.03835227 Z"
      id="1"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_10;
