import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_30 = (
  <g
    id="number-30"
    stroke="none"
    transform="translate(1.5, 0.000000)"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M31.4014599,39 C31.6751825,38.7784091 31.8941606,38.5568182 32.1678832,38.2798295 C34.6313869,35.8423295 36,32.4630682 36,28.6960227 C36,24.6519886 34.1386861,21.8821023 31.4562044,19.1676136 C33.9744526,16.6193182 35.4525547,14.2372159 35.4525547,10.5255682 C35.4525547,6.20454545 33.5912409,2.49289773 30.689781,0 L11.1459854,0 C8.18978102,2.60369318 6.32846715,6.59232955 6.32846715,11.1903409 C6.32846715,12.1875 7.20437956,13.0184659 8.18978102,13.0184659 L14.540146,13.0184659 C15.5255474,13.0184659 16.4014599,12.1875 16.4014599,11.1903409 C16.4014599,8.86363636 17.770073,6.03835227 20.7810219,6.03835227 C23.8467153,6.03835227 25.2153285,8.53125 25.2153285,10.5255682 C25.2153285,11.4673295 24.9416058,14.5142045 20.7810219,14.5142045 L18.8649635,14.5142045 C17.879562,14.5142045 17.0583942,15.3451705 17.0583942,16.3977273 L17.0583942,22.0482955 C17.0583942,23.0454545 17.879562,23.8764205 18.8649635,23.8764205 L21.4927007,23.8764205 C23.8467153,23.8764205 25.8175182,25.9261364 25.8175182,28.3082386 C25.8175182,30.6903409 24.1751825,32.90625 20.8905109,32.90625 C17.1678832,32.90625 16.2372263,28.9730114 16.2372263,28.0866477 C16.2372263,27.0894886 15.3613139,26.2585227 14.4306569,26.2585227 L7.86131387,26.2585227 C6.82116788,26.2585227 6,27.0894886 6,28.0866477 C6,32.1306818 7.47810219,36.2301136 10.5985401,39 L31.4014599,39 Z M68,39 L68,0 L44.7669173,0 C40.3120301,3.65625 38,10.2485795 38,19.5 C38,28.7514205 40.3120301,35.34375 44.7669173,39 L68,39 Z M56.1137947,32.9993384 L56,33 C52.7822581,33 49,31.4412371 49,19.5278351 C49,7.61443299 52.7822581,6 56,6 C59.2177419,6 63,7.61443299 63,19.5278351 C63,31.3010794 59.3062127,32.9618901 56.1137947,32.9993384 L56.1137947,32.9993384 Z"
      id="3"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_30;
