import React from 'react';

const iconPauseOnButton = (
  <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
    <g transform="translate(12.652 4)">
      <rect width="16" height="56" rx="2" />
      <rect fill="#FFF" width="16" height="56" rx="2" />
      <rect opacity=".302" width="16" height="56" rx="2" />
    </g>
    <g transform="translate(35.652 4)">
      <rect width="16" height="56" rx="2" />
      <rect fill="#FFF" width="16" height="56" rx="2" />
      <rect opacity=".302" width="16" height="56" rx="2" />
    </g>
  </g>
);

export default iconPauseOnButton;
