import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_3 = (
  <g
    id="number-03"
    transform="translate(1.5, 0.000000)"
    fill={palette.default.white}
    fillRule="nonzero"
  >
    <path
      d="M5.4,19.5 C5.4,10.2485795 7.72670455,3.65625 12.0477273,0 L33.3758523,0 C37.8076705,3.65625 40.0235795,10.2485795 40.0235795,19.5 C40.0235795,28.7514205 37.8076705,35.34375 33.3758523,39 L12.0477273,39 C7.72670455,35.34375 5.4,28.7514205 5.4,19.5 Z M68.0548295,39 L47.2252841,39 C44.0676136,36.2301136 42.6272727,32.1306818 42.6272727,28.0866477 C42.6272727,27.0894886 43.4582386,26.2585227 44.4553977,26.2585227 L51.103125,26.2585227 C52.1002841,26.2585227 52.93125,27.0894886 52.93125,28.0866477 C52.93125,28.9730114 53.8730114,32.90625 57.6400568,32.90625 C61.0193182,32.90625 62.6258523,30.6903409 62.6258523,28.3082386 C62.6258523,25.9261364 60.6869318,23.8764205 58.2494318,23.8764205 L55.5903409,23.8764205 C54.5931818,23.8764205 53.7622159,23.0454545 53.7622159,22.0482955 L53.7622159,16.3977273 C53.7622159,15.3451705 54.5931818,14.5142045 55.5903409,14.5142045 L57.5292614,14.5142045 C61.7948864,14.5142045 62.071875,11.4673295 62.071875,10.5255682 C62.071875,8.53125 60.6869318,6.03835227 57.5292614,6.03835227 C54.4823864,6.03835227 53.0974432,8.86363636 53.0974432,11.1903409 C53.0974432,12.1875 52.2664773,13.0184659 51.2693182,13.0184659 L44.7877841,13.0184659 C43.790625,13.0184659 42.9596591,12.1875 42.9596591,11.1903409 C42.9596591,6.59232955 44.7877841,2.60369318 47.7792614,0 L68.0548295,0 L68.0548295,39 Z M22.7394886,6.03835227 C19.5818182,6.03835227 15.8701705,7.64488636 15.8701705,19.5 C15.8701705,31.3551136 19.5818182,32.90625 22.7394886,32.90625 C25.9525568,32.90625 29.6088068,31.3551136 29.6088068,19.5 C29.6088068,7.64488636 25.9525568,6.03835227 22.7394886,6.03835227 Z"
      id="3"
    />
  </g>
);

export default chartListTop50_3;
