import { palette } from '../palette/Palette';

const UniversalVotingAddTrack = {
  wrapper: {
    border: `solid 1px ${palette.grey.sixth}`,
    borderRadius: 0
  },

  returnButton: {
    color: palette.primary.second,
    textTransform: 'uppercase'
  },

  divider: {
    color: palette.grey.sixth,
    backgroundColor: palette.grey.sixth
  },

  inputTypeText: {
    borderColor: palette.grey.fourth,
    backgroundColor: palette.grey.fourth,
    '&:focus': {
      outline: 'none',
      border: `1px solid ${palette.primary.first}`
    },
    '&::placeholder': {
      color: palette.grey.second
    }
  },
  confirmButton: {
    '& button': {
      backgroundColor: palette.primary.first,
      '&:hover': {
        '& p': {
          color: palette.default.white
        }
      }
    },
    '& p': {
      color: palette.default.black,
      textTransform: 'uppercase'
    }
  },
  title: {
    textTransform: 'uppercase',
    whiteSpace: 'nowrap'
  },

  artist: {
    whiteSpace: 'nowrap'
  }
};
export default UniversalVotingAddTrack;
