import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_1 = (
  <g>
    <defs>
      <path
        d="M57.7521023,0 C57.4751136,2.16051136 55.5915909,3.93323864 53.4310795,3.93323864 L53.4310795,3.93323864 L49.3870455,3.93323864 C48.3898864,3.93323864 47.5589205,4.76420455 47.5589205,5.76136364 L47.5589205,5.76136364 L47.5589205,11.2457386 C47.5589205,12.1875 48.3898864,13.0184659 49.3870455,13.0184659 L49.3870455,13.0184659 L57.7521023,13.0184659 L57.7521023,39 L34.5958523,39 C38.9722727,35.34375 41.1881818,28.7514205 41.1881818,19.5 C41.1881818,10.2485795 38.9722727,3.65625 34.5958523,0 L34.5958523,0 Z M23.9594886,6.03835227 C27.1171591,6.03835227 30.7734091,7.64488636 30.7734091,19.5 C30.7734091,31.3551136 27.1171591,32.90625 23.9594886,32.90625 C20.7464205,32.90625 17.0901705,31.3551136 17.0901705,19.5 C17.0901705,7.64488636 20.7464205,6.03835227 23.9594886,6.03835227 Z M13.2123295,0 C8.89130682,3.65625 6.62,10.2485795 6.62,19.5 C6.62,28.7514205 8.89130682,35.34375 13.2123295,39 L13.2123295,39 L0,39 L0,0 Z"
        id="path-1"
      />
    </defs>
    <g
      id="number-01"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <mask id="mask-2" fill="white">
        <use xlinkHref="#path-1" />
      </mask>
      <use
        id="Combined-Shape"
        fill={palette.default.white}
        xlinkHref="#path-1"
      />
    </g>
  </g>
);

export default chartListTop50_1;
