import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_41 = (
  <g
    id="number-41"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M34.271028,39 L34.271028,32.90625 L39.1495327,32.90625 C40.1588785,32.90625 41,32.0752841 41,31.078125 L41,25.2059659 C41,24.1534091 40.1588785,23.3224432 39.1495327,23.3224432 L34.271028,23.3224432 L34.271028,0 L18.4579439,0 L6.06542056,23.1008523 C5.09995937,24.9024827 5.00667809,25.1328702 5.00039174,26.1676107 L5,31.078125 C5,32.0752841 5.78504673,32.90625 6.79439252,32.90625 L24.0093458,32.90625 L24.0093458,39 L34.271028,39 Z M68,39 L68,0 L58.2506964,0 C58.0278552,2.16051136 56.1337047,3.93323864 53.9052925,3.93323864 L49.8384401,3.93323864 C48.8356546,3.93323864 48,4.76420455 48,5.76136364 L48,11.1903409 C48,12.1875 48.8356546,13.0184659 49.8384401,13.0184659 L58.2506964,13.0184659 L58.2506964,39 L68,39 Z M24,23 L17,23 L24,10 L24,23 Z"
      id="4"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_41;
