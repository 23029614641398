import { palette } from '../palette/Palette';

const Clip = {
  bottomPlayOptions: {
    height: '21.4%',
    backgroundColor: palette.primary.second
  },

  audioHolder: {
    backgroundColor: palette.grey.fourth,
    '&:hover': {
      '& $imageHolder': {
        '& $imageOverlay': {
          opacity: 0.5
        }
      },

      '& $imageHolderLatest': {
        '& $imageOverlay': { opacity: 0.5 }
      },

      '& $bottomPlayOptions': {
        backgroundColor: palette.secondary.second
      }
    }
  },

  bottomPlayOptionsMd: {
    height: '35px'
  },

  imageOverlay: {
    backgroundColor: palette.primary.second
  },

  notPlaying: {
    width: '72px'
  },

  notPlayingMd: {
    width: '56px'
  },

  notPlayingSm: {
    width: '46px'
  },

  playing: {
    width: '72px'
  },

  playingMd: {
    width: '56px'
  },

  playingSm: {
    width: '46px'
  },

  audioHolderInner: {
    maxHeight: 112
  },

  imageHolder: {
    width: '17.9%'
  },

  imageHolderMd: {
    width: 70
  },

  imageHolderSm: {
    width: 56
  },

  imageHolderLatest: {
    width: '37.1%'
  },

  imageHolderLatestMd: {
    width: 150
  },

  imageLatest: {
    maxWidth: 234
  },

  imageLatestMd: {
    maxWidth: 150
  },

  iconSpeakerLatest: {
    width: 32,
    height: 32
  },

  iconSpeaker: {
    height: 32,
    width: 32
  },

  iconSpeakerMd: {
    padding: '0 11px 0 0',
    height: 23,
    width: 23
  },

  iconSpeakerSm: {
    padding: '0 10px 0 0',
    height: 23,
    width: 23
  },

  badgeHolder: {
    top: 13,
    left: 13,
    zIndex: 10,
    textTransform: 'uppercase'
  },

  image: {
    maxWidth: '112px'
  },

  imageMd: {
    width: '70px'
  },

  imageSm: {
    width: '56px'
  },

  textHolderLatest: {
    height: '78.6%'
  },

  textHolderLatestMd: {
    height: 'calc(100% - 35px)'
  },

  iconPlayStopLatest: {
    padding: '0 2% 0 5%',
    width: 26,
    height: 26
  },

  iconPlayStopLatestMd: {
    height: 20,
    width: 20
  },

  iconPlay: {
    height: 40,
    width: 40
  },

  iconPlayMd: {
    height: 35,
    width: 35
  },

  iconPlaySm: {
    height: 26,
    width: 26
  },

  iconStop: {
    height: 40,
    width: 40
  },

  iconStopMd: {
    height: 35,
    width: 35
  },

  iconStopSm: {
    height: 26,
    width: 26
  },

  textHolderInner: {
    left: '7%'
  },

  textHolder: {
    padding: '1% 4%'
  },

  textHolderInnerSm: {
    left: '10px'
  },

  publishedDate: {
    paddingTop: 5
  },

  iconPlayLarge: {
    width: 60,
    height: 60
  },

  description: {
    fontFamily: 'QanelasSoftRegular',
    padding: '2% 0 0'
  }
};

export default Clip;
