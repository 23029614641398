import { palette } from '../../palette/Palette';

const Dialog = {
  content: {
    color: palette.grey.seventh,
    background: palette.grey.fourth,
    boxShadow: '0px 0px 32px -5px rgba(0,0,0,.25)'
  },

  fontPargraph: {
    fontSize: 16,
    fontFamily: 'QanelasSoftRegular'
  },

  hrColor: {
    color: palette.grey.third,
    backgroundColor: palette.grey.third
  },

  hrBefore: {
    backgroundColor: palette.primary.second,
    border: `6px solid ${palette.grey.fourth}`
  },

  title: {
    color: palette.grey.first,
    fontFamily: 'QanelasSoftBlack',
    fontSize: 16
  }
};

export default Dialog;
