import { palette } from '../palette/Palette';

const FAQComponent = {
  faqHolder: {
    '& $faqTitle': {
      color: palette.default.black
    }
  },

  answer: {
    color: palette.primary.second
  },

  faqTitle: {
    display: 'inline-block',
    padding: '5px 7px 3px 0px',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'top',
    boxSizing: 'border-box'
  },

  accordion: {
    padding: '32px 0 0 '
  },

  icon: {
    width: 20,
    height: 20,
    right: '40px'
  },

  iconSm: {
    width: 15,
    height: 15,
    right: '20px'
  },

  iconOpen: {
    width: 20,
    height: 20,
    right: '40px'
  },

  iconOpenSm: {
    width: 15,
    height: 15,
    right: '20px'
  },

  accordionTitle: {
    padding: '30px 80px 30px 40px',
    '&:hover': {
      '& $icon': {
        '& path': {
          fill: palette.primary.second
        }
      }
    }
  },

  accordionTitleSm: {
    padding: '30px 80px 30px 20px'
  },

  accordionBody: {
    padding: '0px 40px 58px'
  },

  accordionBodyMd: {
    padding: '0px 40px 40px'
  },

  accordionBodySm: {
    padding: '0px 20px 20px'
  },

  accordionItem: {
    '&:hover': {
      backgroundColor: palette.grey.fifth
    }
  },

  accItemTitle: {
    textTransform: 'uppercase'
  },

  accordionItemExpanded: {
    backgroundColor: palette.default.black,
    '& $accItemTitle': {
      color: palette.primary.first
    },
    '& $accItemBody': {
      color: palette.default.white
    }
  }
};

export default FAQComponent;
