import { palette } from '../palette/Palette';

const UniversalVotingLogin = {
  link: {
    color: palette.primary.second,
    borderBottom: `1px solid ${palette.default.black}`,
    cursor: 'pointer',
    textTransform: 'uppercase'
  },

  logo: {
    width: 176,
    height: 24,
    viewBox: '0 0 176 30'
  },

  logoSm: {
    width: 115,
    height: 16
  },

  content: {
    '& $title': {
      textTransform: 'uppercase'
    }
  }
};

export default UniversalVotingLogin;
