import { palette } from '../palette/Palette';

const UniversalVotingTrack = {
  artist: {
    color: palette.default.white,
    textTransform: 'uppercase'
  },

  title: {
    color: palette.default.white,
    textTransform: 'uppercase'
  },

  addIcon: {
    '& svg': {
      fill: palette.primary.first
    }
  },

  play: {
    width: 20,
    height: 20
  },

  pause: {
    width: 20,
    height: 20
  },

  addIconSvg: {
    width: 24,
    height: 24
  },

  progress: {
    backgroundColor: palette.primary.first
  },

  trackIcon: {
    viewBox: '0 0 116 116',
    width: 80,
    height: 80
  },

  checkmarkIcon: {
    '& svg': {
      fill: palette.primary.first
    }
  }
};

export default UniversalVotingTrack;
