import { palette } from '../../palette/Palette';

const PlaylistModal = {
  title: {
    fontFamily: 'QanelasSoftBlack',
    fontSize: 24,
    lineHeight: 1,
    color: palette.default.black,
    textTransform: 'uppercase',
    '& > span': {
      borderRadius: 3
    }
  },
  titleSpan: {
    backgroundColor: palette.default.white
  },
  modalContent: {
    padding: '16px 30px'
  },
  modalContentMd: {
    padding: '16px 20px'
  }
};

export default PlaylistModal;
