import { palette } from '../palette/Palette';

const Footer = {
  footer: {
    marginTop: 0,
    backgroundColor: palette.default.black
  },

  holder: {
    padding: '60px 0 20px',
    paddingMd: '40px 0 140px',
    paddingSm: '40px 0 105px'
  },

  contactIcon: {},

  topContainer: {
    padding: '0 0 20px'
  },

  title: {
    paddingBottom: 25
  },

  contact: {
    margin: '0 0 20px',

    '& $contactIcon': {
      fill: palette.default.white
    }
  },

  contactSm: {},

  contactIcons: {
    width: 25,
    height: 25
  },

  contactItem: {
    padding: '0 0 0 35px'
  },

  footnote: {
    position: 'relative',
    top: -15,
    padding: '0 0 0 35px'
  },

  footnoteSm: {
    padding: '0 0 0 44px'
  },

  footerMenu: {},

  footerMenuItem: {
    padding: '0 10px 20px 0',
    '&:hover': {
      '& p': {
        color: palette.grey.second
      }
    },
    '& $itemInner': {
      textTransform: 'none'
    }
  },

  footerForm: {},

  footerCenter: {
    paddingMd: '55px 0 0'
  },

  socialIcon: {
    width: 40,
    height: 40,
    backgroundColor: palette.primary.first,
    margin: '0 10px 10px 0',
    '&:hover': {
      background: palette.secondary.first
    },
    borderRadius: 4,
    '& g': {
      fill: palette.default.black
    }
  },

  iconInner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 18,
    height: 18
  },

  socialIcons: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    paddingMd: '48px 0 48px',
    paddingSm: '32px 0 32px'
  },
  socialIconsMd: {
    justifyContent: 'center'
  },
  socialIconsSm: {
    justifyContent: 'flex-start'
  },

  copyright: {
    paddingMd: '0 0 20px',
    paddingSm: '0 0 10px'
  },

  footerHolder: {
    padding: '60px 0 0'
  },

  footerFormMd: {
    padding: '0 0 8px'
  },

  footerFormSm: {
    padding: '0 0 32px'
  },

  otherLink: {
    padding: '0 20px 0 0',
    paddingSm: '0 10px 10px 0',
    '&:hover': {
      '& span': {
        color: palette.default.white
      }
    }
  },

  contactsAndFootnote: {
    '& $footnote': {
      color: palette.grey.second
    },

    '& $contactItem': {
      textTransform: 'none'
    }
  }
};

export default Footer;
