import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_44 = (
  <g
    id="number-44"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M34.4579439,39 L34.4579439,32.90625 L39.2009346,32.90625 C40.182243,32.90625 41,32.0752841 41,31.078125 L41,25.2059659 C41,24.1534091 40.182243,23.3224432 39.2009346,23.3224432 L34.4579439,23.3224432 L34.4579439,0 L19.0841121,0 L7.03582555,23.1008523 C6.09718272,24.9024827 6.00649259,25.1328702 6.00038086,26.1676107 L6,31.078125 C6,32.0752841 6.76323988,32.90625 7.74454829,32.90625 L24.4813084,32.90625 L24.4813084,39 L34.4579439,39 Z M68,39 L68,0 L55.9329004,0 L44.0281385,23.1008523 C43.0964615,24.9024827 43.0064444,25.1328702 43.000378,26.1676107 L43,31.078125 C43,32.0752841 43.7575758,32.90625 44.7316017,32.90625 L61.3441558,32.90625 L61.3441558,39 L68,39 Z M25,23 L18,23 L25,10 L25,23 Z M61,23 L54,23 L61,10 L61,23 Z"
      id="4"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_44;
