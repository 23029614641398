import { palette } from '../palette/Palette';

const ArticleHeader = {
  title: {
    textTransform: 'uppercase'
  },

  dateTime: {
    color: palette.grey.second
  },

  titleMd: {
    paddingBottom: 24
  },

  iconHolder: {
    top: -2
  },

  iconCalendar: {
    width: 20,
    height: 20
  },

  categoryHolder: {
    maxWidth: 400
  },
  imageCaption: {
    color: palette.default.black
  },
  imageCaptionMd: {
    paddingLeft: 32
  },

  iconHolderXs: {
    paddingRight: 5,
    top: -1
  },

  categoryHolderMd: {
    maxWidth: '100%',
    paddingRight: 16
  },

  categoryHolderSm: {
    paddingRight: 8
  },

  kijkWrapper: {
    '@supports (aspect-ratio: 16 / 9)': {
      '& iframe': {
        height: '100%',
        aspectRatio: '16 / 9'
      }
    }
  }
};

export default ArticleHeader;
