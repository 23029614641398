import { palette } from '../../palette/Palette';

const StationRow = {
  title: {
    color: palette.default.black,
    fontFamily: 'QanelasSoftBlack',
    fontSize: 20,
    lineHeight: 1.2,
    textTransform: 'uppercase'
  }
};

export default StationRow;
