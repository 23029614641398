import React from 'react';

const universalVotingIconPause = (
  <g
    xmlns="http://www.w3.org/2000/svg"
    id="assets"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g
      id="assets/station-no-cover-538-1"
      transform="translate(-40.000000, -41.000000)"
      fill="#FFFFFF"
    >
      <g
        id="538-/-icons-/-general-/-pause"
        transform="translate(22.000000, 22.000000)"
      >
        <path
          d="M29.33964,19.8 C31.02624,19.8 32.39964,21.1734 32.39964,22.86 L32.39964,22.86 L32.39964,49.14 C32.39964,50.8266 31.02624,52.2 29.33964,52.2 L29.33964,52.2 L21.05964,52.2 C19.37304,52.2 17.99964,50.8266 17.99964,49.14 L17.99964,49.14 L17.99964,22.86 C17.99964,21.1734 19.37304,19.8 21.05964,19.8 L21.05964,19.8 Z M50.93964,19.8 C52.62624,19.8 53.99964,21.1734 53.99964,22.86 L53.99964,22.86 L53.99964,49.14 C53.99964,50.8266 52.62624,52.2 50.93964,52.2 L50.93964,52.2 L42.65964,52.2 C40.97304,52.2 39.59964,50.8266 39.59964,49.14 L39.59964,49.14 L39.59964,22.86 C39.59964,21.1734 40.97304,19.8 42.65964,19.8 L42.65964,19.8 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </g>
);

export default universalVotingIconPause;
