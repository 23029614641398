import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_19 = (
  <g
    id="number-19"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M35,39 L35,0 L25.027248,0 C24.8092643,2.16051136 22.9564033,3.93323864 20.7765668,3.93323864 L16.7983651,3.93323864 C15.8174387,3.93323864 15,4.76420455 15,5.76136364 L15,11.1903409 C15,12.1875 15.8174387,13.0184659 16.7983651,13.0184659 L25.027248,13.0184659 L25.027248,39 L35,39 Z M62.0368098,39 L68,27.6434659 L68,0 L46.5214724,0 C43.0429448,2.88068182 41,7.36789773 41,12.796875 C41,22.2144886 46.9631902,28.8068182 56.0736196,28.9730114 L50.7730061,39 L62.0368098,39 Z M57.47343,20 C53.8599034,20 52,17.647541 52,13 C52,8.40983607 53.8599034,6 57.47343,6 C61.1400966,6 63,8.40983607 63,13 C63,17.647541 61.1400966,20 57.47343,20 Z"
      id="1"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_19;
