import { palette } from '../palette/Palette';

const LoginOverlay = {
  outerHolder: {
    height: 500,
    backgroundImage:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 17%, #ffffff 45%, #ffffff)',

    '& $button': {
      minWidth: 128,
      marginRight: 16,
      '&:hover': {
        backgroundColor: palette.secondary.second
      }
    },
    '& $registerButton': {
      backgroundColor: palette.primary.first,
      border: `1px solid ${palette.primary.first}`,
      minWidth: 128,
      '& p': {
        color: palette.default.black
      },
      '&:hover': {
        backgroundColor: palette.secondary.first
      }
    }
  },

  textHolder: {
    marginTop: 24,
    margin: '0 auto'
  },

  loginText: {
    textTransform: 'uppercase'
  },

  registerButton: {},

  iconsHolder: {
    '& svg': {
      width: 151,
      height: 48
    }
  },

  outerHolderMd: {
    '& $button': {
      minWidth: 104
    },
    '& $registerButton': {
      minWidth: 104
    }
  },

  iconsHolderSm: {
    '& svg': {
      width: 100,
      height: 32
    }
  },

  iconViewBox: '0 0 195 31',

  textHolderSm: {
    maxWidth: 250,
    marginTop: 16
  },

  outerHolderSm: {
    height: 400,
    '& $button': {
      minWidth: 88
    },
    '& $registerButton': {
      minWidth: 88
    }
  }
};

export default LoginOverlay;
