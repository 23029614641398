import { palette } from '@theme/palette/Palette';

const UniversalVotingScrollToTop = {
  scrollTopButton: {
    paddingTop: 19,
    paddingBottom: 19,
    '& svg': {
      fill: palette.default.white
    }
  },
  scrollTopButtonMd: {
    paddingTop: 17,
    paddingBottom: 17
  },
  scrollTopButtonSm: {
    paddingTop: 16,
    paddingBottom: 16
  }
};

export default UniversalVotingScrollToTop;
