import * as React from 'react';
import dynamic from 'next/dynamic';

import * as PageTypes from '@constants/constants';

import {
  getContentfulService,
  LinkOverlay,
  NotFoundComponent,
  SearchPage,
  OverviewComponent
} from '@imports';

import applicationData from '../../../application_data.json';

const ArticleComponent = dynamic(() => import('@components/ArticlePage'), {
  ssr: true
});

export const ComponentSelector = ({ contentData }: any) => {
  const {
    pageText,
    searchTeasers,
    searchCategories,
    showLabelOnArticleCards,
    fallbackImage,
    homeoverviewSlug
  } = applicationData.data.application;
  const content = getContentfulService();
  switch (contentData?.type) {
    case PageTypes.ARTICLE:
      return <ArticleComponent {...contentData} />;
    case PageTypes.APP_PAGE:
    case PageTypes.OVERVIEW:
      const data = contentData.data;

      return (
        <OverviewComponent
          {...{
            data,
            homeSlug: homeoverviewSlug,
            fallbackImage,
            showLabelOnArticleCards
          }}
        />
      );
    case PageTypes.CATEGORY:
      return (
        <div style={{ marginTop: '100px' }}>
          <p>Radio Brand: {contentData.brand}</p>
          Category Page: {contentData.data.fields.title ?? 'No data'}
          {contentData.categoryArticles?.items?.map(
            (article: any, index: number) => (
              <p style={{ color: 'blue' }} key={index}>
                <LinkOverlay
                  itemId={article?.sys?.id}
                  title={article?.fields?.title}
                  content={article}
                >
                  ARTICLE TITLE: {article?.fields?.title}
                </LinkOverlay>
              </p>
            )
          )}
        </div>
      );
    case PageTypes.NOT_FOUND:
      return (
        <NotFoundComponent
          text={pageText}
          categories={searchCategories}
          teasers={searchTeasers.items}
          showLabelOnArticleCards={showLabelOnArticleCards}
          getCardData={content.getCardById}
          getCategoryData={content.getCategoryById}
        />
      );
    case PageTypes.ERROR_500:
      return <h1 data-testid="error500Page">Sorry, er is wat misgegaan.</h1>;
    case PageTypes.SEARCH:
      return (
        <SearchPage
          showLabelOnArticleCards={showLabelOnArticleCards}
          fallbackImageUrl={fallbackImage?.fields?.file?.url}
          searchTeasers={searchTeasers.items}
          searchCategories={searchCategories}
          getCardData={content.getCardById}
        />
      );
    default:
      return null;
  }
};
