import { palette } from '../../palette/Palette';

const BackNavigation = {
  wrapper: {
    borderBottom: '2px solid #dcdbdc'
  },

  backButton: {
    color: palette.grey.first,
    fontFamily: 'QanelasSoftBlack',
    fontSize: 18,
    textTransform: 'uppercase'
  },

  hoverColor: {
    color: palette.primary.second
  }
};

export default BackNavigation;
