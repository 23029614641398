import { palette } from '../palette/Palette';

const ArticleSection = {
  header: {
    marginBottom: 48
  },

  articleSectionTitle: {
    color: palette.default.black
  },

  link: {
    '&:hover': {
      '& p': {
        color: palette.secondary.second
      },
      '& path': {
        fill: palette.secondary.second
      }
    },
    alignItems: 'normal'
  },

  linkText: {
    marginTop: 2,
    textTransform: 'uppercase',
    color: palette.primary.second
  },

  linkTextMd: {
    marginTop: 2
  },

  linkTextSm: {
    marginTop: 0
  },

  arrowIcon: {
    fill: palette.primary.second,
    alignSelf: 'flex-start'
  }
};

export default ArticleSection;
