import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_13 = (
  <g
    id="number-13"
    stroke="none"
    transform="translate(1.5, 0.000000)"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M35,39 L35,2.27373675e-13 L25.027248,2.27373675e-13 C24.8092643,2.16051136 22.9564033,3.93323864 20.7765668,3.93323864 L16.7983651,3.93323864 C15.8174387,3.93323864 15,4.76420455 15,5.76136364 L15,11.1903409 C15,12.1875 15.8174387,13.0184659 16.7983651,13.0184659 L25.027248,13.0184659 L25.027248,39 L35,39 Z M68,39 L68,2.27373675e-13 L47.167019,2.27373675e-13 C44.1987315,2.60369318 42.384778,6.59232955 42.384778,11.1903409 C42.384778,12.1875 43.2093023,13.0184659 44.1987315,13.0184659 L50.5750529,13.0184659 C51.6194503,13.0184659 52.4439746,12.1875 52.4439746,11.1903409 C52.4439746,8.86363636 53.8181818,6.03835227 56.8414376,6.03835227 C59.9196617,6.03835227 61.3488372,8.53125 61.3488372,10.5255682 C61.3488372,11.4673295 61.0739958,14.5142045 56.8414376,14.5142045 L54.9175476,14.5142045 C53.9281184,14.5142045 53.1035941,15.3451705 53.1035941,16.3977273 L53.1035941,22.0482955 C53.1035941,23.0454545 53.9281184,23.8764205 54.9175476,23.8764205 L57.5560254,23.8764205 C59.9196617,23.8764205 61.8985201,25.9261364 61.8985201,28.3082386 C61.8985201,30.6903409 60.2494715,32.90625 56.9513742,32.90625 C53.2135307,32.90625 52.2790698,28.9730114 52.2790698,28.0866477 C52.2790698,27.0894886 51.4545455,26.2585227 50.4651163,26.2585227 L43.8689218,26.2585227 C42.8794926,26.2585227 42,27.0894886 42,28.0866477 C42,32.1306818 43.4841438,36.2301136 46.6173362,39 L68,39 Z"
      id="1"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_13;
