import { palette } from '../../palette/Palette';

const Tooltip = {
  tooltipWrapper: {
    backgroundColor: palette.primary.first,
    fontFamily: 'QanelasSoftRegular',
    fontSize: 14,
    lineHeight: 1,
    color: palette.default.white
  },

  tooltipWrapperBefore: {
    borderTopColor: palette.primary.first
  },

  tooltipWrapperSm: {
    backgroundColor: palette.primary.second,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: '4px 8px'
  },

  tooltipWrapperBeforeSm: {
    borderTopColor: palette.primary.second
  }
};

export default Tooltip;
