import { palette } from '../palette/Palette';

const PageNotFound = {
  smallGrid: {
    width: '800px'
  },
  padding: 100,
  paddingLg: 150,
  paddingLl: 150,
  paddingMd: 120,
  paddingSm: 70,
  paddingBottomLl: 60,
  paddingBottomMd: 80,
  paddingBottomSm: 20,
  logo: {
    width: 234,
    height: 192
  },
  logoMd: {
    width: 185,
    height: 152
  },
  logoSm: {
    width: 171,
    height: 140
  },
  title: {
    display: 'block',
    textTransform: 'uppercase'
  },
  categoryTeaseHolderSm: {
    marginBottom: 60
  },
  subTitle: {
    color: palette.default.black
  }
};

export default PageNotFound;
