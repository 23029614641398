import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_49 = (
  <g
    id="number-49"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M34.271028,39 L34.271028,32.90625 L39.1495327,32.90625 C40.1588785,32.90625 41,32.0752841 41,31.078125 L41,25.2059659 C41,24.1534091 40.1588785,23.3224432 39.1495327,23.3224432 L34.271028,23.3224432 L34.271028,0 L18.4579439,0 L6.06542056,23.1008523 C5.09995937,24.9024827 5.00667809,25.1328702 5.00039174,26.1676107 L5,31.078125 C5,32.0752841 5.78504673,32.90625 6.79439252,32.90625 L24.0093458,32.90625 L24.0093458,39 L34.271028,39 Z M63.7877462,39 L68,30.8565341 L68,0 L48.4157549,0 C44.9693654,2.88068182 43,7.36789773 43,12.796875 C43,22.2144886 48.8533917,28.8068182 57.8796499,28.9730114 L52.6827133,39 L63.7877462,39 Z M58.47343,20 C54.8599034,20 53,17.647541 53,13 C53,8.40983607 54.8599034,6 58.47343,6 C62.1400966,6 64,8.40983607 64,13 C64,17.647541 62.1400966,20 58.47343,20 Z M24,23 L17,23 L24,10 L24,23 Z"
      id="4"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_49;
