import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_39 = (
  <g
    id="number-39"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M32.4014599,39 C32.6751825,38.7784091 32.8941606,38.5568182 33.1678832,38.2798295 C35.6313869,35.8423295 37,32.4630682 37,28.6960227 C37,24.6519886 35.1386861,21.8821023 32.4562044,19.1676136 C34.9744526,16.6193182 36.4525547,14.2372159 36.4525547,10.5255682 C36.4525547,6.20454545 34.5912409,2.49289773 31.689781,0 L12.1459854,0 C9.18978102,2.60369318 7.32846715,6.59232955 7.32846715,11.1903409 C7.32846715,12.1875 8.20437956,13.0184659 9.18978102,13.0184659 L15.540146,13.0184659 C16.5255474,13.0184659 17.4014599,12.1875 17.4014599,11.1903409 C17.4014599,8.86363636 18.770073,6.03835227 21.7810219,6.03835227 C24.8467153,6.03835227 26.2153285,8.53125 26.2153285,10.5255682 C26.2153285,11.4673295 25.9416058,14.5142045 21.7810219,14.5142045 L19.8649635,14.5142045 C18.879562,14.5142045 18.0583942,15.3451705 18.0583942,16.3977273 L18.0583942,22.0482955 C18.0583942,23.0454545 18.879562,23.8764205 19.8649635,23.8764205 L22.4927007,23.8764205 C24.8467153,23.8764205 26.8175182,25.9261364 26.8175182,28.3082386 C26.8175182,30.6903409 25.1751825,32.90625 21.8905109,32.90625 C18.1678832,32.90625 17.2372263,28.9730114 17.2372263,28.0866477 C17.2372263,27.0894886 16.3613139,26.2585227 15.4306569,26.2585227 L8.86131387,26.2585227 C7.82116788,26.2585227 7,27.0894886 7,28.0866477 C7,32.1306818 8.47810219,36.2301136 11.5985401,39 L32.4014599,39 Z M62.3866944,39 L68,28.5298295 L68,0 L46.6133056,0 C43.0769231,2.88068182 41,7.36789773 41,12.796875 C41,22.2144886 47.0623701,28.8068182 56.3243243,28.9730114 L50.991684,39 L62.3866944,39 Z M57.47343,20 C53.8599034,20 52,17.647541 52,13 C52,8.40983607 53.8599034,6 57.47343,6 C61.1400966,6 63,8.40983607 63,13 C63,17.647541 61.1400966,20 57.47343,20 Z"
      id="3"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_39;
