import { palette } from '../palette/Palette';
import greenVessel from '../../assets/programGuide/greenVessel.png';
import purpleVessel from '../../assets/programGuide/purpleVessel.png';

const ProgramSlide = {
  containerOuter: {
    paddingRight: 10,
    color: palette.grey.first
  },
  containerInner: {
    cursor: 'default',
    backgroundSize: 'cover'
  },
  containerOuterActive: {
    paddingRight: 10,
    containerInner: {
      transition: 'background-color 0.15s ease-in-out'
    }
  },

  showName: {
    color: palette.default.white,
    margin: '0 0 38px',
    textTransform: 'uppercase'
  },

  showNameMd: {
    marginBottom: 34
  },

  showNameSm: {
    marginBottom: 24
  },

  showNameLine: {
    background: palette.default.black,
    borderRadius: 50,
    padding: '4px 12px'
  },

  onAirBadge: {
    padding: '5px 8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 65,
    height: 26,
    textTransform: 'uppercase'
  },

  onAirBadgeMd: {
    width: 54,
    height: 21
  },

  onAirBadgeSm: {
    width: 45,
    height: 19
  },

  graphicWrapper: {
    height: 63,
    display: 'flex',
    justifyContent: 'center'
  },
  timeStart: {
    display: 'flex',
    color: palette.default.white,
    left: 15,
    bottom: 0,
    borderRadius: 50,
    backgroundColor: palette.default.black,
    padding: '7px 16px'
  },
  timeEnd: {
    display: 'flex',
    color: palette.default.white,
    right: 15,
    bottom: 0,
    borderRadius: 50,
    backgroundColor: palette.default.black,
    padding: '7px 16px'
  },
  timeStartMd: {
    left: 31,
    bottom: 0,
    padding: '7px 16px'
  },
  timeEndMd: {
    right: 31,
    bottom: 0,
    padding: '7px 16px'
  },
  timeStartSm: {
    left: 21,
    bottom: 0,
    padding: '4px 10px'
  },
  timeEndSm: {
    right: 21,
    bottom: 0,
    padding: '4px 10px'
  },
  daySliderBackground: {
    width: 240,
    backgroundColor: palette.default.black,
    backgroundImage: 'none'
  },
  daySliderBackgroundMd: {
    width: 200
  },

  slideGreen: {
    backgroundImage: `url(${(greenVessel as any).src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '450px 200px',
    backgroundPositionY: 'bottom'
  },
  slidePurple: {
    backgroundImage: `url(${(purpleVessel as any).src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '450px 200px',
    backgroundPositionY: 'bottom'
  },
  slideGreenMd: {
    backgroundSize: '370px 160px'
  },
  slidePurpleMd: {
    backgroundSize: '370px 160px'
  },
  slideGreenSm: {
    backgroundSize: '330px 140px'
  },
  slidePurpleSm: {
    backgroundSize: '330px 140px'
  }
};

export default ProgramSlide;
