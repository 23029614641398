import React from 'react';

const iconSearchNoResults = (
  <g fill="none">
    <path
      d="M81.25 118.75c-20.706 0-37.5-16.794-37.5-37.5s16.794-37.5 37.5-37.5 37.5 16.794 37.5 37.5-16.794 37.5-37.5 37.5m87.881 32.7-40.3-40.294c5.463-8.675 8.669-18.906 8.669-29.906C137.5 50.181 112.319 25 81.25 25S25 50.181 25 81.25s25.181 56.25 56.25 56.25c11.006 0 21.231-3.206 29.906-8.669l40.294 40.3a3.127 3.127 0 0 0 4.419 0l13.262-13.262a3.127 3.127 0 0 0 0-4.419"
      fill="#D8D8D8"
    />
    <path
      d="M104.094 59.255c-12.204-12.204-31.99-12.204-44.195 0-12.203 12.204-12.203 31.99 0 44.194 6.114 6.113 6.241-15.937 17.299-27.017 11.017-11.04 32.986-11.087 26.896-17.177z"
      fill="#F5F5F5"
    />
  </g>
);

export default iconSearchNoResults;
