const BrandHead = () => {
  return (
    <>
      <meta property="og:site_name" content="Radio 538" />
      <meta property="fb:app_id" content="354799163951" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@radio538" />
      {/* favicon */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png?v=106"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png?v=106"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png?v=106"
      />
      <link rel="manifest" href="/site.webmanifest?v=106" />
      <link
        rel="mask-icon"
        href="/safari-pinned-tab.svg?v=106"
        color="#00e34f"
      />
      <link rel="shortcut icon" href="/favicon.ico?v=106" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
      {/* OneTrust Consent intiial SDK script */}
      <script
        type="text/javascript"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-domain-script={process.env.ONETRUST_VENDOR_ID}
      />

      {/* UTAG CHANGE */}
      <script
        type="text/javascript"
        async={false}
        src={process.env.NEXT_PUBLIC_UTAG_SOURCE}
      />
      {/* QUALIFIO SCRIPT  */}
      <script
        src="https://acties.538.nl/v1/loader/script?c=vgXVoPWhC3pCMK0pZuFnAQ&s=yFL83ykHdd9OX5xQHJhOGw&d=acties.538.nl"
        async
      />
      {/* NEW RELIC */}
      <script src="/newrelic.js" />
      <link
        rel="preload"
        href="/fonts/AnzeigenGrotesk-Regular.woff2"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/QanelasSoft-Black.woff2"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/QanelasSoft-Regular.woff2"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/QanelasSoft-SemiBold.woff2"
        as="font"
        crossOrigin=""
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
    </>
  );
};

export default BrandHead;
