import { palette } from '../palette/Palette';

const JackpotCounter = {
  titleJackpot: {
    margin: '0 0 15px',

    '& $title': {
      color: palette.default.black,
      textTransform: 'uppercase'
    }
  },

  jackpotNumWrapper: {
    backgroundColor: palette.primary.first,
    height: 102
  },

  jackpotNumWrapperMd: {
    height: 80,
    maxWidth: 52
  },

  jackpotNumWrapperSm: {
    height: 72,
    maxWidth: 40
  },

  titleJackpotMd: {
    padding: '30px 0 23px'
  },

  titleJackpotSm: {
    padding: '30px 0 6px'
  },

  jackpotNum: {
    padding: '55% 0',
    color: palette.default.black
  },

  jackpotNumSm: {
    padding: '65% 0'
  },

  headerJackpotNumWrapper: {
    backgroundColor: palette.primary.first
  },

  special: {
    '& $jackpotWrapper $jackpotNumWrapper': {
      backgroundColor: palette.default.white
    },

    '& $titleJackpot $title': {
      color: palette.default.white
    }
  }
};

export default JackpotCounter;
