import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setStreamCategory } from '@imports';

interface Props {
  data?: any;
  children: ReactNode;
  setStreamCategory: (data) => void;
}

type StreamInfoCatcherProps = ReturnType<typeof mapDispatchToProps> & Props;

const StreamInfoCatcher = ({ data, children }: StreamInfoCatcherProps) => {
  const onClickHandler = () => {
    if (!data?.fields?.category?.fields) return;
    setStreamCategory(data.fields.category.sys.id);
  };
  return <div onClick={onClickHandler}>{children}</div>;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setStreamCategory }, dispatch);

StreamInfoCatcher.defaultProps = {
  data: {}
};

export default connect(null, mapDispatchToProps)(StreamInfoCatcher);
