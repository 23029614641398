import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_48 = (
  <g
    id="number-48"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M34.271028,39 L34.271028,32.90625 L39.1495327,32.90625 C40.1588785,32.90625 41,32.0752841 41,31.078125 L41,25.2059659 C41,24.1534091 40.1588785,23.3224432 39.1495327,23.3224432 L34.271028,23.3224432 L34.271028,0 L18.4579439,0 L6.06542056,23.1008523 C5.09995937,24.9024827 5.00667809,25.1328702 5.00039174,26.1676107 L5,31.078125 C5,32.0752841 5.78504673,32.90625 6.79439252,32.90625 L24.0093458,32.90625 L24.0093458,39 L34.271028,39 Z M68,39 L68,0 L48.2516411,0 C45.3522976,2.4375 43.6017505,6.09375 43.6017505,10.3039773 C43.6017505,14.015625 45.0787746,16.7301136 47.595186,19.1676136 C44.750547,21.6605114 43,24.8181818 43,28.8622159 C43,33.1832386 44.6411379,36.6732955 47.4310722,39 L68,39 Z M58.4736842,14 C56.3157895,14 54,12.9403974 54,10.0264901 C54,7.11258278 56.3157895,6 58.4736842,6 C60.6842105,6 63,7.11258278 63,10.0264901 C63,12.9403974 60.6842105,14 58.4736842,14 Z M24,23 L17,23 L24,10 L24,23 Z M57.973545,33 C55.1693122,33 53,31.1226994 53,28.5276074 C53,25.9325153 55.1693122,24 57.973545,24 C60.8306878,24 63,25.9325153 63,28.5276074 C63,31.1226994 60.8306878,33 57.973545,33 Z"
      id="4"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_48;
