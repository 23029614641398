import { palette } from '../../palette/Palette';

const TileMessage = {
  messageBox: {
    backgroundColor: palette.grey.sixth,
    color: palette.grey.first
  },
  title: {
    fontFamily: 'QanelasSoftBlack',
    color: palette.grey.first
  },
  text: {
    color: palette.grey.seventh,
    fontFamily: 'QanelasSoftRegular',
    fontSize: 18
  },
  overlay: {
    backgroundColor: palette.default.white
  }
};

export default TileMessage;
