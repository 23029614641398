import { palette } from '../palette/Palette';

const VideoSectionSkeleton = {
  category: {
    backgroundColor: palette.primary.second
  },

  lightGreyBox: {
    backgroundColor: palette.grey.fourth
  },

  middleGreyBox: {
    backgroundColor: palette.grey.sixth
  },

  darkGreyBox: {
    backgroundColor: palette.grey.third
  }
};

export default VideoSectionSkeleton;
