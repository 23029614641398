import { palette } from '../../palette/Palette';

const Playlist = {
  showMore: {
    color: palette.primary.first,
    fontFamily: 'QanelasSoftBlack',
    fontSize: 18,
    lineHeight: 1,
    textTransform: 'uppercase'
  },

  hover: {
    color: palette.secondary.first
  },

  hoverAfter: {
    backgroundColor: palette.secondary.first
  },

  before: {
    borderLeft: `dotted 2px ${palette.grey.second}`
  },

  after: {
    backgroundColor: palette.primary.first
  },

  trackPlayTime: {
    color: palette.grey.second,
    fontFamily: 'QanelasSoftRegular',
    fontSize: 14,
    lineHeight: 1.81
  },

  nowPlaying: {
    color: palette.default.black,
    fontFamily: 'QanelasSoftBlack',
    fontSize: 14,
    lineHeight: 1,
    textTransform: 'uppercase'
  },

  row: {}
};

export default Playlist;
