const TabPanel = {
  body: {
    padding: '0 30px'
  },
  bodySm: {
    padding: '0 20px'
  }
};

export default TabPanel;
