/**
 * Wrapper class around environment
 * which is used to retrieve environment
 * variables and configurations
 */
class Config {
  getDomain = () => process.env.NEXT_PUBLIC_DOMAIN || '';

  getContentfulHost = () =>
    process.env.NEXT_PUBLIC_CONTENTFUL_HOST || 'content.talparad.io';

  getContentfulPreviewHost = () =>
    process.env.NEXT_PUBLIC_CONTENTFUL_HOST_PREVIEW || 'preview.contentful.com';

  getContentfulSpaceID = () => process.env.NEXT_PUBLIC_SPACEID || '';

  getContentfulAccessToken = () => process.env.NEXT_PUBLIC_ACCESSTOKEN || '';

  getContentfulPreviewAccesToken = () =>
    process.env.NEXT_PUBLIC_PREVIEW_TOKEN || '';

  getCotentfulEnvironment = () =>
    process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT || 'development';

  getUniversalApiUrl = () => process.env.NEXT_PUBLIC_UNIVERSAL_API_URL;

  getUniversalApiKey = () => process.env.NEXT_PUBLIC_UNIVERSAL_API_KEY;

  getGigyaApiKey = () => process.env.NEXT_PUBLIC_GIGYA_APIKEY;

  getGigyaBrandName = () => process.env.NEXT_PUBLIC_GIGYA_BRAND;

  // UTAG CHANGE
  getTealiumSource = () => process.env.NEXT_PUBLIC_UTAG_SOURCE;

  getScheduleSource = () => process.env.NEXT_PUBLIC_SCHEDULE_SOURCE;

  getArticleContentBanner = () => ({
    url: process.env.NEXT_PUBLIC_ARTICLE_CONTENT_BANNER_UR,
    sizes: [728, 90]
  });

  getArticleSidebarBanner = () => ({
    url: process.env.NEXT_PUBLIC_ARTICLE_SIDEBAR_BANNER_URL,
    sizes: [300, 250]
  });

  getArticleContentBannerMobile = () => ({
    url: process.env.NEXT_PUBLIC_ARTICLE_CONTENT_BANNER_MOBILE_URL,
    sizes: [320, 50]
  });

  getReactAppSheduleSource = () => process.env.NEXT_PUBLIC_SCHEDULE_SOURCE;
}

const config = new Config();

export default config;
