import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_20 = (
  <g
    id="number-20"
    stroke="none"
    transform="translate(1.5, 0.000000)"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M34.8899083,39 L34.8899083,34.0142045 C34.8899083,33.0170455 34.0642202,32.1860795 33.0733945,32.1860795 L19.8623853,32.1860795 L28.7247706,23.8764205 C30.2110092,22.4914773 35,18.2258523 35,11.0795455 C35,6.42613636 33.1834862,2.54829545 30.1559633,0 L10.0642202,0 C6.87155963,2.60369318 5,6.53693182 5,11.0795455 C5,12.1321023 5.82568807,13.0184659 6.81651376,13.0184659 L13.2568807,13.0184659 C14.2477064,13.0184659 15.0733945,12.1321023 15.0733945,11.0795455 C15.0733945,9.02982955 16.7798165,6.03835227 19.9174312,6.03835227 C23.9908257,6.03835227 24.8715596,9.69460227 24.8715596,11.0795455 C24.8715596,12.8522727 23.7155963,14.6803977 21.5137615,16.8963068 L5.71559633,32.6292614 C5.22018349,33.0724432 5,33.7372159 5,34.5127841 L5,39 L34.8899083,39 Z M68,39 L68,0 L44.7669173,0 C40.3120301,3.65625 38,10.2485795 38,19.5 C38,28.7514205 40.3120301,35.34375 44.7669173,39 L68,39 Z M56.1137947,32.9993384 L56,33 C52.7822581,33 49,31.4412371 49,19.5278351 C49,7.61443299 52.7822581,6 56,6 C59.2177419,6 63,7.61443299 63,19.5278351 C63,31.3010794 59.3062127,32.9618901 56.1137947,32.9993384 L56.1137947,32.9993384 Z"
      id="2"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_20;
