import { palette } from '../../palette/Palette';

import playerTileBackground from '../../../assets/playerTileBackground.png';

const MiniPlayer = {
  title: {
    fontFamily: 'QanelasSoftBlack',
    fontSize: 14
  },

  subTitle: {
    color: palette.grey.seventh,
    fontFamily: 'QanelasSoftRegular',
    fontSize: 12
  },

  tileSm: {
    backgroundSize: 40,
    backgroundImage: `url(${(playerTileBackground as any).src})`
  }
};

export default MiniPlayer;
