import { palette } from '../palette/Palette';

const RelatedArticlesList = {
  relatedTitle: {
    '& h2': {
      color: palette.default.black
    }
  },

  holder: {
    marginBottom: 120
  },

  holderMd: {
    marginBottom: 48
  },

  holderSm: {
    marginBottom: 26
  }
};

export default RelatedArticlesList;
