import React from 'react';

import { palette } from '../theme/palette/Palette';

const dropIcon = (
  <path
    d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32zm0-6C17.64 58 6 46.36 6 32S17.64 6 32 6s26 11.64 26 26-11.64 26-26 26z"
    fill={palette.grey.third}
  />
);

export default dropIcon;
