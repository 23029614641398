import { palette } from '../palette/Palette';

const SearchPageCategoriesStyles = {
  buttonControlSvgHover: {
    '& g rect:nth-child(2)': {
      fill: palette.secondary.first
    }
  }
};

export default SearchPageCategoriesStyles;
