import { palette } from '../palette/Palette';

const VotingTrack = {
  trackWrapper: {
    backgroundColor: palette.default.black
  },

  icon: {
    width: '100%',
    height: '100%',
    viewBox: '0 0 54 54'
  },

  trackOrderIcon: {
    width: '100%',
    height: '100%',
    viewBox: '0 0 248 54'
  },

  trackInfoBackground: {
    backgroundColor: palette.default.black
  },

  info: {
    color: palette.default.white
  },

  progress: {
    background: palette.primary.first
  },

  iconStop: {
    '& g': {
      fill: palette.default.black
    },
    '& g:first-child': {
      '& rect': {
        fill: palette.default.white
      }
    }
  },

  iconPlay: {
    '& rect': {
      fill: palette.default.white
    },
    '& g': {
      fill: palette.default.black
    }
  }
};

export default VotingTrack;
