import { palette } from '../palette/Palette';

const SingleClip = {
  iconSize: 40,
  iconSizeMd: 32,
  imageSize: 112,
  imageSizeSm: 56,

  audioHolderInner: {
    backgroundColor: palette.default.black
  },

  trackTitle: {
    color: palette.default.white
  },

  trackArtist: {
    paddingTop: 10,
    color: palette.grey.third
  },

  imageHolder: {
    borderRadius: '5px 0 0 5px',
    overflow: 'hidden'
  },

  optionsHolder: {
    paddingRight: 30
  },

  optionsHolderMd: {
    paddingRight: 20
  },

  optionsHolderSm: {
    paddingRight: 10
  },

  icon: {
    '& svg': {
      '& g': {
        '& g:first-child': {
          fill: palette.primary.first,
          '& rect': {
            fill: palette.primary.first
          }
        },
        '& g': {
          fill: palette.default.black,
          '& rect': {
            fill: palette.default.black
          }
        }
      }
    }
  }
};

export default SingleClip;
