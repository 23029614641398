import { palette } from '../palette/Palette';

const ListItems = {
  iconSize: {
    width: 16,
    height: 16
  },

  imageHolder: {
    '-webkit-mask-image': '-webkit-radial-gradient(white, white)' // fix for safari browser for border radius not showing
  },

  background: {
    backgroundColor: palette.grey.third
  },

  itemHolder: {
    backgroundColor: palette.grey.fourth
  },

  itemHolderArticle: {
    backgroundColor: palette.default.white
  },

  itemMobileHolder: {
    backgroundColor: palette.default.white
  },

  textHolder: {
    '& p': {
      textTransform: 'uppercase'
    }
  },

  masonryItemHolder: {
    backgroundColor: palette.default.white,

    '&:hover': {
      backgroundColor: palette.grey.fourth
    }
  },

  masonryTitleHover: {
    color: palette.primary.first
  },

  listImageMasonryMd: {
    width: '23%'
  },

  masonryIconSize: {
    width: 16,
    height: 16
  },

  iconHolder: {
    padding: '0 0 2px 0'
  },

  listItemMasonry: {
    paddingTop: 0
  },

  squareList: {
    padding: '0 10px'
  },

  longList: {
    padding: '0 14px'
  },

  longListImageMasonryMd: {
    width: '28%'
  },

  squareListItemSm: {
    padding: '10px 10px 0'
  },

  squareSm: {
    padding: '0 10px 10px'
  },

  longSm: {
    padding: '0 10px 10px'
  },

  icon: {
    bottom: 4,
    right: 4
  },
  listItemArticle: {
    '&:hover': {
      '& span': {
        color: palette.primary.first
      },
      '& svg': {
        width: 48,
        height: 48
      }
    }
  }
};

export default ListItems;
