import React from 'react';

const iconArrowDown = (
  <g
    xmlns="http://www.w3.org/2000/svg"
    id="feedback"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g
      id="538top50_04"
      transform="translate(-435.000000, -544.000000)"
      fill="#FFFFFF"
    >
      <path
        d="M458.236092,583.208791 C457.704049,584.263736 456.28527,584.263736 455.753228,583.208791 L435.180928,546.10989 C434.648886,545.054945 435.358275,544 436.42236,544 L477.744307,544 C478.808391,544 479.517781,545.054945 478.985739,546.10989 L458.236092,583.208791 Z"
        id="down"
      />
    </g>
  </g>
);

export default iconArrowDown;
