import { palette } from '../palette/Palette';

const RelatedArticleItem = {
  wrapper: {
    '&:hover': {
      '& span': {
        color: palette.primary.first
      },
      '& svg': {
        width: 48,
        height: 48
      }
    }
  },

  wrapperSm: {
    maxWidth: 'initial'
  },

  textHolder: {
    color: palette.default.black
  },

  textHolderSm: {
    width: '100%',
    marginLeft: 12,

    '& > span': {
      width: '90%'
    }
  }
};

export default RelatedArticleItem;
