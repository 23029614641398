import { palette } from '../../palette/Palette';

const PlayButton = {
  button: {
    color: palette.primary.first,
    width: 70,
    height: 70,
    hoverColor: palette.secondary.first,

    '& svg': {
      width: 70,
      height: 70,
      '& g:first-child': {
        '& rect': {
          fill: palette.primary.first
        }
      },
      '& g': {
        fill: palette.default.black
      }
    }
  },

  buttonHover: {
    '& svg': {
      '& g:first-child': {
        '& rect': {
          fill: palette.secondary.first
        }
      },
      '& g': {
        fill: palette.default.black
      }
    }
  },

  buttonMiniPlayer: {
    width: 45,
    height: 45,

    '& svg': {
      width: 45,
      height: 45,
      '& g:first-child': {
        '& rect': {
          fill: palette.primary.first
        }
      },
      '& g': {
        fill: palette.default.black
      }
    }
  },

  pulseGradient: {
    first: `radial-gradient(50% 50% at 50% 50%, rgba(0, 227, 79, 0) 0%, rgba(0, 227, 79, 0) 61.46%, rgba(0, 227, 79, 0.486487) 81.77%, ${palette.primary.first} 100%);`,
    second: `radial-gradient(50% 50% at 50% 50%, rgba(0, 227, 79, 0) 0%, rgba(0, 227, 79, 0) 61.46%, rgba(0, 227, 79, 0.486487) 81.77%, ${palette.primary.first} 100%);`
  }
};

export default PlayButton;
