import { palette } from '../palette/Palette';

const LinkListAudioEmbed = {
  notPlaying: {
    width: 40
  },

  notPlayingMd: {
    width: 40
  },

  notPlayingSm: {
    width: 40
  },

  iconPlay: {
    height: 23,
    width: 23
  },

  iconPlayMd: {
    height: 23,
    width: 23
  },

  iconPlaySm: {
    height: 23,
    width: 23
  },

  iconStop: {
    height: 23,
    width: 23
  },

  iconStopMd: {
    height: 23,
    width: 23
  },

  iconStopSm: {
    height: 23,
    width: 23
  },

  title: {
    '&:hover': {
      color: palette.primary.first
    }
  },

  imageOverlay: {
    backgroundColor: palette.primary.first
  },

  playing: {
    width: '124px',
    paddingRight: '32px'
  },

  playingMd: {
    width: '88px',
    paddingRight: '21px'
  },

  playingSm: {
    width: '74px',
    paddingRight: '20px'
  },

  audioHolderInner: {
    maxHeight: 112,

    '&:hover $textHolder': {
      color: palette.primary.first
    },

    '&:hover': {
      backgroundColor: palette.grey.fourth
    }
  },

  imageHolder: {
    width: '28%'
  },

  imageHolderSm: {
    width: 56
  },

  image: {
    maxWidth: '100%'
  },

  textHolderInnerSm: {
    left: '10px'
  },

  square: {
    padding: '0 10px',
    boxSizing: 'border-box'
  },

  long: {
    boxSizing: 'border-box',
    padding: '0 14px'
  },

  audioHolder: {
    paddingTop: 0
  },

  squareSm: {
    padding: '0 10px 10px'
  },

  longSm: {
    padding: '0 10px 10px'
  }
};

export default LinkListAudioEmbed;
