import { generateTheme } from '@talpanetwork/talpa-publishing-theme/theming/generateTheme';

import * as iconSet from '../icons/index';

import * as styles from './styles/index';
import { palette } from './palette/Palette';

const anzeigenFallback = 'Impact, Charcoal, Sans-serif';
const qanelasFallback = 'Arial, Helvetica, Sans-serif';

const QanelasSoftRegular = `'QanelasSoftRegular', ${qanelasFallback}`;
const QanelasSoftBlack = `'QanelasSoftBlack', ${qanelasFallback}`;
const QanelasSoftSemiBold = `'QanelasSoftSemiBold', ${qanelasFallback}`;
const AnzeigenGroteskRegular = `'AnzeigenGroteskRegular', ${anzeigenFallback}`;

const defaultTheme = generateTheme();
const fontSize = 16;

export function pxToEm(value) {
  return `${value / fontSize}em`;
}

const styleOptions = {
  palette: { ...palette },
  icons: {
    ...iconSet,
    defaultFill: palette.primary.first
  },

  shape: {
    noBorderRadius: 0,
    borderRadius: 4
  },

  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195
    }
  },

  components: {
    ...styles
  },

  spacing: {
    unit: 10
  },

  typography: {
    fontFamilyHeavy: QanelasSoftBlack,
    fontFamilyBold: QanelasSoftBlack,
    fontFamilyRegular: QanelasSoftRegular,
    fontFamilyBoldAlt1: AnzeigenGroteskRegular,
    fontFamilyHeavyAlt1: AnzeigenGroteskRegular,

    headerLarge: {
      textTransform: 'uppercase',
      fontSize: pxToEm(32),
      lineHeight: 1,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(30)
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(28)
      }
    },
    headerSmall: {
      fontFamily: QanelasSoftSemiBold
    },
    articleHeadline: {
      fontFamily: AnzeigenGroteskRegular,
      fontSize: pxToEm(48),
      lineHeight: '48px',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(44),
        lineHeight: '44px'
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(38),
        lineHeight: '38px'
      }
    },
    buttonCta: {
      lineHeight: '14px',
      [defaultTheme.breakpoints.down('md')]: {
        lineHeight: '13px'
      },
      [defaultTheme.breakpoints.down('sm')]: {
        lineHeight: '13px'
      }
    },
    headlineLarge: {},
    headlineMedium: {},
    headlineMediumRelatedArticle: {},
    headlineSmall: {
      lineHeight: '17px',
      [defaultTheme.breakpoints.down('md')]: {
        lineHeight: '15px'
      },
      [defaultTheme.breakpoints.down('sm')]: {
        lineHeight: '13px'
      }
    },
    titleMedium: {},
    signatureHeadlineLarge: {
      fontSize: pxToEm(44),
      lineHeight: '38px',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(36),
        lineHeight: '30px'
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(28),
        lineHeight: '24px'
      }
    },
    signatureInitial: {
      fontSize: pxToEm(44),
      lineHeight: '38px',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(36),
        lineHeight: '30px'
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(28),
        lineHeight: '24px'
      }
    },
    signatureHeadlineMedium: {
      fontFamily: QanelasSoftBlack,
      fontSize: pxToEm(20),
      lineHeight: '22px',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(18),
        lineHeight: '20px'
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(16),
        lineHeight: '18px'
      }
    },
    tag: {},
    infoMedium: {},
    paragraph: {},
    inputMedium: {},
    inputSmall: {},
    caption: {},
    labelLarge: {
      fontSize: pxToEm(20),
      lineHeight: 1.1,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(18)
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(16)
      }
    },
    labelMedium: {},
    labelSmall: {},
    linkSmall: {},
    link: {},
    campaignHeader: {
      lineHeight: '54px',
      [defaultTheme.breakpoints.down('lg')]: {
        lineHeight: '40px'
      },
      [defaultTheme.breakpoints.down('md')]: {
        lineHeight: '40px'
      },
      [defaultTheme.breakpoints.down('sm')]: {
        lineHeight: '28px'
      }
    },
    voteTrackTitle: {
      textTransform: 'uppercase',
      fontFamily: QanelasSoftSemiBold
    },
    voteTrackSmall: {
      textTransform: 'uppercase',
      fontFamily: QanelasSoftSemiBold
    },
    voteTrackArtist: {
      textTransform: 'uppercase'
    },
    voteTrackArtistSmall: {
      textTransform: 'uppercase'
    },
    voteButtonCta: {
      textTransform: 'uppercase'
    },
    voteTitle: {
      textTransform: 'uppercase',
      fontFamily: AnzeigenGroteskRegular
    },
    voteByline: {
      fontFamily: QanelasSoftRegular
    },
    voteBylineTelephone: {
      fontFamily: QanelasSoftRegular,
      fontSize: pxToEm(18),
      lineHeight: '20px',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(14),
        lineHeight: '18px'
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(10),
        lineHeight: '14px'
      }
    },
    trackRestTop50: {
      fontFamily: QanelasSoftSemiBold
    },
    quote: {
      fontFamily: AnzeigenGroteskRegular,
      fontSize: pxToEm(44),
      lineHeight: '38px',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(36),
        lineHeight: '30px'
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(28),
        lineHeight: '24px'
      }
    },
    loginProfileName: {
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(16)
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(16)
      }
    },
    notFoundTitle: {
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(20),
        lineHeight: '24px'
      }
    },
    paragraphBold: {
      fontFamily: QanelasSoftSemiBold
    },
    paragraphLink: {
      fontSize: pxToEm(20),
      lineHeight: '30px',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(18),
        lineHeight: '28px'
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(16),
        lineHeight: '24px'
      }
    },
    infoSmall: {
      fontFamily: QanelasSoftSemiBold
    },
    videoListTitle: {
      fontSize: pxToEm(20),
      lineHeight: '40px',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: pxToEm(20),
        lineHeight: '40px'
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(24),
        lineHeight: '24px'
      }
    },
    voteTextBold: {
      fontFamily: QanelasSoftSemiBold
    },
    frequencySearchClearText: {
      [defaultTheme.breakpoints.down('sm')]: {
        lineHeight: '16px'
      }
    },
    trackBold: {
      fontFamily: QanelasSoftBlack
    },

    stationTitle: {
      fontFamily: QanelasSoftBlack,
      fontSize: pxToEm(21),
      lineHeight: '28px',

      [defaultTheme.breakpoints.down('ml')]: {
        fontSize: pxToEm(12),
        lineHeight: '8px'
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: pxToEm(12),
        lineHeight: '8px'
      }
    }
  },

  utils: {
    grid: {
      width: {
        default: '83.71%',
        large: '88%',
        medium: '92.2%',
        small: '100%'
      },

      maxWidth: {
        default: '1280px',
        large: '1067px',
        medium: '944px',
        small: '100%'
      }
    },

    smallGrid: {
      width: 832
    }
  }
};

export const generateThemeWithDeviceType = (deviceType) => {
  return generateTheme(styleOptions, deviceType);
};

export const theme = generateTheme(styleOptions);

export default theme;
