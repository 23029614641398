import { palette } from '@theme/palette/Palette';

const CampaignImageSection = {
  root: {
    '&:hover': {
      '& $buttonRef': {
        backgroundColor: palette.secondary.second
      }
    }
  },

  teaserText: {
    textTransform: 'uppercase'
  },

  subText: {
    textTransform: 'lowercase',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
  buttonRefMd: {
    minHeight: 44
  },
  buttonRefSm: {
    minHeight: 44
  }
};

export default CampaignImageSection;
