const JWPlayer = {
  wrapper: {
    width: 276,
    height: 276
  },

  wrapperSm: {
    width: 255,
    height: 255
  }
};

export default JWPlayer;
