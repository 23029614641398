import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconPlus = (
  <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
    <path
      d="M58.947 26.947H37.053V5.053a5.052 5.052 0 1 0-10.106 0v21.894H5.053a5.052 5.052 0 1 0 0 10.106h21.894v21.894a5.052 5.052 0 1 0 10.106 0V37.053h21.894a5.052 5.052 0 1 0 0-10.106"
      fill={palette.primary.first}
    />
  </g>
);

export default iconPlus;
