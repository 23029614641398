import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_4 = (
  <g
    id="number-04"
    transform="translate(1.5, 0.000000)"
    fill={palette.default.white}
    fillRule="nonzero"
  >
    <path
      d="M5.4,19.5 C5.4,10.2485795 7.72670455,3.65625 12.0477273,0 L33.3758523,0 C37.8076705,3.65625 40.0235795,10.2485795 40.0235795,19.5 C40.0235795,28.7514205 37.8076705,35.34375 33.3758523,39 L12.0477273,39 C7.72670455,35.34375 5.4,28.7514205 5.4,19.5 Z M68.0548295,39 L61.1301136,39 L61.1301136,32.90625 L44.1230114,32.90625 C43.1258523,32.90625 42.3502841,32.0752841 42.3502841,31.078125 L42.3502841,26.3139205 C42.3502841,25.1505682 42.4056818,24.984375 43.4028409,23.1008523 L55.5903409,0 L68.0548295,0 L68.0548295,39 Z M22.7394886,6.03835227 C19.5818182,6.03835227 15.8701705,7.64488636 15.8701705,19.5 C15.8701705,31.3551136 19.5818182,32.90625 22.7394886,32.90625 C25.9525568,32.90625 29.6088068,31.3551136 29.6088068,19.5 C29.6088068,7.64488636 25.9525568,6.03835227 22.7394886,6.03835227 Z M54.2607955,23.3224432 L61.1301136,23.3224432 L61.1301136,10.4701705 L54.2607955,23.3224432 Z"
      id="4"
    />
  </g>
);

export default chartListTop50_4;
