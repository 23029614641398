import { palette } from '../palette/Palette';

const ChartListTop50Filter = {
  filter: {
    color: palette.default.black,
    textTransform: 'uppercase'
  },
  filterCategory: {
    color: palette.grey.second,
    textTransform: 'uppercase'
  },
  activeCategory: {
    color: palette.default.black
  }
};

export default ChartListTop50Filter;
