import { palette } from '../../palette/Palette';

const TabList = {
  list: {
    padding: '0 30px',
    borderBottomColor: palette.grey.third,
    backgroundColor: 'transparent'
  },
  listMd: {
    padding: '0 20px'
  }
};

export default TabList;
