import { palette } from '../palette/Palette';

const VotingScreen = {
  screenWrapper: {
    '& $screenPopup': {
      backgroundColor: palette.default.white,
      color: palette.default.black,

      '& $iconClose': {
        position: 'absolute',
        top: 0,
        right: '3%',
        cursor: 'pointer',
        width: '100%',
        height: '100%'
      }
    },
    '& button': {
      borderRadius: 50
    }
  },

  screen: {
    backgroundColor: palette.default.black,
    color: palette.default.white,
    '& svg': {
      fill: palette.default.black,
      margin: '6% auto 3%'
    }
  },

  screenMd: {
    '& svg': {
      margin: '10% auto'
    }
  },

  linkText: {
    '& p': {
      color: palette.default.white
    }
  },

  linkTextPopup: {
    textAlign: 'center',
    verticalAlign: 'middle',
    '& $p': {
      color: palette.primary.first
    }
  },

  inputTypeText: {
    color: palette.grey.sixth,
    borderBottom: `2px solid ${palette.grey.sixth}`
  },

  telephoneIconHolder: {
    '& svg': {
      fill: palette.grey.sixth
    }
  },

  votingButton: {
    backgroundColor: palette.default.white
  }
};

export default VotingScreen;
