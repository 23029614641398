import { palette } from '../palette/Palette';

const Switch = {
  switch: {
    borderColor: palette.grey.third,
    backgroundColor: palette.grey.third,
    '&:hover': {
      backgroundColor: `${palette.grey.third} !important`
    }
  },

  switchAfter: {
    backgroundColor: palette.grey.sixth
  },

  checkedAfter: {
    backgroundColor: palette.primary.first
  }
};
export default Switch;
