import { palette } from '../../palette/Palette';

const Controls = {
  hover: {
    fill: palette.primary.first
  },
  chatIcon: {
    '& path': {
      fill: palette.grey.first
    }
  }
};

export default Controls;
