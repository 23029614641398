import { palette } from '../palette/Palette';

const ProgramSection = {
  week: {
    padding: '0px',
    marginBottom: 20
  },
  day: {
    margin: '0',
    paddingRight: '20px',
    textTransform: 'capitalize',
    transition: 'color 0.15s ease-in-out'
  },
  weekSm: {
    width: '115%',
    left: '-6%',
    '&:first-child': {
      paddingLeft: 35
    }
  },
  weekXs: {
    '&:first-child': {
      paddingLeft: 5
    }
  },
  daySm: {
    paddingRight: '26px'
  },
  dayMd: {
    paddingRight: '15px'
  },
  container: {
    padding: '0'
  },
  containerMd: {
    padding: '0px',
    width: '150%'
  },
  containerSm: {
    left: '-5%'
  },
  innerContainer: {
    paddingTop: 23
  },
  innerContainerSm: {
    paddingTop: 29,
    height: 200
  },
  sectionTitle: {
    marginBottom: 20
  },
  titleInner: {
    display: 'inline-block',
    padding: '5px 7px 3px 0px',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'top',
    boxSizing: 'border-box'
  },
  title: {
    margin: '0 0 24px',
    textTransform: 'uppercase',
    '&$inactive': {
      color: palette.primary.second,
      '&:hover': {
        color: palette.secondary.second
      }
    },
    '&$active': {
      color: palette.default.black
    }
  },
  titleMd: {
    textTransform: 'capitalize'
  },
  titleSm: {
    margin: '0 0 28px'
  },
  image: {
    maxWidth: 440,
    maxHeight: 220,
    bottom: '7px',
    left: 'calc(50% - 220px)',
    borderRadius: 95
  },
  imageMd: {
    maxWidth: 360,
    maxHeight: 180,
    left: 'calc(50% - 180px)',
    bottom: '5px',
    borderRadius: 78
  },
  imageSm: {
    maxWidth: 320,
    maxHeight: 160,
    left: 'calc(50% - 160px)',
    borderRadius: 68
  }
};

export default ProgramSection;
