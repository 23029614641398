import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconMiniPlayerChevron = (
  <g xmlns="http://www.w3.org/2000/svg" fill="none">
    <g>
      <g transform="translate(32.000000, 32.000000) rotate(-90.000000) translate(-32.000000, -32.000000) translate(0.000000, 0.000000)">
        <rect width="64" height="64" rx="32" />
        <rect width="64" height="64" fill={palette.grey.sixth} rx="32" />
        <rect width="64" height="64" opacity=".302" rx="32" />
      </g>
      <g fill={palette.grey.seventh}>
        <path
          d="M6.563 25.959L17.523 15 6.563 4.041 10.602 0 25.602 15 21.563 19.041 10.602 30z"
          transform="translate(32.000000, 32.000000) rotate(-90.000000) translate(-32.000000, -32.000000) translate(0.000000, 0.000000) translate(17.000000, 17.000000)"
        />
      </g>
    </g>
  </g>
);

export default iconMiniPlayerChevron;
