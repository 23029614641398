import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconLogo = (
  <g fill="none" fillRule="evenodd">
    <path d="M0 0h200v100H0z" />
    <path
      fill={palette.default.white}
      d="M6.667 4H200v92H6.667C2.985 96 0 93.004 0 89.31V10.69C0 6.997 2.985 4 6.667 4z"
    />
    <path
      fill={palette.primary.second}
      d="M179.63 66.092c0 7.984-6.428 14.42-14.4 14.42s-14.399-6.436-14.399-14.42 6.427-14.42 14.4-14.42c7.971 0 14.398 6.436 14.398 14.42"
    />
    <path
      fill={palette.primary.first}
      d="M178.798 33.475c0 7.984-6.427 14.42-14.4 14.42-7.971 0-14.398-6.436-14.398-14.42s6.427-14.42 14.399-14.42c7.972 0 14.399 6.436 14.399 14.42"
    />
    <path
      fill={palette.default.black}
      d="M141.376 50.186l-.803-.743.803-.805c2.163-2.042 4.697-5.632 4.697-11.016 0-10.584-8.466-18.506-19.713-18.506h-.186c-11.247 0-19.775 7.984-19.775 18.506 0 5.384 2.534 8.974 4.697 11.016l.803.805-.803.743c-3.338 3.094-5.377 7.612-5.377 12.13 0 5.013 1.854 9.531 5.13 12.75 3.645 3.59 8.96 5.446 15.387 5.446s11.742-1.857 15.388-5.446c3.337-3.219 5.129-7.737 5.129-12.75 0-4.518-1.978-9.098-5.377-12.13zm-15.202-18.32h.186c4.078 0 6.241 3.342 6.241 5.756 0 5.075-4.82 5.446-6.303 5.446-1.483 0-6.304-.371-6.304-5.446-.061-2.414 2.102-5.756 6.18-5.756zm.062 35.897c-3.832 0-7.169-2.6-6.983-6.004.123-3.342 2.534-6.003 6.983-6.003 4.45 0 6.86 2.661 6.983 6.003.185 3.404-3.152 6.004-6.983 6.004zM81.124 31.928c4.016 0 6.18 3.342 6.18 5.694 0 5.075-4.697 5.446-6.18 5.446h-2.596c-1.36 0-2.472 1.052-2.472 2.476v7.922c0 1.361 1.05 2.476 2.472 2.476h3.584c3.214 0 5.871 2.66 5.871 5.88 0 3.28-3.09 5.879-6.86 5.879-4.82 0-6.426-4.27-6.426-6.19 0-1.36-1.05-2.475-2.472-2.475h-8.59c-1.36 0-2.472 1.052-2.472 2.476 0 13.12 10.011 19 20.022 19 6.366-.062 11.68-1.918 15.326-5.446 3.337-3.219 5.13-7.737 5.13-12.688 0-4.518-1.978-9.098-5.377-12.13l-.803-.743.803-.805c2.163-2.042 4.697-5.632 4.697-11.016 0-10.522-8.467-18.506-19.776-18.506-9.949 0-20.022 6.004-20.022 19.434 0 1.424 1.05 2.476 2.472 2.476h8.713a2.481 2.481 0 0 0 2.472-2.476c-.062-2.104 1.545-6.684 6.304-6.684zm-44.866 17.7c-1.915 0-5.438.496-7.292 3.714-.494.805-1.174 1.362-2.224 1.362h-8.838c-1.36 0-2.471-1.052-2.471-2.414v-.31l3.522-29.83c.062-1.3 1.112-2.229 2.41-2.229h31.888c1.36 0 2.472 1.052 2.472 2.476v7.984c0 1.361-1.113 2.351-2.596 2.351h-22l-.618 5.633v.185l1.607-.557c1.73-.619 3.46-.928 5.006-.928 11.37 0 19.59 7.489 20.764 18.505.123.929.123 1.919.123 2.91 0 13.182-8.713 22.094-21.753 22.094-13.78-.062-21.258-9.84-21.258-19 0-1.424 1.112-2.538 2.472-2.538h8.652c1.42 0 2.472 1.052 2.472 2.476 0 2.785 3.398 6.189 7.662 6.189 4.82 0 7.725-3.466 7.725-9.284 0-5.199-3.152-8.788-7.725-8.788z"
    />
  </g>
);

export default iconLogo;
