import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_43 = (
  <g
    id="number-43"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M34.271028,39 L34.271028,32.90625 L39.1495327,32.90625 C40.1588785,32.90625 41,32.0752841 41,31.078125 L41,25.2059659 C41,24.1534091 40.1588785,23.3224432 39.1495327,23.3224432 L34.271028,23.3224432 L34.271028,0 L18.4579439,0 L6.06542056,23.1008523 C5.09995937,24.9024827 5.00667809,25.1328702 5.00039174,26.1676107 L5,31.078125 C5,32.0752841 5.78504673,32.90625 6.79439252,32.90625 L24.0093458,32.90625 L24.0093458,39 L34.271028,39 Z M68,39 L68,0 L48.0875274,0 C45.1334792,2.60369318 43.3282276,6.59232955 43.3282276,11.1903409 C43.3282276,12.1875 44.1487965,13.0184659 45.1334792,13.0184659 L51.5339168,13.0184659 C52.5185996,13.0184659 53.3391685,12.1875 53.3391685,11.1903409 C53.3391685,8.86363636 54.7067834,6.03835227 57.7155361,6.03835227 C60.833698,6.03835227 62.2013129,8.53125 62.2013129,10.5255682 C62.2013129,11.4673295 61.9277899,14.5142045 57.7155361,14.5142045 L55.8008753,14.5142045 C54.8161926,14.5142045 53.9956236,15.3451705 53.9956236,16.3977273 L53.9956236,22.0482955 C53.9956236,23.0454545 54.8161926,23.8764205 55.8008753,23.8764205 L58.4266958,23.8764205 C60.833698,23.8764205 62.7483589,25.9261364 62.7483589,28.3082386 C62.7483589,30.6903409 61.1619256,32.90625 57.8249453,32.90625 C54.1050328,32.90625 53.1750547,28.9730114 53.1750547,28.0866477 C53.1750547,27.0894886 52.3544858,26.2585227 51.4245077,26.2585227 L44.8052516,26.2585227 C43.8205689,26.2585227 43,27.0894886 43,28.0866477 C43,32.1306818 44.4223195,36.2301136 47.5404814,39 L68,39 Z M24,23 L17,23 L24,10 L24,23 Z"
      id="4"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_43;
