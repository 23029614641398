import React from 'react';

const iconCtaArrow = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M37.22 60.872c2.27 0 3.29-.68 4.312-1.815l21.447-24.851c.68-.795 1.021-1.476 1.021-2.27s-.34-1.475-1.021-2.27L41.532 4.817C40.51 3.68 39.489 3 37.22 3H20.426c-2.383 0-2.724 2.383-1.362 3.858l14.638 16-29.844-.567C1.135 22.29 0 23.312 0 25.695v12.482c0 2.383 1.135 3.405 3.858 3.405l29.844-.568-14.638 16c-1.362 1.475-1.021 3.858 1.362 3.858H37.22z"
  />
);

export default iconCtaArrow;
