import { palette } from '../palette/Palette';

const Button = {
  default: {
    minWidth: 150,
    minHeight: 52,
    padding: '0 24px',
    backgroundColor: palette.primary.second,
    border: 'none',
    textTransform: 'uppercase',
    borderRadius: 50,
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: palette.secondary.first
      }
    },
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: palette.secondary.second
      }
    }
  },

  disabled: {
    opacity: 0.3
  },

  votingAddTrackBtn: {
    color: palette.default.black,
    backgroundColor: palette.primary.first,
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: palette.primary.first
      }
    },
    '@media (hover: hover)': {
      '&:hover': {
        color: palette.default.white,
        backgroundColor: palette.primary.second
      }
    }
  },

  campaignImageButton: {
    fontSize: '1em',
    minHeight: 52,
    padding: '0 24px',
    boxSizing: 'border-box',
    textTransform: 'uppercase',
    backgroundColor: palette.primary.first,
    borderRadius: 50,
    outline: 'none',
    border: 'none',
    margin: '10px 0'
  },

  textFrameButton: {
    minHeight: 44,
    border: 'none',
    color: palette.default.white,
    textTransform: 'uppercase',
    padding: '0 20px',
    borderRadius: 50,
    backgroundColor: palette.primary.second,
    '&:hover': {
      backgroundColor: palette.secondary.second
    }
  },

  campaignImageButtonMd: {
    minWidth: 135,
    minHeight: 44,
    margin: '16px 0'
  },

  campaignImageButtonSm: {
    margin: '50px 0 50px'
  },

  defaultMd: {
    minWidth: 135,
    minHeight: 44
  },

  imageSectionButton: {
    fontSize: '1em',
    minWidth: 128,
    minHeight: 52,
    padding: '0 24px',
    boxSizing: 'border-box',
    textTransform: 'uppercase',
    backgroundColor: palette.primary.second,
    borderRadius: 50,
    outline: 'none',
    border: 'none',
    objectFit: 'contain',
    '&:hover': {
      backgroundColor: palette.primary.second
    }
  },

  imageSectionButtonSl: {
    minWidth: 104,
    minHeight: 44
  },

  imageSectionButtonMd: {
    margin: '16px 0'
  },

  imageSectionButtonSm: {
    margin: 0,
    minWidth: 88,
    minHeight: 44
  },

  registerModalButton: {
    borderRadius: 50,
    textTransform: 'uppercase',
    backgroundColor: palette.primary.first,
    '& p': {
      color: palette.default.black
    },
    '&:hover': {
      backgroundColor: palette.secondary.first
    }
  }
};

export default Button;
