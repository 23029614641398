import React from 'react';

const iconClock = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M32 0c17.6 0 32 14.4 32 32S49.6 64 32 64 0 49.6 0 32 14.4 0 32 0zm0 6.4C17.92 6.4 6.4 17.92 6.4 32c0 14.08 11.52 25.6 25.6 25.6 14.08 0 25.6-11.52 25.6-25.6C57.6 17.92 46.08 6.4 32 6.4zm1.6 8.8v16.64L48 40.48l-2.56 4.16L28.8 34.4V15.2h4.8z"
  />
);

export default iconClock;
