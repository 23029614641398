import { palette } from '../palette/Palette';

const VotingButton = {
  button: {
    color: palette.default.white,
    '&#upVoting': {
      backgroundColor: palette.primary.second,
      msGridColumn: '1'
    },
    '&#upVoting:hover': {
      color: palette.default.white,
      backgroundColor: palette.secondary.second
    },
    '&#endVoting': {
      msGridColumn: '1',
      backgroundColor: palette.primary.second,
      '&:hover': {
        backgroundColor: palette.default.black
      }
    },
    '&#downVoting': {
      msGridColumn: '2',
      backgroundColor: palette.primary.first,
      '&:hover': {
        backgroundColor: palette.secondary.first
      }
    }
  },

  buttonMl: {
    '&#upVoting': {
      minHeight: 0
    },
    '&#downVoting': {
      minHeight: 0
    },
    '&#endVoting': {
      minHeight: 0
    }
  },

  icon: {
    width: '100%',
    height: '100%',
    viewBox: '0 0 45 40'
  },

  buttonMd: {
    '&#upVoting:hover': {
      backgroundColor: palette.primary.second
    },
    '&#downVoting': {
      '&:hover': {
        backgroundColor: palette.primary.first
      }
    },
    '&#endVoting': {
      '&:hover': {
        backgroundColor: palette.primary.second
      }
    }
  }
};

export default VotingButton;
