import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_8 = (
  <g
    id="number-08"
    stroke="none"
    strokeWidth="1"
    transform="translate(1.5, 0.000000)"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M6,19.5 C6,10.2485795 8.32670455,3.65625 12.6477273,0 L33.9758523,0 C38.4076705,3.65625 40.6235795,10.2485795 40.6235795,19.5 C40.6235795,28.7514205 38.4076705,35.34375 33.9758523,39 L12.6477273,39 C8.32670455,35.34375 6,28.7514205 6,19.5 Z M68.15625,39 L47.7698864,39 C44.9446023,36.6732955 43.2826705,33.1832386 43.2826705,28.8622159 C43.2826705,24.8181818 45.0553977,21.6605114 47.9360795,19.1676136 C45.3877841,16.7301136 43.8920455,14.015625 43.8920455,10.3039773 C43.8920455,6.09375 45.6647727,2.4375 48.6008523,0 L68.15625,0 L68.15625,39 Z M23.3394886,6.03835227 C20.1818182,6.03835227 16.4701705,7.64488636 16.4701705,19.5 C16.4701705,31.3551136 20.1818182,32.90625 23.3394886,32.90625 C26.5525568,32.90625 30.2088068,31.3551136 30.2088068,19.5 C30.2088068,7.64488636 26.5525568,6.03835227 23.3394886,6.03835227 Z M63.5028409,10.2485795 C63.5028409,7.20170455 61.0653409,6.03835227 58.7386364,6.03835227 C56.4673295,6.03835227 54.0298295,7.20170455 54.0298295,10.2485795 C54.0298295,13.2954545 56.4673295,14.4034091 58.7386364,14.4034091 C61.0653409,14.4034091 63.5028409,13.2954545 63.5028409,10.2485795 Z M64.0014205,28.4190341 C64.0014205,25.8153409 61.7301136,23.8764205 58.7386364,23.8764205 C55.8025568,23.8764205 53.53125,25.8153409 53.53125,28.4190341 C53.53125,31.0227273 55.8025568,32.90625 58.7386364,32.90625 C61.7301136,32.90625 64.0014205,31.0227273 64.0014205,28.4190341 Z"
      id="8"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_8;
