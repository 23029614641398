import React from 'react';

const iconVoteArrow = (
  <g transform="translate(-175.000000, -180.000000)" fill="#FFFFFF">
    <path
      d="M289.362921,278.514286 C290.746231,281.257143 288.901818,284 286.135198,284 L178.698135,284 C175.931516,284 174.087103,281.257143 175.470413,278.514286 L228.958392,182.057143 C230.341702,179.314286 234.030528,179.314286 235.413838,182.057143 L289.362921,278.514286 Z"
      id="up"
    />
  </g>
);

export default iconVoteArrow;
