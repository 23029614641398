import { palette } from '../palette/Palette';

const SearchListItem = {
  itemHolder: {
    '&:after': {
      content: '" "',
      width: 'calc(100% - 32px)',
      height: 2,
      backgroundColor: palette.grey.fourth,
      position: 'absolute',
      bottom: 0
    }
  },

  itemHolderHover: {
    backgroundColor: palette.grey.fourth,
    boxSizing: 'border-box',

    '& $itemCategory': {
      color: palette.secondary.second
    }
  },

  itemHolderMd: {
    margin: '0 -15px',
    padding: '20px 15px'
  },

  imageHolder: {
    width: 114,
    height: 70
  },

  textHolder: {
    marginLeft: 12
  },

  backgroundInner: {
    backgroundColor: palette.grey.fifth
  },

  highlight: {
    color: palette.primary.second
  },

  articleText: {
    height: 20,
    color: palette.grey.second
  },

  cardDate: {
    color: palette.grey.second,
    top: 0,
    fontSize: 16
  },

  cardDateMd: {
    fontSize: 14
  },

  cardDateSm: {
    fontSize: 12
  },

  itemCategory: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: '11px',
    color: palette.primary.second
  },

  itemCategoryMd: {
    fontSize: 12
  },

  itemCategorySm: {
    fontSize: 10
  },

  svgIcon: {
    width: 20,
    height: 20
  },

  videoIconSm: {
    width: 16,
    height: 16
  },

  divider: {
    color: palette.grey.second,

    height: 16
  },

  dividerMd: {
    height: 14
  }
};
export default SearchListItem;
