import { palette } from '../../palette/Palette';

const Scrubber = {
  startTime: {
    color: palette.grey.third,
    fontFamily: 'QanelasSoftRegular'
  },
  endTime: {
    color: palette.grey.third,
    fontFamily: 'QanelasSoftRegular'
  }
};

export default Scrubber;
