import React from 'react';

const iconChevronDown = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M49.987 16.3905L32.3906 33.9892C31.8701 34.5101 31.0259 34.5101 30.5051 33.9895C30.5051 33.9894 30.505 33.9894 30.5051 33.9892L12.9087 16.3905C12.388 15.8699 11.5438 15.8698 11.023 16.3903L5.72418 21.6872C5.20322 22.2077 5.20307 23.0519 5.72368 23.5727C5.72374 23.5727 5.72379 23.5728 5.72401 23.5727L30.5051 48.3537C31.0259 48.8742 31.87 48.8743 32.3908 48.3539L38.6325 42.1144C38.6326 42.1143 38.6327 42.1143 38.6327 42.1142L57.1718 23.5727C57.6926 23.0521 57.6925 22.2079 57.1718 21.6872C57.1717 21.6872 57.1717 21.6871 57.1716 21.6872L51.8728 16.3903C51.3519 15.8698 50.5078 15.8699 49.987 16.3905Z"
  />
);

export default iconChevronDown;
