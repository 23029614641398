import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_17 = (
  <g
    id="number-17"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M35,39 L35,0 L25.027248,0 C24.8092643,2.16051136 22.9564033,3.93323864 20.7765668,3.93323864 L16.7983651,3.93323864 C15.8174387,3.93323864 15,4.76420455 15,5.76136364 L15,11.1903409 C15,12.1875 15.8174387,13.0184659 16.7983651,13.0184659 L25.027248,13.0184659 L25.027248,39 L35,39 Z M58.7420043,39 L68,14.7911932 L68,0 L42,0 L42,5.04119318 C42,6.03835227 42.8315565,6.92471591 43.8294243,6.92471591 L60.5159915,6.92471591 L47.9317697,39 L58.7420043,39 Z"
      id="1"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_17;
