import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50DoubleArrow = (
  <g
    id="Productie"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g
      id="538Top50_desktop-(filter:-risers)"
      transform="translate(-1291.000000, -1197.000000)"
      fill={palette.primary.first}
      fillRule="nonzero"
    >
      <g id="Group-5" transform="translate(548.000000, 1173.000000)">
        <path
          d="M758.487,38.515 C758.853,38.515 759.097,38.149 758.914,37.783 L751.777,24.912 C751.594,24.546 751.106,24.546 750.923,24.912 L743.847,37.783 C743.664,38.149 743.908,38.515 744.274,38.515 L758.487,38.515 Z M758.487,56.937 C758.853,56.937 759.097,56.571 758.914,56.205 L751.777,43.334 C751.594,42.968 751.106,42.968 750.923,43.334 L743.847,56.205 C743.664,56.571 743.908,56.937 744.274,56.937 L758.487,56.937 Z"
          id="^"
        />
      </g>
    </g>
  </g>
);

export default chartListTop50DoubleArrow;
