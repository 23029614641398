import { palette } from '../palette/Palette';

const ArticleSkeletonStyles = {
  category: {
    backgroundColor: palette.primary.second
  },

  socialLink: {
    backgroundColor: palette.primary.second
  },

  lightGreyBox: {
    backgroundColor: palette.grey.sixth
  },

  darkGreyBox: {
    backgroundColor: palette.grey.third
  }
};

export default ArticleSkeletonStyles;
