import { palette } from '@theme/palette/Palette';

const OverviewStyles = (theme) => ({
  holder: {},

  overview: {
    minHeight: `calc(100vh - ${theme.components.Navigation.mainHeader.height}px)`,
    [theme.breakpoints.down('md')]: {
      minHeight: `calc(100vh - ${theme.components.Navigation.mainHeaderMd.height}px)`
    }
  },

  wrapper: {
    position: 'relative',
    width: theme.utils.grid.width.default,
    maxWidth: theme.utils.grid.maxWidth.default,
    margin: '0 auto',
    overflow: 'hidden',

    [theme.breakpoints.down('lg')]: {
      width: theme.utils.grid.width.large,
      maxWidth: theme.utils.grid.maxWidth.large
    },

    [theme.breakpoints.down('md')]: {
      width: theme.utils.grid.width.medium,
      maxWidth: theme.utils.grid.maxWidth.medium
    },

    [theme.breakpoints.down('sm')]: {
      width: theme.utils.grid.width.small,
      maxWidth: theme.utils.grid.maxWidth.small,
      padding: `0 ${theme.spacing.unit * 2}px`,
      boxSizing: 'border-box'
    }
  },

  wrapperBackground: {
    backgroundColor: palette.grey.fourth
  }
});

export default OverviewStyles;
