import { palette } from '../palette/Palette';

const Modal = {
  modalHeader: {
    backgroundColor: palette.default.white,
    '& p': {
      color: palette.default.black
    },
    textTransform: 'uppercase'
  },

  closeIconSvg: {
    fill: palette.primary.first
  },

  modalButton: {
    backgroundColor: palette.primary.second,
    '&:hover': {
      backgroundColor: palette.secondary.second
    },
    minHeight: 51,
    height: 51
  },

  firstButton: {
    minHeight: 51,
    height: 51
  },

  firstButtonMd: {
    minHeight: 43,
    height: 43
  },

  firstButtonSm: {
    minHeight: 34,
    height: 34
  },

  modalButtonMd: {
    minHeight: 43,
    height: 43
  },

  modalButtonSm: {
    minHeight: 34,
    height: 34
  }
};

export default Modal;
