export enum ContentId {
  SECTION = 'section',
  IMAGE_SECTION = 'imageSection',
  HEADER_SECTION = 'headerSection',
  COMPONENT_SECTION = 'componentSection',
  COMPOSITE_LIST = 'compositeList',
  VIDEO_SECTION = 'videoSection',
  CALENDAR = 'calendar',
  VOTING_LIST = 'votinglist',
  CHART_LIST = 'chartlist',
  AUDIO_EMBED = 'audioEmbed',
  TEXT_FRAME = 'textFrame'
}

export enum COMPONENT_SECTION_TYPE {
  station_picker = 'station_picker',
  program_guide = 'program_guide',
  fragmentjackpot_search = 'fragmentjackpot_search',
  fragmentjackpot_answers_with_datetime = 'fragmentjackpot_answers_with_datetime',
  fragmentjackpot_answers_without_datetime = 'fragmentjackpot_answers_without_datetime',
  auction_widget = 'auction_widget',
  frequencies = 'frequencies'
}

export enum CompositeListType {
  FAQ = 'FAQ',
  CONTEXTUAL_TEXT_CARD = 'Contextual text card'
}
