import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_15 = (
  <g
    id="number-15"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M35,39 L35,0 L25.027248,0 C24.8092643,2.16051136 22.9564033,3.93323864 20.7765668,3.93323864 L16.7983651,3.93323864 C15.8174387,3.93323864 15,4.76420455 15,5.76136364 L15,11.1903409 C15,12.1875 15.8174387,13.0184659 16.7983651,13.0184659 L25.027248,13.0184659 L25.027248,39 L35,39 Z M68,39 L68,13.7386364 C65.2889344,11.3565341 61.7479508,9.97159091 57.5430328,9.97159091 C55.8278689,9.97159091 54.2786885,10.4147727 52.6741803,11.0241477 L53.1168033,6.92471591 L68,6.92471591 L68,0 L43.8770492,0 L41.2766393,21.4943182 C41.1659836,22.6576705 42.1065574,23.5440341 43.1577869,23.5440341 L49.5758197,23.5440341 C50.4057377,23.5440341 50.9036885,23.2670455 51.2909836,22.546875 C52.5635246,19.7215909 55.7172131,19.5 56.9344262,19.5 C60.3094262,19.5 62.6331967,22.1036932 62.6331967,26.0923295 C62.6331967,30.3025568 60.4754098,32.90625 56.9344262,32.90625 C54.3340164,32.90625 51.2356557,30.9119318 51.2356557,28.3636364 C51.2356557,27.3664773 50.3504098,26.5355114 49.3545082,26.5355114 L42.8811475,26.5355114 C41.829918,26.5355114 41,27.3664773 41,28.3636364 C41,32.2414773 42.8258197,36.2855114 46.2008197,39 L68,39 Z"
      id="1"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_15;
