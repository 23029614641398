import { palette } from '../palette/Palette';

const secondaryButton = {
  '& $buttonSecondary': {
    background: palette.primary.first,
    '& p': {
      color: palette.default.black
    },
    '&:hover': {
      background: palette.secondary.first,
      '& p': {
        color: palette.default.black
      }
    }
  }
};

const primaryButton = {
  '& $buttonPrimary': {
    background: palette.primary.second,
    '@media (hover: none)': {
      '&:hover': {
        color: palette.default.white,
        backgroundColor: palette.secondary.second
      }
    },
    '@media (hover: hover)': {
      '&:hover': {
        color: palette.default.white,
        backgroundColor: palette.secondary.second
      }
    }
  }
};

const HeaderSectionSlide = {
  contentWrapper: {
    width: '100%',
    maxWidth: 'none'
  },
  contentWrapperLg: {
    width: '100%',
    maxWidth: 'none'
  },

  textContentHolder: {
    paddingLeft: '15%'
  },

  textContentHolderLg: {
    paddingLeft: '13%'
  },

  textContentHolderMl: {
    paddingLeft: '11%'
  },

  dark: {
    background: palette.default.black,
    '& $textHolder': {
      '& a': {
        color: palette.primary.first
      }
    },
    color: palette.default.white,
    '& $jackpotCounterWrapper': {
      '& p': {
        color: palette.default.white
      }
    },

    '& $imageOverlay': {
      '& path': {
        fill: palette.default.black
      }
    },

    ...primaryButton,
    ...secondaryButton
  },

  light: {
    background: palette.grey.fourth,
    '& $textHolder': {
      '& a': {
        color: palette.primary.second
      }
    },
    color: palette.default.black,
    '& $jackpotCounterWrapper': {
      '& p': {
        color: palette.default.black
      }
    },

    '& $imageOverlay': {
      '& path': {
        fill: palette.grey.fourth
      }
    },

    ...primaryButton,
    ...secondaryButton
  },

  color: {
    '& $textHolder': {
      '& a': {
        color: palette.primary.first
      }
    },
    background: palette.primary.second,
    color: palette.default.white,
    '& $buttonPrimary': {
      background: palette.primary.first,
      '& p': {
        color: palette.default.black
      },
      '& svg': {
        '& g': {
          fill: palette.default.black
        }
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: palette.secondary.first
        }
      },
      '@media (hover: none)': {
        '&:active': {
          backgroundColor: palette.primary.first
        }
      }
    },

    '& $buttonSecondary': {
      border: '2px solid'
    },

    '& $jackpotCounterWrapper': {
      '& p': {
        color: palette.default.white
      }
    }
  },

  special: {
    background: palette.misc.first,
    color: palette.default.white,
    '& $titleHolder h1': {
      color: palette.default.white
    },
    '& $textHolder': {
      '& a': {
        color: palette.primary.second
      },
      '& p': {
        color: palette.grey.sixth
      }
    },

    '& $imageOverlay': {
      '& path': {
        fill: palette.misc.first
      }
    },

    ...primaryButton,
    ...secondaryButton
  },

  imageOverlaySvg: {
    left: -2,
    '& path': {
      fill: palette.primary.second
    }
  },

  rootMd: {
    height: 829
  },
  rootSm: {
    height: 620
  },

  buttonsHolderMd: {
    '& button': {
      minHeight: 44
    }
  },

  buttonsHolderSm: {
    '& button': {
      minHeight: 44
    }
  },

  buttonCommon: {
    color: palette.default.white
  },

  iconPlayOnButton: {
    width: 24,
    height: 24
  },

  iconPlayOnButtonMd: {
    width: 20,
    height: 20
  },

  iconPlayOnButtonSm: {
    width: 16,
    height: 16
  },

  buttonPrimaryMd: {
    // because of hover on default button element
    '&:hover': {
      background: palette.primary.first
    },
    '&:active': {
      background: palette.primary.second
    }
  },
  image: {
    height: 480,
    width: '100% !important',
    objectFit: 'cover'
  },
  imageSm: {
    objectFit: 'unset',
    height: 'auto !important'
  }
};

export default HeaderSectionSlide;
