import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_23 = (
  <g
    id="number-23"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M36.8899083,39 L36.8899083,34.0142045 C36.8899083,33.0170455 36.0642202,32.1860795 35.0733945,32.1860795 L21.8623853,32.1860795 L30.7247706,23.8764205 C32.2110092,22.4914773 37,18.2258523 37,11.0795455 C37,6.42613636 35.1834862,2.54829545 32.1559633,0 L12.0642202,0 C8.87155963,2.60369318 7,6.53693182 7,11.0795455 C7,12.1321023 7.82568807,13.0184659 8.81651376,13.0184659 L15.2568807,13.0184659 C16.2477064,13.0184659 17.0733945,12.1321023 17.0733945,11.0795455 C17.0733945,9.02982955 18.7798165,6.03835227 21.9174312,6.03835227 C25.9908257,6.03835227 26.8715596,9.69460227 26.8715596,11.0795455 C26.8715596,12.8522727 25.7155963,14.6803977 23.5137615,16.8963068 L7.71559633,32.6292614 C7.22018349,33.0724432 7,33.7372159 7,34.5127841 L7,39 L36.8899083,39 Z M68,39 L68,0 L46.1795918,0 C43.2040816,2.60369318 41.3306122,6.59232955 41.3306122,11.1903409 C41.3306122,12.1875 42.2122449,13.0184659 43.2040816,13.0184659 L49.5959184,13.0184659 C50.5877551,13.0184659 51.4693878,12.1875 51.4693878,11.1903409 C51.4693878,8.86363636 52.8469388,6.03835227 55.877551,6.03835227 C58.9632653,6.03835227 60.3408163,8.53125 60.3408163,10.5255682 C60.3408163,11.4673295 60.0653061,14.5142045 55.877551,14.5142045 L53.9489796,14.5142045 C52.9571429,14.5142045 52.1306122,15.3451705 52.1306122,16.3977273 L52.1306122,22.0482955 C52.1306122,23.0454545 52.9571429,23.8764205 53.9489796,23.8764205 L56.5938776,23.8764205 C58.9632653,23.8764205 60.9469388,25.9261364 60.9469388,28.3082386 C60.9469388,30.6903409 59.2938776,32.90625 55.9877551,32.90625 C52.2408163,32.90625 51.3040816,28.9730114 51.3040816,28.0866477 C51.3040816,27.0894886 50.422449,26.2585227 49.4857143,26.2585227 L42.8734694,26.2585227 C41.8265306,26.2585227 41,27.0894886 41,28.0866477 C41,32.1306818 42.4877551,36.2301136 45.6285714,39 L68,39 Z"
      id="2"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_23;
