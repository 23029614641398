import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListNew = (
  <g
    xmlns="http://www.w3.org/2000/svg"
    id="Productie"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g
      id="538Top50_tablet-(filter:-new)"
      transform="translate(-788.000000, -1239.000000)"
      fill={palette.primary.second}
      fillRule="nonzero"
    >
      <g id="Group-5-Copy" transform="translate(16.000000, 1203.000000)">
        <path
          d="M775.54,48 C775.94,48 776.116,47.824 776.116,47.424 L776.116,42.528 L778.932,47.648 C779.076,47.904 779.22,48 779.62,48 L781.844,48 C782.244,48 782.42,47.824 782.42,47.424 L782.42,37.248 C782.42,36.848 782.244,36.672 781.844,36.672 L779.636,36.672 C779.236,36.672 779.06,36.848 779.06,37.248 L779.06,42.144 L776.244,37.024 C776.1,36.768 775.956,36.672 775.556,36.672 L773.332,36.672 C772.932,36.672 772.756,36.848 772.756,37.248 L772.756,47.424 C772.756,47.824 772.932,48 773.332,48 L775.54,48 Z M791.444,48 C791.844,48 792.02,47.824 792.02,47.424 L792.02,45.696 C792.02,45.296 791.844,45.12 791.444,45.12 L787.508,45.12 L787.508,43.632 L791.108,43.632 C791.476,43.632 791.668,43.44 791.668,43.072 L791.668,41.456 C791.668,41.072 791.476,40.88 791.108,40.88 L787.508,40.88 L787.508,39.552 L791.348,39.552 C791.748,39.552 791.924,39.376 791.924,38.976 L791.924,37.248 C791.924,36.848 791.748,36.672 791.348,36.672 L784.66,36.672 C784.26,36.672 784.084,36.848 784.084,37.248 L784.084,47.424 C784.084,47.824 784.26,48 784.66,48 L791.444,48 Z M798.692,48 C799.108,48 799.284,47.824 799.38,47.488 L800.34,43.776 L800.788,41.76 L800.852,41.76 L801.3,43.776 L802.26,47.488 C802.356,47.824 802.532,48 802.948,48 L805.396,48 C805.812,48 805.988,47.824 806.084,47.488 L808.772,37.36 C808.884,36.928 808.724,36.672 808.34,36.672 L805.94,36.672 C805.54,36.672 805.316,36.848 805.236,37.2 L804.452,41.024 L803.956,43.632 L803.892,43.632 L803.38,41.024 L802.516,37.184 C802.436,36.832 802.228,36.672 801.812,36.672 L799.828,36.672 C799.412,36.672 799.204,36.832 799.124,37.184 L798.26,41.024 L797.748,43.632 L797.684,43.632 L797.188,41.024 L796.404,37.2 C796.324,36.848 796.1,36.672 795.7,36.672 L793.3,36.672 C792.916,36.672 792.756,36.928 792.868,37.36 L795.556,47.488 C795.652,47.824 795.828,48 796.244,48 L798.692,48 Z"
          id="NEW"
        />
      </g>
    </g>
  </g>
);

export default chartListNew;
