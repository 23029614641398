import { palette } from '../palette/Palette';

const UniversalVotingUserList = {
  content: {
    backgroundColor: palette.grey.eight
  },

  userListTab: {
    '& p': {
      fontFamily: 'QanelasSoftBlack',
      fontSize: 28,
      textTransform: 'uppercase',
      color: palette.default.white
    }
  },

  userListTabWrapper: {
    textTransform: 'uppercase'
  },

  additionalTrack: {
    borderColor: palette.grey.seventh
  },
  text: {
    fontFamily: 'QanelasSoftRegular'
  },

  smallHeader: {
    textTransform: 'uppercase',
    color: palette.default.white
  },

  userListTabMd: {
    padding: 0,

    backgroundColor: 'transparent',

    '& p': {
      fontFamily: 'QanelasSoftBlack',
      fontSize: 20,
      color: palette.default.white
    }
  }
};

export default UniversalVotingUserList;
