import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconQuote = (
  <>
    <path
      d="M18.985 23.492c6.396 2.034 10.421 8.116 9.926 14.989-.474 6.59-5.64 12.012-12.382 12.998-6.409.936-12.807-2.88-15.21-9.235-2.371-6.279-1.444-12.312 1.965-17.922C7.48 17.417 13.961 13.96 21.636 12.57c2.37-.429 4.798-.538 7.267-.57-5.446 2-10.269 4.572-12.088 10.88.873.245 1.528.407 2.17.612z"
      fill={palette.primary.first}
    />
    <path
      d="M51.862 22.88c.874.245 1.528.407 2.17.612 6.398 2.034 10.422 8.116 9.927 14.989-.474 6.59-5.64 12.012-12.382 12.998-6.409.936-12.808-2.88-15.21-9.235-2.372-6.279-1.444-12.312 1.965-17.922 4.193-6.905 10.676-10.363 18.353-11.752 2.369-.429 4.796-.538 7.265-.57-5.445 2-10.268 4.572-12.088 10.88"
      fill={palette.primary.second}
    />
  </>
);

export default iconQuote;
