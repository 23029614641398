import { palette } from '../../palette/Palette';

const StationTile = {
  tile: {
    borderRadius: '5%'
  },
  textWrapper: {
    color: palette.default.white
  },
  text: {
    color: palette.default.white,
    fontFamily: 'QanelasSoftBlack',
    fontSize: 9,
    lineHeight: 1.2,
    textTransform: 'uppercase',
    display: 'none'
  },
  textLastListened: {
    fontSize: 16,
    fontFamily: 'QanelasSoftBlack'
  }
};

export default StationTile;
