import { palette } from '../palette/Palette';

const List = {
  root: {
    padding: '25px'
  },

  refHolder: {
    textAlign: 'center'
  },

  refTitle: {
    textTransform: 'uppercase'
  },

  articleListTitle: {
    display: 'inline-block',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'top',
    boxSizing: 'border-box'
  },

  listTitle: {},

  titleHolder: {
    position: 'relative',
    width: 'calc(100%  + 4px)',
    left: -2,
    top: -2,
    textAlign: 'center'
  },

  listMasonryTitle: {
    backgroundColor: palette.default.black
  },

  listSquareTitle: {
    height: 60,
    lineHeight: '60px'
  },

  listSquareTitleSl: {
    height: 50,
    lineHeight: '55px'
  },

  listSquareTitleSm: {
    height: 60,
    lineHeight: '60px'
  },

  listLongTitle: {
    height: 70,
    lineHeight: '70px'
  },

  listLongTitleMd: {
    height: 60,
    lineHeight: '65px'
  },

  listLongTitleSm: {
    height: 60,
    lineHeight: '60px'
  },

  listMasonryItemHolder: {
    paddingTop: 9
  },

  square: {
    border: `2px solid ${palette.grey.fifth}`,
    boxSizing: 'border-box'
  },

  long: {
    border: `2px solid ${palette.grey.fifth}`,
    boxSizing: 'border-box'
  }
};

export default List;
