import { palette } from '../palette/Palette';

const UniversalVotingEndScreen = {
  bodyText: {
    link: {
      color: palette.primary.second
    },
    subscript: {
      color: palette.grey.second
    }
  },

  titleVotedTracks: {
    textTransform: 'uppercase'
  },

  paragraphLeftTitle: {
    textTransform: 'uppercase'
  },

  lineSeparator: {
    borderBottom: `2px solid ${palette.grey.fourth}`
  },

  titleEndScreen: {
    color: palette.default.black
  },

  confirmationEndText: {
    textTransform: 'uppercase'
  }
};

export default UniversalVotingEndScreen;
