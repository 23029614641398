import { palette } from '../palette/Palette';

const OverviewSkeletonStyles = {
  greyBox: {
    backgroundColor: palette.grey.sixth
  },

  title: {
    backgroundColor: palette.grey.third
  },
  headerHolder: {
    width: '100%',
    maxWidth: '100%'
  },

  header: {
    height: 668
  },

  headerMd: {
    height: 472
  },

  headerSm: {
    height: 595
  }
};

export default OverviewSkeletonStyles;
