import React from 'react';

const iconChevronLeft = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M50.619 55.378 27.237 32 50.62 8.622 42 0 10 32l8.619 8.622L42 64z"
  />
);

export default iconChevronLeft;
