import React from 'react';

const iconMessage = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M59.815 5A4.19 4.19 0 0 1 64 9.185l-.002 35.938a4.19 4.19 0 0 1-4.183 4.185H28.416l-1.378 7.813a2.459 2.459 0 0 1-4.146 1.326l-9.312-9.14H4.185A4.19 4.19 0 0 1 0 45.124V9.185A4.19 4.19 0 0 1 4.185 5zM51.69 24.692H12.308a2.46 2.46 0 1 0 0 4.923H51.69a2.46 2.46 0 1 0 0-4.923zm0-9.846H12.308a2.46 2.46 0 1 0 0 4.923H51.69a2.46 2.46 0 1 0 0-4.923z"
  />
);

export default iconMessage;
