import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_9 = (
  <g
    id="number-09"
    stroke="none"
    strokeWidth="1"
    transform="translate(1.5, 0.000000)"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M6,19.5 C6,10.2485795 8.32670455,3.65625 12.6477273,0 L33.9758523,0 C38.4076705,3.65625 40.6235795,10.2485795 40.6235795,19.5 C40.6235795,28.7514205 38.4076705,35.34375 33.9758523,39 L12.6477273,39 C8.32670455,35.34375 6,28.7514205 6,19.5 Z M68.15625,31.7428977 L64.3338068,39 L53.0880682,39 L58.3508523,28.9730114 C49.2102273,28.8068182 43.2826705,22.2144886 43.2826705,12.796875 C43.2826705,7.36789773 45.2769886,2.88068182 48.7670455,0 L68.15625,0 L68.15625,31.7428977 Z M23.3394886,6.03835227 C20.1818182,6.03835227 16.4701705,7.64488636 16.4701705,19.5 C16.4701705,31.3551136 20.1818182,32.90625 23.3394886,32.90625 C26.5525568,32.90625 30.2088068,31.3551136 30.2088068,19.5 C30.2088068,7.64488636 26.5525568,6.03835227 23.3394886,6.03835227 Z M65.2201705,12.796875 C65.2201705,8.36505682 63.28125,6.03835227 59.4588068,6.03835227 C55.6917614,6.03835227 53.7528409,8.36505682 53.7528409,12.796875 C53.7528409,17.2840909 55.6917614,19.5553977 59.4588068,19.5553977 C63.28125,19.5553977 65.2201705,17.2840909 65.2201705,12.796875 Z"
      id="9"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_9;
