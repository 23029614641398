import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_2 = (
  <g
    id="number-02"
    transform="translate(1.5, 0.000000)"
    fill={palette.default.white}
    fillRule="nonzero"
  >
    <path
      d="M5.4,19.5 C5.4,10.2485795 7.72670455,3.65625 12.0477273,0 L33.3758523,0 C37.8076705,3.65625 40.0235795,10.2485795 40.0235795,19.5 C40.0235795,28.7514205 37.8076705,35.34375 33.3758523,39 L12.0477273,39 C7.72670455,35.34375 5.4,28.7514205 5.4,19.5 Z M68.0548295,21.9928977 C67.2792614,22.8792614 66.6144886,23.4886364 66.1713068,23.8764205 L57.2522727,32.1860795 L68.0548295,32.1860795 L68.0548295,39 L42.3502841,39 L42.3502841,34.5127841 C42.3502841,33.7372159 42.571875,33.0724432 43.0704545,32.6292614 L58.9142045,16.8963068 C61.1855114,14.6803977 62.3488636,12.8522727 62.3488636,11.0795455 C62.3488636,9.69460227 61.4625,6.03835227 57.3076705,6.03835227 C54.2053977,6.03835227 52.4880682,9.02982955 52.4880682,11.0795455 C52.4880682,12.1321023 51.6571023,13.0184659 50.6045455,13.0184659 L44.1784091,13.0184659 C43.18125,13.0184659 42.3502841,12.1321023 42.3502841,11.0795455 C42.3502841,6.53693182 44.2338068,2.60369318 47.3914773,0 L68.0548295,0 L68.0548295,21.9928977 Z M22.7394886,6.03835227 C19.5818182,6.03835227 15.8701705,7.64488636 15.8701705,19.5 C15.8701705,31.3551136 19.5818182,32.90625 22.7394886,32.90625 C25.9525568,32.90625 29.6088068,31.3551136 29.6088068,19.5 C29.6088068,7.64488636 25.9525568,6.03835227 22.7394886,6.03835227 Z"
      id="2"
    />
  </g>
);

export default chartListTop50_2;
