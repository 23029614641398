import { palette } from '../palette/Palette';

const Arrow = {
  rightArrowBackground: {
    backgroundColor: palette.default.white,
    width: 40
  },

  leftArrowBackground: {
    backgroundColor: palette.default.white,
    width: 40
  },

  arrowHolder: {
    backgroundColor: palette.primary.first,
    '& svg': {
      fill: palette.default.black
    },
    '&:hover': {
      backgroundColor: palette.secondary.first,
      '& svg': {
        '& rect': {
          fill: palette.secondary.first
        }
      }
    }
  },

  compositeArrowBackgroundRight: {
    background:
      'linear-gradient(to right, rgba(248, 248, 248, 0) 0%,rgba(248, 248, 248,1) 70%,rgba(248, 248, 248,1) 100%)'
  },

  compositeArrowBackgroundLeft: {
    background:
      'linear-gradient(to left, rgb(248, 248, 248, 0) 0%,rgba(248, 248, 248,1) 70%,rgba(248, 248, 248,1) 100%)'
  },

  programGuideArrowBackgroundLeft: {
    left: 'auto',
    right: 62,
    top: -38,
    maxHeight: 32,

    '& $arrowHolder': {
      backgroundColor: 'transparent'
    },

    '& $left:first-child': {
      transform: 'translateY(-67%) rotate(180deg)'
    }
  },

  programGuideArrowBackgroundRight: {
    top: -38,
    right: -20,
    maxHeight: 32,
    maxWidth: 32,

    '& $arrowHolder': {
      backgroundColor: 'transparent'
    },

    '& $right:first-child': {
      transform: 'translateY(-67%) rotate(0deg)'
    }
  },

  calendarBackgroundLeft: {
    '& $left': {
      marginLeft: -35
    }
  },

  calendarBackgroundRight: {
    '& $right': {
      marginRight: -32
    }
  }
};

export default Arrow;
