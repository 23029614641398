import { palette } from '../palette/Palette';

const UniversalVotingFreeChoice = {
  freeChoiceTitle: {
    textTransform: 'uppercase'
  },

  trackInput: {
    '&:focus': {
      borderColor: palette.primary.first,
      outline: 'none'
    }
  },

  infoIcon: {
    '& svg': {
      fill: palette.default.black
    }
  },

  freeChoiceMotivationText: {
    fontWeight: 'bold'
  },

  inputTypeText: {
    '&:focus': {
      borderColor: palette.primary.first,
      outline: 'none'
    },
    '&::placeholder': {
      color: palette.grey.second
    }
  },

  button: {
    backgroundColor: palette.primary.first
  },

  closeLink: {
    color: palette.primary.second,
    textTransform: 'uppercase'
  }
};

export default UniversalVotingFreeChoice;
