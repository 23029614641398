import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_14 = (
  <g
    id="number-14"
    stroke="none"
    transform="translate(1.5, 0.000000)"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M35,39 L35,0 L25.027248,0 C24.8092643,2.16051136 22.9564033,3.93323864 20.7765668,3.93323864 L16.7983651,3.93323864 C15.8174387,3.93323864 15,4.76420455 15,5.76136364 L15,11.1903409 C15,12.1875 15.8174387,13.0184659 16.7983651,13.0184659 L25.027248,13.0184659 L25.027248,39 L35,39 Z M68,39 L68,0 L54.0909091,0 L42.0363636,23.1008523 C41.0972332,24.9024827 41.006496,25.1328702 41.0003811,26.1676107 L41,31.078125 C41,32.0752841 41.7636364,32.90625 42.8,32.90625 L59.4909091,32.90625 L59.4909091,39 L68,39 Z M59,23 L52,23 L59,10 L59,23 Z"
      id="1"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_14;
