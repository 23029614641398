import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconNewSliderBack = (
  <g fill="none" fillRule="evenodd">
    <g>
      <g>
        <rect width="64" height="64" rx="32" />
        <rect width="64" height="64" fill={palette.primary.first} rx="32" />
        <rect width="64" height="64" opacity=".302" rx="32" />
      </g>
      <g fill={palette.default.black}>
        <path
          d="M4.688 25.959L15.648 15 4.688 4.041 8.727 0 23.727 15 19.688 19.041 8.727 30z"
          transform="translate(17 17) matrix(-1 0 0 1 28.415 0)"
        />
      </g>
    </g>
  </g>
);

export default iconNewSliderBack;
