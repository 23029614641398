import { palette } from '../palette/Palette';

const Toaster = {
  wrapper: {
    background: palette.grey.fourth
  },

  verticalLine: {
    borderColor: palette.primary.first
  },

  title: {
    textTransform: 'uppercase'
  },

  bodyLink: {
    color: palette.primary.second
  },

  bodyText: {
    color: palette.grey.second
  },

  inner: {
    background: palette.grey.fourth
  },

  bar: {
    background: palette.primary.first
  },

  right: {
    background: palette.grey.fourth
  },

  progress: {
    background: palette.grey.fourth
  },

  closeSign: {
    fill: palette.primary.first
  }
};

export default Toaster;
