import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconNewSliderNext = (
  <g fill="none" fillRule="evenodd">
    <g>
      <g>
        <rect width="64" height="64" rx="32" />
        <rect width="64" height="64" fill={palette.primary.first} rx="32" />
        <rect width="64" height="64" opacity=".302" rx="32" />
      </g>
      <g fill={palette.default.black}>
        <path
          d="M6.563 25.959L17.523 15 6.563 4.041 10.602 0 25.602 15 21.563 19.041 10.602 30z"
          transform="translate(17 17)"
        />
      </g>
    </g>
  </g>
);

export default iconNewSliderNext;
