import { palette } from '../palette/Palette';

const TextStyles = {
  callToAction: {
    marginBottom: 17
  },

  copyTextInner: {
    link: {
      color: palette.primary.second,
      '&:hover': {
        color: palette.secondary.second
      }
    },
    subscript: {
      color: palette.grey.second
    },
    '& img': {
      objectFit: 'cover'
    }
  }
};

export default TextStyles;
