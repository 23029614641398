import { palette } from '../palette/Palette';

const VideoSlider = {
  buttonControlHover: {
    '& svg': {
      '& g': {
        fill: palette.secondary.first,
        '& g': {
          fill: palette.default.white
        }
      }
    }
  }
};

export default VideoSlider;
