import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_46 = (
  <g
    id="number-46"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M34.271028,39 L34.271028,32.90625 L39.1495327,32.90625 C40.1588785,32.90625 41,32.0752841 41,31.078125 L41,25.2059659 C41,24.1534091 40.1588785,23.3224432 39.1495327,23.3224432 L34.271028,23.3224432 L34.271028,0 L18.4579439,0 L6.06542056,23.1008523 C5.09995937,24.9024827 5.00667809,25.1328702 5.00039174,26.1676107 L5,31.078125 C5,32.0752841 5.78504673,32.90625 6.79439252,32.90625 L24.0093458,32.90625 L24.0093458,39 L34.271028,39 Z M68,39 L68,12.1875 C65.8070175,10.8579545 63.1754386,10.0823864 60.1052632,10.0269886 L65.3684211,0 L54.2390351,0 L45.4122807,16.8409091 C43.877193,19.7215909 43,23.15625 43,26.203125 C43,31.6321023 45.0285088,36.1193182 48.4824561,39 L68,39 Z M24,23 L17,23 L24,10 L24,23 Z M58.47343,33 C54.8599034,33 53,30.8065844 53,26.526749 C53,22.1934156 54.8599034,20 58.47343,20 C62.1400966,20 64,22.1934156 64,26.526749 C64,30.8065844 62.1400966,33 58.47343,33 Z"
      id="4"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_46;
