import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_45 = (
  <g
    id="number-45"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M34.271028,39 L34.271028,32.90625 L39.1495327,32.90625 C40.1588785,32.90625 41,32.0752841 41,31.078125 L41,25.2059659 C41,24.1534091 40.1588785,23.3224432 39.1495327,23.3224432 L34.271028,23.3224432 L34.271028,0 L18.4579439,0 L6.06542056,23.1008523 C5.09995937,24.9024827 5.00667809,25.1328702 5.00039174,26.1676107 L5,31.078125 C5,32.0752841 5.78504673,32.90625 6.79439252,32.90625 L24.0093458,32.90625 L24.0093458,39 L34.271028,39 Z M68,39 L68,12.1321023 C65.6510067,10.7471591 62.8545861,9.97159091 59.6666667,9.97159091 C57.9328859,9.97159091 56.3668904,10.4147727 54.7449664,11.0241477 L55.1923937,6.92471591 L68,6.92471591 L68,0 L45.852349,0 L43.2796421,21.4943182 C43.1677852,22.6576705 44.0626398,23.5440341 45.1252796,23.5440341 L51.6689038,23.5440341 C52.50783,23.5440341 53.0111857,23.2670455 53.3467562,22.546875 C54.6331096,19.7215909 57.8210291,19.5 59.0514541,19.5 C62.5190157,19.5 64.8120805,22.1036932 64.8120805,26.0923295 C64.8120805,30.3025568 62.6308725,32.90625 59.0514541,32.90625 C56.4228188,32.90625 53.2908277,30.9119318 53.2908277,28.3636364 C53.2908277,27.3664773 52.4519016,26.5355114 51.4451902,26.5355114 L44.8456376,26.5355114 C43.8389262,26.5355114 43,27.3664773 43,28.3636364 C43,32.2414773 44.7897092,36.2855114 48.2013423,39 L68,39 Z M24,23 L17,23 L24,10 L24,23 Z"
      id="4"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_45;
