import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_50 = (
  <g
    id="number-50"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M31.4275862,39 C34.9586207,36.1193182 37,31.6321023 37,26.0923295 C37,16.5085227 30.5448276,9.97159091 21.4413793,9.97159091 C19.7310345,9.97159091 18.1862069,10.4147727 16.5862069,11.0241477 L17.0275862,6.92471591 L32.0896552,6.92471591 C33.0827586,6.92471591 34.0758621,6.31534091 34.0758621,5.09659091 L34.0758621,0 L7.8137931,0 L5.27586207,21.4943182 C5.16551724,22.6576705 6.04827586,23.5440341 7.09655172,23.5440341 L13.4965517,23.5440341 C14.3793103,23.5440341 14.8758621,23.2670455 15.2068966,22.546875 C16.5862069,19.7769886 19.6206897,19.5 20.8344828,19.5 C24.2551724,19.5 26.5172414,22.1036932 26.5172414,26.0923295 C26.5172414,30.3025568 24.3655172,32.90625 20.8344828,32.90625 C18.2413793,32.90625 15.1517241,30.9119318 15.1517241,28.3636364 C15.1517241,27.3664773 14.3241379,26.5355114 13.2758621,26.5355114 L6.82068966,26.5355114 C5.82758621,26.5355114 5,27.3664773 5,28.3636364 C5,32.2414773 6.76551724,36.2855114 10.1310345,39 L31.4275862,39 Z M68,39 L68,0 L46.6799205,0 C42.3379722,3.65625 40,10.2485795 40,19.5 C40,28.7514205 42.3379722,35.34375 46.6799205,39 L68,39 Z M57.1157405,32.9993384 L57,33 C53.7822581,33 50,31.4412371 50,19.5278351 C50,7.61443299 53.7822581,6 57,6 C60.2741935,6 64,7.61443299 64,19.5278351 C64,31.3010794 60.3613439,32.9618901 57.1157405,32.9993384 L57.1157405,32.9993384 Z"
      id="5"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_50;
