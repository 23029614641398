import { palette } from '../palette/Palette';

const SearchFragmentJackpot = {
  fragmentJackpotWrapperOuter: {
    borderColor: palette.grey.sixth,
    backgroundColor: palette.grey.fourth
  },

  fragmentJackpotWrapperInner: {
    padding: '3% 5%',

    '& $answer': {
      color: palette.grey.second
    }
  },

  titleHolder: {
    position: 'relative',
    top: -2,
    left: -2,
    width: 'calc(100% + 4px)',
    display: 'block',
    backgroundColor: palette.default.black,

    '& $title': {
      color: palette.default.white,
      textTransform: 'uppercase'
    }
  },

  wrongAnswersItem: {
    margin: '0 0 30px'
  },

  title: {
    boxSizing: 'border-box',
    padding: '5%'
  },

  titleMd: {
    padding: '20px 16px'
  },

  titleSm: {
    padding: 16
  },

  buttonTeaseHolder: {
    '& $button': {
      '& $svg': {
        fill: palette.default.white
      }
    }
  },

  iconPicker: {
    width: 20,
    height: 13,
    paddingTop: 15,
    transform: 'rotate(-90deg)'
  },

  iconPickerMd: {
    marginLeft: 15
  },

  fragmentJackpotWrapperInnerMd: {
    padding: 16
  },

  fragmentJackpotWrapperMd: {
    marginBottom: 40
  },

  fragmentJackpotWrapperSm: {
    padding: 0,
    marginBottom: 20
  },

  name: {
    display: 'none'
  }
};

export default SearchFragmentJackpot;
