import { palette } from '../palette/Palette';

const UniversalVotingPopup = {
  popupInner: {
    background: palette.default.white
  },

  popupInnerMaximumVoted: {
    background: palette.default.white
  },

  buttonWrapper: {
    borderTop: `1px solid ${palette.grey.sixth}`
  },

  button: {
    color: palette.primary.second,
    cursor: 'pointer',
    textTransform: 'uppercase'
  },

  alreadyVotedBtnOk: {
    color: palette.primary.second,
    cursor: 'pointer',
    textTransform: 'uppercase'
  },

  alreadyVotedBtn: {
    backgroundColor: palette.primary.first,
    '&:hover': {
      backgroundColor: palette.primary.second,
      color: palette.default.white
    }
  },

  alreadyVotedTextWrapper: {
    '& p:first-child': {
      textTransform: 'uppercase'
    }
  },

  horizontalLine: {
    borderBottomColor: palette.grey.fifth
  },

  buttonMaxVoted: {
    color: palette.default.white
  },

  titleMaxVoted: {
    textTransform: 'uppercase',
    padding: '0 49px'
  },

  titleMaxVotedSm: {
    padding: '0 12px'
  },

  textMaxVotedSm: {
    paddingLeft: 12,
    paddingRight: 12
  },

  buttonMaxVotedSm: {
    marginBottom: 34,
    width: 196
  },

  horizontalLineSm: {
    marginBottom: 34
  }
};

export default UniversalVotingPopup;
