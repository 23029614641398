import { palette } from '../../palette/Palette';

const TrackTile = {
  tile: {
    borderRadius: 0
  },

  title: {
    color: palette.grey.second,
    fontFamily: 'QanelasSoftBlack',
    fontSize: 16,
    lineHeight: 1.25,
    textTransform: 'none'
  },
  artist: {
    color: palette.grey.second,
    fontFamily: 'QanelasSoftRegular',
    textTransform: 'none',
    fontSize: 13,
    lineHeight: 1.08
  },
  artistStation: {
    color: palette.grey.second,
    fontSize: 12,
    lineHeight: '14px'
  },
  titleStations: {
    color: 'a4a4a3',
    fontFamily: 'QanelasSoftBlack',
    fontSize: 13,
    lineHeight: '18px'
  },
  currentSong: {
    color: palette.default.black
  },
  trackNumberContainer: {
    backgroundColor: palette.primary.second
  }
};

export default TrackTile;
