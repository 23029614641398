import { palette } from '../palette/Palette';

const ChartList = {
  chartListTop: {
    backgroundColor: palette.default.white
  },
  chartListSearch: {
    backgroundColor: palette.default.white
  },

  chartListHeaderContainer: {
    backgroundColor: palette.default.black,
    color: palette.default.white,
    textTransform: 'uppercase'
  },

  chartListTableRow: {
    borderBottomColor: palette.grey.third,
    height: 75,
    borderBottomWidth: '1px',
    oddColor: 'rgba(255, 255, 255, 1)',
    evenColor: 'rgba(208, 208, 208, 0.15)'
  },

  chartListTableRowMd: {
    height: '80px'
  },

  chartListHeaderRow: {
    borderBottom: '1px solid #d0d0d0'
  },

  fixed: {
    chartListHeader: {
      width: '83.71%',
      maxWidth: '1280px'
    },

    chartListHeaderLg: {
      width: '88%'
    },

    chartListSearch: {
      padding: '0 0 23px'
    },

    headerContainer: {
      backgroundColor: palette.default.white,
      color: palette.grey.first
    },

    headerTypography: {
      color: palette.grey.first
    }
  }
};

export default ChartList;
