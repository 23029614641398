import { palette } from '../palette/Palette';

const StationCard = {
  singleStation: {
    padding: '0 10px',
    margin: '0 0 20px',
    width: '20%',
    '&:nth-child(5n+1)': {
      clear: 'both'
    }
  },

  title: {
    textAlign: 'center',
    padding: '6px 12px'
  },

  titleMd: {
    padding: '4px 10px'
  },

  textHolder: {
    backgroundColor: palette.primary.second,
    color: palette.default.white,
    borderRadius: 50,
    textTransform: 'uppercase'
  },

  overlay: {
    textAlign: 'center',
    width: '100%',
    top: '75%',
    transform: 'translateY(-50%)'
  },

  logoHolder: {
    display: 'flex',
    justifyContent: 'center',
    top: '35%',
    zIndex: 0
  },

  imageOverlay: {
    backgroundColor: palette.primary.second
  },

  imageOverlaySm: {
    display: 'none'
  },

  imageOverlayHover: {
    opacity: '80%'
  },

  playIcon: {
    opacity: 1
  },

  playIconHover: {
    opacity: 1
  }
};

export default StationCard;
