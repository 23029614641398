import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconNavigationLogo = (
  <g fill="none" fillRule="evenodd">
    <text
      fontFamily="QanelasSoftBlack"
      fontSize="20"
      fontWeight="600"
      fill={palette.default.black}
    >
      <tspan x="44.459" y="15">
        ACCOUNT
      </tspan>
    </text>
    <path
      d="M38.373 11.224a3.347 3.347 0 0 1-3.356 3.35 3.347 3.347 0 0 1-3.356-3.35 3.347 3.347 0 0 1 3.356-3.349 3.347 3.347 0 0 1 3.356 3.349"
      fill={palette.primary.second}
    />
    <path
      d="M38.18 3.65a3.347 3.347 0 0 1-3.357 3.348 3.347 3.347 0 0 1-3.356-3.349A3.347 3.347 0 0 1 34.823.3a3.347 3.347 0 0 1 3.356 3.35"
      fill={palette.primary.first}
    />
    <path
      d="m29.457 7.53-.187-.172.187-.187a3.442 3.442 0 0 0 1.095-2.559c0-2.458-1.974-4.298-4.595-4.298h-.044c-2.621 0-4.61 1.855-4.61 4.298 0 1.25.592 2.084 1.096 2.559l.187.187-.187.172c-.778.719-1.253 1.768-1.253 2.817 0 1.165.432 2.214 1.195 2.961.85.834 2.089 1.265 3.587 1.265 1.498 0 2.737-.431 3.586-1.265.778-.747 1.196-1.796 1.196-2.96a3.83 3.83 0 0 0-1.253-2.818zm-3.544-4.255h.044c.95 0 1.454.777 1.454 1.337 0 1.179-1.123 1.265-1.469 1.265-.346 0-1.47-.086-1.47-1.265-.014-.56.49-1.337 1.441-1.337zm.015 8.337c-.893 0-1.671-.604-1.628-1.394.029-.776.59-1.394 1.628-1.394 1.037 0 1.599.618 1.627 1.394.044.79-.734 1.394-1.627 1.394zM15.413 3.29c.936 0 1.44.776 1.44 1.322 0 1.179-1.095 1.265-1.44 1.265h-.605a.568.568 0 0 0-.577.575v1.84c0 .316.245.575.577.575h.835c.75 0 1.368.618 1.368 1.365 0 .762-.72 1.366-1.598 1.366-1.124 0-1.498-.992-1.498-1.438a.568.568 0 0 0-.577-.575h-2.002a.568.568 0 0 0-.576.575c0 3.048 2.334 4.413 4.667 4.413 1.484-.014 2.722-.445 3.572-1.265.778-.747 1.196-1.796 1.196-2.946a3.83 3.83 0 0 0-1.253-2.818l-.188-.172.188-.187a3.442 3.442 0 0 0 1.094-2.559c0-2.443-1.973-4.297-4.609-4.297-2.32 0-4.667 1.394-4.667 4.513 0 .33.245.575.576.575h2.031a.577.577 0 0 0 .576-.575c-.014-.489.36-1.552 1.47-1.552zM4.955 7.4c-.446 0-1.268.116-1.7.863-.115.187-.273.316-.518.316H.677a.565.565 0 0 1-.576-.56v-.072l.82-6.928A.544.544 0 0 1 1.485.5h7.432c.317 0 .576.245.576.575V2.93c0 .317-.259.547-.605.547H3.76l-.145 1.308v.043l.375-.13a3.534 3.534 0 0 1 1.167-.215c2.65 0 4.566 1.739 4.84 4.298.028.215.028.445.028.675 0 3.062-2.03 5.131-5.07 5.131C1.743 14.573 0 12.302 0 10.175c0-.33.26-.59.576-.59h2.017a.56.56 0 0 1 .576.575c0 .647.792 1.438 1.786 1.438 1.124 0 1.8-.805 1.8-2.156 0-1.208-.734-2.041-1.8-2.041z"
      fill={palette.default.black}
    />
  </g>
);

export default iconNavigationLogo;
