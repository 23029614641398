import { palette } from '../palette/Palette';

const VotingCard = {
  votingWrapper: {
    border: `16px solid ${palette.default.white}`
  },
  votingBorder: {
    border: `2px solid ${palette.grey.fifth}`
  },
  votingWrapperMl: {
    border: `12px solid ${palette.default.white}`
  },
  votingWrapperSl: {
    border: `10px solid ${palette.default.white}`
  },
  votingWrapperSm: {
    border: `8px solid ${palette.default.white}`
  },
  votingWrapperXs: {
    border: `3px solid ${palette.default.white}`
  },
  wrapperPopup: {
    backgroundColor: palette.default.white,
    color: palette.default.black
  }
};

export default VotingCard;
