import { palette } from '../palette/Palette';

const CountdownDisplay = {
  countdownWrapper: {
    color: palette.default.black
  },

  rectangle: {
    backgroundColor: palette.primary.first,
    color: palette.default.black
  },

  daysDisplayText: {
    textTransform: 'uppercase'
  },

  title: {
    fontSize: 20,
    textTransform: 'uppercase'
  },

  special: {
    '& $rectangle': {
      backgroundColor: palette.default.white
    }
  }
};

export default CountdownDisplay;
