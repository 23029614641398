import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_11 = (
  <g
    id="number-11"
    stroke="none"
    transform="translate(1.5, 0.000000)"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M37,39 L37,0 L27.027248,0 C26.8092643,2.16051136 24.9564033,3.93323864 22.7765668,3.93323864 L18.7983651,3.93323864 C17.8174387,3.93323864 17,4.76420455 17,5.76136364 L17,11.1903409 C17,12.1875 17.8174387,13.0184659 18.7983651,13.0184659 L27.027248,13.0184659 L27.027248,39 L37,39 Z M68,39 L68,0 L58.0546448,0 C57.8360656,2.16051136 55.9781421,3.93323864 53.7923497,3.93323864 L49.8032787,3.93323864 C48.8196721,3.93323864 48,4.76420455 48,5.76136364 L48,11.1903409 C48,12.1875 48.8196721,13.0184659 49.8032787,13.0184659 L58.0546448,13.0184659 L58.0546448,39 L68,39 Z"
      id="1"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_11;
