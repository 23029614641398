import { palette } from '../palette/Palette';

const UniversalVotingModal = {
  modalHeader: {
    backgroundColor: palette.default.black,
    textTransform: 'uppercase'
  },

  closeIcon: {
    fill: palette.primary.first
  }
};
export default UniversalVotingModal;
