import { palette } from '../../palette/Palette';

const TileMessageButton = {
  button: {
    backgroundColor: palette.primary.second,
    fontFamily: 'QanelasSoftRegular',
    fontStyle: 'none'
  }
};

export default TileMessageButton;
