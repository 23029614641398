import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_27 = (
  <g
    id="number-27"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M36.8899083,39 L36.8899083,34.0142045 C36.8899083,33.0170455 36.0642202,32.1860795 35.0733945,32.1860795 L21.8623853,32.1860795 L30.7247706,23.8764205 C32.2110092,22.4914773 37,18.2258523 37,11.0795455 C37,6.42613636 35.1834862,2.54829545 32.1559633,0 L12.0642202,0 C8.87155963,2.60369318 7,6.53693182 7,11.0795455 C7,12.1321023 7.82568807,13.0184659 8.81651376,13.0184659 L15.2568807,13.0184659 C16.2477064,13.0184659 17.0733945,12.1321023 17.0733945,11.0795455 C17.0733945,9.02982955 18.7798165,6.03835227 21.9174312,6.03835227 C25.9908257,6.03835227 26.8715596,9.69460227 26.8715596,11.0795455 C26.8715596,12.8522727 25.7155963,14.6803977 23.5137615,16.8963068 L7.71559633,32.6292614 C7.22018349,33.0724432 7,33.7372159 7,34.5127841 L7,39 L36.8899083,39 Z M57.4939516,39 L68,10.96875 L68,0 L41,0 L41,5.04119318 C41,6.03835227 41.8165323,6.92471591 42.8508065,6.92471591 L59.1814516,6.92471591 L46.8790323,39 L57.4939516,39 Z"
      id="2"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_27;
