import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconPauseCircle = (
  <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
    <g>
      <rect width="64" height="64" rx="32" />
      <rect fill={palette.primary.first} width="64" height="64" rx="32" />
      <rect opacity=".302" width="64" height="64" rx="32" />
    </g>
    <g transform="translate(22.93 18.875)">
      <rect width="7.5" height="26.25" rx="2" />
      <rect fill={palette.default.black} width="7.5" height="26.25" rx="2" />
      <rect opacity=".302" width="7.5" height="26.25" rx="2" />
    </g>
    <g transform="translate(33.712 18.875)">
      <rect width="7.5" height="26.25" rx="2" />
      <rect fill={palette.default.black} width="7.5" height="26.25" rx="2" />
      <rect opacity=".302" width="7.5" height="26.25" rx="2" />
    </g>
  </g>
);

export default iconPauseCircle;
