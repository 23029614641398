import { palette } from '../palette/Palette';

const ChartListTop50Legend = {
  legend: {
    backgroundColor: palette.grey.fifth
  },

  icons: {
    '& svg': {
      padding: '0 0 18px 10px',
      '& g': {
        fill: palette.default.black
      }
    },
    '& svg:nth-of-type(1)': {
      paddingLeft: 0
    },
    '& svg:nth-of-type(2)': {
      '& g': {
        fill: palette.primary.first
      }
    }
  }
};

export default ChartListTop50Legend;
