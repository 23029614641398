export const palette = {
  primary: {
    first: '#00e34f',
    second: '#a400f9'
  },
  secondary: {
    first: '#03d14b',
    second: '#8500ca'
  },
  grey: {
    first: '#3C3C3B',
    second: '#9D9D9C',
    third: '#D0D0D0',
    fourth: '#F8F8F8',
    fifth: '#E8E8E8',
    sixth: '#EEEEEE',
    seventh: '#706f6f',
    eight: '#515151'
  },
  misc: {
    first: '#ff7300',
    second: '#fff41b'
  },
  default: {
    white: '#FFFFFF',
    black: '#000000'
  }
};
