import { palette } from '../palette/Palette';

const Badge = {
  root: {
    backgroundColor: palette.primary.first
  },

  exclusiveMd: {
    padding: '6px 15px'
  },

  newEpisode: {
    padding: '6px 8px',
    backgroundColor: palette.default.black
  },

  programGuide: {
    top: 20,
    right: 20,
    width: 96,
    backgroundColor: palette.default.black
  },

  programGuideSm: {
    width: 45
  }
};

export default Badge;
