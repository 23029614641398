import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_5 = (
  <g
    id="number-05"
    stroke="none"
    transform="translate(1.5, 0.000000)"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M5.4,19.5 C5.4,10.2485795 7.72670455,3.65625 12.0477273,0 L33.3758523,0 C37.8076705,3.65625 40.0235795,10.2485795 40.0235795,19.5 C40.0235795,28.7514205 37.8076705,35.34375 33.3758523,39 L12.0477273,39 C7.72670455,35.34375 5.4,28.7514205 5.4,19.5 Z M68.0548295,6.92471591 L54.7039773,6.92471591 L54.2607955,11.0241477 C55.8673295,10.4147727 57.4184659,9.97159091 59.1357955,9.97159091 C62.5704545,9.97159091 65.6173295,10.8579545 68.0548295,12.4644886 L68.0548295,39 L47.7792614,39 C44.4,36.2855114 42.6272727,32.2414773 42.6272727,28.3636364 C42.6272727,27.3664773 43.4582386,26.5355114 44.4553977,26.5355114 L50.9369318,26.5355114 C51.9894886,26.5355114 52.8204545,27.3664773 52.8204545,28.3636364 C52.8204545,30.9119318 55.9227273,32.90625 58.5264205,32.90625 C62.071875,32.90625 64.2323864,30.3025568 64.2323864,26.0923295 C64.2323864,22.1036932 61.9610795,19.5 58.5264205,19.5 C57.3076705,19.5 54.15,19.7215909 52.8758523,22.546875 C52.5434659,23.2670455 52.0448864,23.5440341 51.2139205,23.5440341 L44.7323864,23.5440341 C43.6798295,23.5440341 42.7934659,22.6576705 42.9042614,21.4943182 L45.4525568,0 L68.0548295,0 L68.0548295,6.92471591 Z M22.7394886,6.03835227 C19.5818182,6.03835227 15.8701705,7.64488636 15.8701705,19.5 C15.8701705,31.3551136 19.5818182,32.90625 22.7394886,32.90625 C25.9525568,32.90625 29.6088068,31.3551136 29.6088068,19.5 C29.6088068,7.64488636 25.9525568,6.03835227 22.7394886,6.03835227 Z"
      id="5"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_5;
