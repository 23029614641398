import { palette } from '../../palette/Palette';

const AudioVideoToggle = {
  toggleButton: {
    fontFamily: 'QanelasSoftBlack',
    fontSize: 16,
    lineHeight: 1.2,
    textTransform: 'uppercase',
    color: palette.primary.second,
    hoverColor: palette.secondary.second
  }
};

export default AudioVideoToggle;
