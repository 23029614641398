import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_7 = (
  <g
    id="feedback_def"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g
      id="number-07"
      transform="translate(1.5, 0.000000)"
      fill={palette.default.white}
      fillRule="nonzero"
    >
      <path
        d="M6,19.5 C6,10.2485795 8.32670455,3.65625 12.6477273,0 L33.9758523,0 C38.4076705,3.65625 40.6235795,10.2485795 40.6235795,19.5 C40.6235795,28.7514205 38.4076705,35.34375 33.9758523,39 L12.6477273,39 C8.32670455,35.34375 6,28.7514205 6,19.5 Z M68.15625,16.1761364 L59.4034091,39 L48.6008523,39 L61.1761364,6.92471591 L44.5014205,6.92471591 C43.5042614,6.92471591 42.6732955,6.03835227 42.6732955,5.04119318 L42.6732955,0 L68.15625,0 L68.15625,16.1761364 Z M23.3394886,6.03835227 C20.1818182,6.03835227 16.4701705,7.64488636 16.4701705,19.5 C16.4701705,31.3551136 20.1818182,32.90625 23.3394886,32.90625 C26.5525568,32.90625 30.2088068,31.3551136 30.2088068,19.5 C30.2088068,7.64488636 26.5525568,6.03835227 23.3394886,6.03835227 Z"
        id="7"
      />
    </g>
  </g>
);

export default chartListTop50_7;
