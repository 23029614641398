import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_12 = (
  <g
    id="number-12"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g
      id="13"
      transform="translate(16.555555, -0.800000)"
      fill={palette.default.white}
      fillRule="nonzero"
    >
      <path
        d="M20,39.8 L20,0.8 L10.027248,0.8 C9.80926431,2.96051136 7.95640327,4.73323864 5.77656676,4.73323864 L1.79836512,4.73323864 C0.817438692,4.73323864 0,5.56420455 0,6.56136364 L0,11.9903409 C0,12.9875 0.817438692,13.8184659 1.79836512,13.8184659 L10.027248,13.8184659 L10.027248,39.8 L20,39.8 Z M53,39.8 L53,32.9860795 L40.9385246,32.9860795 L49.8463115,24.6764205 C50.5102459,24.0670455 51.7827869,22.8482955 53,21.0755682 L53,0.8 L31.0901639,0.8 C27.9364754,3.40369318 26,7.33693182 26,11.8795455 C26,12.9321023 26.829918,13.8184659 27.8811475,13.8184659 L34.2991803,13.8184659 C35.295082,13.8184659 36.1803279,12.9321023 36.1803279,11.8795455 C36.1803279,9.82982955 37.8954918,6.83835227 40.9938525,6.83835227 C45.0881148,6.83835227 46.0286885,10.4946023 46.0286885,11.8795455 C46.0286885,13.6522727 44.8114754,15.4803977 42.5983607,17.6963068 L26.7192623,33.4292614 C26.2213115,33.8724432 26,34.5372159 26,35.3127841 L26,39.8 L53,39.8 Z"
        id="1"
      />
    </g>
  </g>
);

export default chartListTop50_12;
