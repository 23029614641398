import { palette } from '../palette/Palette';

const SocialShare = {
  socialShare: {
    marginTop: 32
  },

  socialShareMd: {
    marginTop: 24
  },

  socialShareSm: {
    marginTop: 20
  },

  socialShareInnerBackground: {
    backgroundColor: palette.primary.first,

    '& $shareIcon': {
      fill: palette.default.black,
      width: 18,
      height: 18,
      '& g': {
        fill: palette.default.black
      }
    },

    '&:hover': {
      backgroundColor: palette.secondary.first
    }
  }
};

export default SocialShare;
