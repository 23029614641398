import { palette } from '../palette/Palette';

const ArticleCard = {
  imageHolder: {
    paddingBottom: '46.7742%',
    boxSizing: 'border-box',
    '-webkit-mask-image': '-webkit-radial-gradient(white, white)' // fix for safari browser for border radius not showing
  },

  imageHolderMd: {
    paddingBottom: '46.7742%'
  },

  imageHolderSm: {
    paddingBottom: '46.7742%'
  },

  imageSize: {
    width: 620,
    height: 300
  },

  imageSizeMd: {
    width: 460,
    height: 300
  },

  imageSizeSm: {
    width: 727,
    height: 341
  },

  imageHover: {
    transform: 'translate(-50%, -50%) scale(1.08)'
  },

  icon: {
    width: 32,
    height: 32
  },

  quoteIcon: {
    width: 32,
    height: 32
  },

  quoteBackground: {
    border: `2px solid ${palette.grey.fifth}`
  },

  quoteTitle: {
    paddingTop: 10,
    color: palette.default.black,
    textTransform: 'uppercase'
  },

  quoteAuthor: {
    color: palette.primary.second
  },

  quoteBorder: {
    width: 110,
    height: 2,
    margin: '10px 0 20px',
    backgroundColor: palette.primary.first
  },

  textBackground: {
    backgroundColor: palette.default.white,
    border: `2px solid ${palette.grey.fifth}`
  },

  textCard: {
    color: palette.primary.second,
    '&:hover': {
      '& $title': {
        color: palette.secondary.second
      }
    }
  },

  textCardTitle: {
    textTransform: 'uppercase',
    marginTop: 8
  },

  textCardTitleSm: {
    marginTop: 4
  },

  imageCardTitle: {
    textTransform: 'uppercase',
    marginTop: 8
  },

  imageCardTitleSm: {
    marginTop: 4,
    marginBottom: 0
  },

  imageCardTitleBig: {
    marginTop: 8
  },

  textHolder: {
    centerTop: {
      top: 0,
      bottom: 'auto',
      textAlign: 'center',
      padding: '40px 50px'
    },

    centerBottom: {
      textAlign: 'center',
      padding: '16px 50px'
    },

    leftTop: {
      top: 0,
      bottom: 'auto'
    }
  },

  badgeHolder: {
    right: 0,
    rightTop: {
      top: 0
    },
    rightBottom: {
      bottom: 0
    }
  },

  imageTextCardHolder: {
    imageTextCard: {
      '&:after': {
        content: '""',
        width: 'calc(100% - 4px)',
        height: 'calc(100% - 4px)',
        border: `2px solid ${palette.grey.fifth}`,
        top: 0,
        left: 0,
        zIndex: -1,
        borderRadius: 6,
        position: 'absolute'
      }
    },

    imageHolder: {
      paddingBottom: '100%',
      boxSizing: 'border-box',
      '-webkit-mask-image': '-webkit-radial-gradient(white, white)' // fix for safari browser for border radius not showing
    },

    left: {
      width: '47%',
      height: '100%'
    },

    right: {
      width: '53%',
      paddingLeft: 20
    }
  }
};

export default ArticleCard;
