import { palette } from '../../palette/Palette';

const CarouselNavigationButton = {
  button: {
    backgroundColor: palette.primary.first,
    color: palette.default.white
  },
  hoverColor: {
    backgroundColor: palette.primary.second
  }
};

export default CarouselNavigationButton;
