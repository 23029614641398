import { palette } from '../palette/Palette';

const CompositeList = {
  outerContainer: {
    '& $sectionTitleSmall': {
      color: palette.default.black
    }
  },
  outerContainerMd: {
    width: '120%'
  },
  outerContainerSm: {
    width: '120%'
  },
  compTitle: {
    marginBottom: 30
  },
  sectionTitleSmall: {
    display: 'inline-block',
    padding: '5px 7px 3px 0px',
    color: palette.default.white,
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'top'
  },
  sectionTitleSmallMd: { maxWidth: '80%' },
  containerOuter: {
    paddingRight: 40
  },
  containerOuterMd: {
    paddingRight: 30
  },
  containerOuterSm: {
    paddingRight: '20px'
  },
  containerInner: {
    boxSizing: 'border-box',
    width: 480,
    height: 459,
    padding: 40,
    backgroundColor: palette.grey.sixth,
    '& $number': {
      color: palette.default.black
    },
    '& $title': {
      color: palette.default.black
    }
  },
  containerInnerSl: {
    maxWidth: 465,
    height: 375
  },
  containerInnerMd: {
    width: 400,
    height: 407
  },
  containerInnerSm: {
    width: 320,
    height: 354
  },
  title: {
    textTransform: 'uppercase',
    paddingBottom: 24
  },
  orderNumber: {
    color: palette.default.black,
    paddingBottom: 32
  },
  innerText: {
    paddingBottom: 40
  },
  innerLink: {
    color: palette.primary.second
  },
  compTitleMd: {
    marginBottom: 20
  },
  sliderSm: {
    '& div[class=slick-track]': {
      marginLeft: 0,
      width: '50px !important'
    }
  }
};

export default CompositeList;
