import { palette } from '../../palette/Palette';

const Tab = {
  link: {
    color: palette.default.black,
    fontFamily: 'QanelasSoftBlack',
    fontSize: 18,
    lineHeight: 1.33,
    padding: 15,
    textTransform: 'uppercase'
  },
  linkHover: {
    color: palette.primary.second,
    borderBottomColor: palette.primary.second
  },
  linkSelected: {
    color: palette.primary.second,
    borderBottomColor: palette.primary.second
  }
};

export default Tab;
