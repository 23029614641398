import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconLogo = (
  <g fill="none" fillRule="evenodd">
    <g>
      <g>
        <path
          fill={palette.primary.second}
          d="M23.063 36.48c0 6.192-5.003 11.184-11.208 11.184S.647 42.672.647 36.48c0-6.192 5.003-11.184 11.208-11.184s11.208 4.992 11.208 11.184"
          transform="translate(-1447 -480) translate(551 468) translate(896 12) translate(105.081)"
        />
        <path
          fill={palette.primary.first}
          d="M22.416 11.184c0 6.192-5.003 11.184-11.208 11.184S0 17.376 0 11.184C0 4.992 5.003 0 11.208 0s11.208 4.992 11.208 11.184"
          transform="translate(-1447 -480) translate(551 468) translate(896 12) translate(105.081)"
        />
      </g>
      <path
        fill={palette.default.white}
        d="M98.369 24.144l-.626-.576.626-.624c1.683-1.584 3.655-4.368 3.655-8.544 0-8.208-6.59-14.352-15.344-14.352h-.144C77.78.048 71.143 6.24 71.143 14.4c0 4.176 1.972 6.96 3.656 8.544l.625.624-.625.576c-2.598 2.4-4.185 5.904-4.185 9.408 0 3.888 1.443 7.392 3.992 9.888 2.838 2.784 6.975 4.224 11.978 4.224 5.002 0 9.139-1.44 11.977-4.224 2.598-2.496 3.993-6 3.993-9.888 0-3.504-1.54-7.056-4.185-9.408zM86.536 9.936h.144c3.175 0 4.858 2.592 4.858 4.464 0 3.936-3.752 4.224-4.906 4.224-1.155 0-4.907-.288-4.907-4.224-.048-1.872 1.636-4.464 4.81-4.464zm.048 27.84c-2.983 0-5.58-2.016-5.436-4.656.096-2.592 1.972-4.656 5.436-4.656 3.463 0 5.339 2.064 5.435 4.656.144 2.64-2.453 4.656-5.435 4.656zM51.469 9.984c3.127 0 4.81 2.592 4.81 4.416 0 3.936-3.655 4.224-4.81 4.224h-2.02c-1.058 0-1.924.816-1.924 1.92v6.144c0 1.056.818 1.92 1.924 1.92h2.79c2.501 0 4.57 2.064 4.57 4.56 0 2.544-2.406 4.56-5.34 4.56-3.752 0-5.002-3.312-5.002-4.8 0-1.056-.818-1.92-1.925-1.92h-6.686c-1.058 0-1.924.816-1.924 1.92 0 10.176 7.793 14.736 15.585 14.736 4.955-.048 9.092-1.488 11.93-4.224 2.597-2.496 3.992-6 3.992-9.84 0-3.504-1.54-7.056-4.185-9.408l-.625-.576.625-.624c1.684-1.584 3.656-4.368 3.656-8.544 0-8.16-6.59-14.352-15.393-14.352-7.744 0-15.585 4.656-15.585 15.072 0 1.104.818 1.92 1.924 1.92h6.783c1.058 0 1.924-.864 1.924-1.92-.048-1.632 1.202-5.184 4.906-5.184zM16.547 23.712c-1.491 0-4.233.384-5.676 2.88-.385.624-.914 1.056-1.732 1.056H2.261c-1.058 0-1.924-.816-1.924-1.872v-.24L3.079 2.4C3.127 1.392 3.944.672 4.955.672h24.82c1.058 0 1.924.816 1.924 1.92v6.192c0 1.056-.866 1.824-2.02 1.824H12.555l-.481 4.368v.144l1.25-.432c1.347-.48 2.694-.72 3.897-.72 8.85 0 15.248 5.808 16.162 14.352.096.72.096 1.488.096 2.256 0 10.224-6.782 17.136-16.932 17.136C5.82 47.664 0 40.08 0 32.976c0-1.104.866-1.968 1.924-1.968h6.734c1.107 0 1.924.816 1.924 1.92 0 2.16 2.646 4.8 5.965 4.8 3.752 0 6.013-2.688 6.013-7.2 0-4.032-2.453-6.816-6.013-6.816z"
        transform="translate(-1447 -480) translate(551 468) translate(896 12)"
      />
    </g>
  </g>
);

export default iconLogo;
