import { palette } from '../palette/Palette';

const MenuItem = {
  itemArrowIcon: {
    width: 24,
    height: 24
  },
  menuItem: {
    '& $textHolder': {
      color: palette.default.white
    },
    '& $itemArrowIcon': {
      fill: palette.default.white
    },
    textTransform: 'uppercase',
    '&$firstMenuItem': {
      '&:hover': {
        transitionDelay: '0.2s',
        '& $textHolder': {
          transition: 'all 0.3s ease 0.2s'
        }
      }
    },

    '&:hover': {
      '& $textHolder': {
        color: palette.default.black
      },
      '& $itemArrowIcon': {
        fill: palette.default.black
      },
      backgroundColor: palette.primary.first
    }
  },
  subMenuItem: {
    '& $textHolder': {
      color: palette.default.black
    },
    '&:hover': {
      backgroundColor: palette.default.black,
      '& $textHolder': {
        color: palette.default.white
      }
    }
  },
  activeElement: {
    backgroundColor: palette.primary.first,
    '& $textHolder': {
      color: palette.default.black,
      transitionDelay: '0.2s'
    },
    '& $itemArrowIcon': {
      fill: palette.default.black,
      transitionDelay: '0.2s'
    },
    '&:hover': {
      transition: 'all 0.3s ease 0.2s'
    }
  }
};

export default MenuItem;
