import React from 'react';

const iconClose = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M56.0906307,0.47577515 C55.462264,-0.158591717 54.4355,-0.158591717 53.8006553,0.47577515 L32.8637377,21.5074767 C32.232132,22.1418435 31.205368,22.1418435 30.5737623,21.5074767 L9.6368447,0.47577515 C9.00200004,-0.158591717 7.97523598,-0.158591717 7.34686934,0.47577515 L0.473704239,7.37898793 C-0.157901413,8.0133548 -0.157901413,9.0446076 0.473704239,9.67897447 L21.4106219,30.7074228 C22.0454665,31.3450429 22.0454665,32.3730425 21.4106219,33.0074094 L0.752258527,53.7593388 C0.120652875,54.3969589 0.120652875,55.4249585 0.752258527,56.0593254 L7.62218462,62.959285 C8.25379027,63.596905 9.28055433,63.596905 9.91215998,62.959285 L30.5737623,42.2106087 C31.205368,41.5762418 32.232132,41.5762418 32.8637377,42.2106087 L53.52534,62.959285 C54.1569457,63.596905 55.1837097,63.596905 55.8153154,62.959285 L62.6852415,56.0593254 C63.3168471,55.4249585 63.3168471,54.3969589 62.6852415,53.7593388 L42.0268781,33.0074094 C41.3920335,32.3730425 41.3920335,31.3450429 42.0268781,30.7074228 L62.9637958,9.67897447 C63.5954014,9.0446076 63.5954014,8.0133548 62.9637958,7.37898793 L56.0906307,0.47577515 Z"
    id="icon-close"
  />
);

export default iconClose;
