import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_18 = (
  <g
    id="number-18"
    stroke="none"
    strokeWidth="1"
    transform="translate(1.5, 0.000000)"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M35,39 L35,0 L25.027248,0 C24.8092643,2.16051136 22.9564033,3.93323864 20.7765668,3.93323864 L16.7983651,3.93323864 C15.8174387,3.93323864 15,4.76420455 15,5.76136364 L15,11.1903409 C15,12.1875 15.8174387,13.0184659 16.7983651,13.0184659 L25.027248,13.0184659 L25.027248,39 L35,39 Z M68,39 L68,0 L46.3340122,0 C43.4195519,2.4375 41.604888,6.09375 41.604888,10.3039773 C41.604888,14.015625 43.1446029,16.7301136 45.6741344,19.1676136 C42.814664,21.6605114 41,24.8181818 41,28.8622159 C41,33.1832386 42.7046843,36.6732955 45.509165,39 L68,39 Z M56.4736842,14 C54.3157895,14 52,12.9403974 52,10.0264901 C52,7.11258278 54.3157895,6 56.4736842,6 C58.6315789,6 61,7.11258278 61,10.0264901 C61,12.9403974 58.6315789,14 56.4736842,14 Z M56.5,33 C53.4315789,33 51,31.1226994 51,28.5276074 C51,25.9325153 53.4315789,24 56.5,24 C59.5684211,24 62,25.9325153 62,28.5276074 C62,31.1226994 59.5684211,33 56.5,33 Z"
      id="1"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_18;
