import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50_40 = (
  <g
    id="number-40"
    transform="translate(1.5, 0.000000)"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <path
      d="M32.271028,39 L32.271028,32.90625 L37.1495327,32.90625 C38.1588785,32.90625 39,32.0752841 39,31.078125 L39,25.2059659 C39,24.1534091 38.1588785,23.3224432 37.1495327,23.3224432 L32.271028,23.3224432 L32.271028,0 L16.4579439,0 L4.06542056,23.1008523 C3.09995937,24.9024827 3.00667809,25.1328702 3.00039174,26.1676107 L3,31.078125 C3,32.0752841 3.78504673,32.90625 4.79439252,32.90625 L22.0093458,32.90625 L22.0093458,39 L32.271028,39 Z M68,39 L68,0 L46.6534653,0 C42.3287129,3.65625 40,10.2485795 40,19.5 C40,28.7514205 42.3287129,35.34375 46.6534653,39 L68,39 Z M57.1157405,32.9993384 L57,33 C53.7822581,33 50,31.4412371 50,19.5278351 C50,7.61443299 53.7822581,6 57,6 C60.2741935,6 64,7.61443299 64,19.5278351 C64,31.3010794 60.3613439,32.9618901 57.1157405,32.9993384 L57.1157405,32.9993384 Z M22,23 L15,23 L22,10 L22,23 Z"
      id="4"
      fill={palette.default.white}
      fillRule="nonzero"
    />
  </g>
);

export default chartListTop50_40;
