import { palette } from '../palette/Palette';

const ChartListTop50Track = {
  lastWeek: {
    '& g': {
      fill: palette.default.black
    },
    borderColor: palette.default.black
  },

  reenteringTrackWrapper: {
    borderColor: palette.default.black
  },

  reenterIcon: {
    fill: palette.primary.second
  },

  newTrack: {
    borderColor: palette.default.black
  },

  lastWeekBorder: {
    borderColor: palette.default.black
  },

  fastForward: {
    borderColor: palette.default.black
  },

  nmbOfWeeks: {
    borderColor: palette.default.black
  },

  progress: {
    backgroundColor: palette.primary.first
  },

  progressGrey: {
    backgroundColor: palette.grey.third
  },

  position: {
    backgroundColor: palette.default.black,

    '& g': {
      fill: palette.default.white
    }
  },

  iconControl: {
    '& svg': {
      fill: palette.default.black
    }
  },

  iconControlPlaying: {
    '& svg': {
      fill: palette.primary.first
    }
  },
  trackInfo: {
    textTransform: 'uppercase',

    '& div:nth-of-type(1)': {
      marginBottom: 5
    }
  },

  trackInfoSm: {
    '& div:nth-of-type(1)': {
      marginBottom: 2
    }
  },

  positionNew: {
    backgroundColor: palette.primary.second
  },

  positionBiggestJump: {
    backgroundColor: palette.primary.first
  },

  positionFirst: {
    backgroundColor: palette.default.white,
    '& use': {
      fill: palette.primary.second
    }
  },

  playDark: {
    '& rect': {
      fill: palette.default.black
    },
    '& path': {
      fill: palette.default.white
    }
  }
};

export default ChartListTop50Track;
