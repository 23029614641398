import { palette } from '../palette/Palette';

const Category = {
  tag: {
    maxWidth: '100%',
    boxSizing: 'border-box',
    color: palette.default.white,
    padding: '6px 12px',
    backgroundColor: palette.primary.second,
    '&:hover': {
      backgroundColor: palette.secondary.second
    }
  },

  textInner: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block'
  },

  categoryMobile: {
    color: palette.primary.second,
    textTransform: 'uppercase',
    paddingBottom: 5
  },

  tagSm: {
    padding: '4px 8px'
  }
};

export default Category;
