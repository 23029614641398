import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconSpeaker = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M30.857 13c.631 0 1.143.424 1.143.946v35.949c0 .522-.512.946-1.143.946h-7.474c-.334 0-.651-.122-.866-.332L9.483 37.927a1.245 1.245 0 0 0-.866-.33H1.143C.512 37.596 0 37.172 0 36.65v-9.46c0-.522.512-.946 1.143-.946h7.474c.334 0 .652-.12.866-.33L22.517 13.33c.215-.21.532-.331.866-.331zm22.926.89c.457-.378 1.214-.38 1.652.012C60.747 18.632 64 24.968 64 31.92c0 6.953-3.253 13.287-8.565 18.02-.438.389-1.195.389-1.652.01l-1.614-1.335c-.436-.362-.434-.933-.016-1.31 4.514-4.047 7.276-9.454 7.276-15.385 0-5.93-2.762-11.338-7.276-15.385-.418-.376-.42-.947.016-1.309zm-7.438 6.155c.46-.379 1.225-.38 1.657.019 3.408 3.15 5.484 7.305 5.484 11.857 0 4.55-2.076 8.707-5.484 11.855-.432.4-1.197.4-1.657.02l-1.616-1.34c-.434-.358-.43-.922-.025-1.302 2.62-2.467 4.21-5.699 4.21-9.233 0-3.536-1.59-6.768-4.21-9.235-.404-.38-.409-.944.025-1.302zm-7.126 5.9c.491-.407 1.296-.354 1.73.098 1.552 1.614 2.48 3.658 2.48 5.877 0 2.22-.928 4.263-2.48 5.878-.434.45-1.239.506-1.73.097l-1.625-1.345c-.387-.32-.469-.837-.137-1.196.877-.954 1.4-2.146 1.4-3.434 0-1.289-.523-2.479-1.4-3.432-.332-.362-.25-.878.137-1.198z"
    fill={palette.primary.second}
  />
);

export default iconSpeaker;
