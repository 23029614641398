import { palette } from '../../palette/Palette';

const Playlist = {
  wrapper: {
    backgroundColor: palette.grey.fourth
  },

  mainPlayer: {
    backgroundColor: palette.grey.fourth
  },

  title: {
    color: palette.grey.second,
    fontFamily: 'QanelasSoftBlack',
    fontSize: 24,
    lineHeight: 1,
    textTransform: 'titlecase',
    '& > span': {
      borderRadius: 3
    }
  },

  link: {
    color: palette.primary.second,
    fontFamily: 'QanelasSoftBlack',
    fontSize: 18,
    textTransform: 'uppercase'
  },

  linkHover: {
    color: palette.secondary.second
  },

  bar: {
    background: palette.grey.sixth,
    marginLeft: -30,
    marginRight: -30,
    paddingLeft: 30,
    paddingRight: 30
  }
};

export default Playlist;
