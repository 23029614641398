import { palette } from '../palette/Palette';

const Menu = {
  overlay: {
    backgroundColor: palette.default.black
  },
  leftMenuBefore: {
    backgroundColor: palette.default.black
  },
  svgClose: {
    width: 32,
    height: 32
  },
  iconClose: {
    '& $svgClose': {
      fill: palette.default.white
    }
  },
  // the height of scrollable area is full height of viewport substracted by height of navigation
  // and height of socialIcons wrapper
  // for devices which can be rotated the height is calculate inside Menu component on tpp
  area: {
    height: 'calc(100vh - 72px - 120px)'
  },
  socialIconsWrapper: {
    backgroundColor: palette.default.black
  },
  mobileIconClose: {
    '& $subMenuSvgClose': {
      fill: palette.default.black
    }
  },
  subMenuSvgClose: {
    width: 24,
    height: 24
  },
  subMenuSvgBack: {
    width: 24,
    height: 24
  },
  svgBack: {
    transform: 'rotate(180deg)',
    fill: palette.default.black
  },
  iconInner: {
    width: 18,
    height: 18,

    '& g': {
      fill: palette.default.black
    }
  },
  transparancyGradientAtTop: {
    backgroundImage: `linear-gradient(to bottom, ${palette.default.black}, rgba(0, 0, 0, 0))`
  },
  transparancyGradientAtBottom: {
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), ${palette.default.black})`
  }
};

export default Menu;
