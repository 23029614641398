import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconCheckmark = (
  <path
    d="m55.446 8 8.45 8.45-39.527 39.527L0 31.61l8.45-8.45 15.919 15.918z"
    fill={palette.primary.first}
  />
);

export default iconCheckmark;
