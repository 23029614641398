import React from 'react';

import { palette } from '../theme/palette/Palette';

const iconPlay = (
  <g fill={palette.primary.first}>
    <rect width="64" height="64" rx="32" />
    <rect fill={palette.primary.first} width="64" height="64" rx="32" />
    <rect opacity=".302" width="64" height="64" rx="32" />
    <g fill={palette.default.black}>
      <path d="M46.039 33.383 24.65 46.75c-1.086.678-2.495-.102-2.495-1.383v-26.73c0-1.285 1.41-2.065 2.495-1.387L46.04 30.617a1.632 1.632 0 0 1 0 2.766" />
    </g>
  </g>
);

export default iconPlay;
