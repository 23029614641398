import React from 'react';

const iconMenu = (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M63 44.462a1 1 0 0 1 1 1v7.846a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-7.846a1 1 0 0 1 1-1zm0-17.231a1 1 0 0 1 1 1v7.846a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V28.23a1 1 0 0 1 1-1zM63 10a1 1 0 0 1 1 1v7.846a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1z"
  />
);

export default iconMenu;
