const Lock = {
  iconMemberSvg: {
    width: 63,
    height: 14,
    viewBox: '0 0 195 31'
  },
  iconMemberTopSvg: {
    width: 187,
    height: 64,
    viewBox: '0 0 187 64'
  },
  iconMemberBottomSvg: {
    width: 187,
    height: 64,
    viewBox: '0 0 187 64'
  }
};

export default Lock;
