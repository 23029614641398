import React from 'react';

import { palette } from '../theme/palette/Palette';

const chartListTop50Wk = (
  <g
    id="Productie"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g
      id="538Top50_desktop"
      transform="translate(-1330.000000, -3045.000000)"
      fill={palette.default.black}
      fillRule="nonzero"
    >
      <g id="Group-5-Copy-3" transform="translate(548.000000, 3001.000000)">
        <g id="Group-36" transform="translate(684.000000, 21.000000)">
          <g id="Group-17-Copy-284" transform="translate(98.000000, 5.000000)">
            <path
              d="M6.544,30 C6.96,30 7.136,29.824 7.232,29.488 L8.192,25.776 L8.64,23.76 L8.704,23.76 L9.152,25.776 L10.112,29.488 C10.208,29.824 10.384,30 10.8,30 L13.248,30 C13.664,30 13.84,29.824 13.936,29.488 L16.624,19.36 C16.736,18.928 16.576,18.672 16.192,18.672 L13.792,18.672 C13.392,18.672 13.168,18.848 13.088,19.2 L12.304,23.024 L11.808,25.632 L11.744,25.632 L11.232,23.024 L10.368,19.184 C10.288,18.832 10.08,18.672 9.664,18.672 L7.68,18.672 C7.264,18.672 7.056,18.832 6.976,19.184 L6.112,23.024 L5.6,25.632 L5.536,25.632 L5.04,23.024 L4.256,19.2 C4.176,18.848 3.952,18.672 3.552,18.672 L1.152,18.672 C0.768,18.672 0.608,18.928 0.72,19.36 L3.408,29.488 C3.504,29.824 3.68,30 4.096,30 L6.544,30 Z M20.672,30 C21.072,30 21.248,29.824 21.248,29.424 L21.248,25.568 L23.68,29.472 C23.888,29.808 24.096,30 24.496,30 L26.992,30 C27.376,30 27.536,29.648 27.296,29.28 L23.936,23.968 L27.216,19.392 C27.472,19.04 27.328,18.672 26.944,18.672 L24.448,18.672 C24.048,18.672 23.824,18.88 23.6,19.2 L21.248,22.56 L21.248,19.248 C21.248,18.848 21.072,18.672 20.672,18.672 L18.336,18.672 C17.936,18.672 17.76,18.848 17.76,19.248 L17.76,29.424 C17.76,29.824 17.936,30 18.336,30 L20.672,30 Z"
              id="WK"
            />
          </g>
        </g>
      </g>
    </g>
  </g>
);

export default chartListTop50Wk;
