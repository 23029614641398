import { palette } from '../palette/Palette';

const CalendarEntry = {
  showTime: { color: palette.primary.first, paddingBottom: 3 },

  artistName: {
    color: palette.default.white,
    paddingBottom: 3
  },

  showName: {
    color: palette.default.white,
    paddingBottom: 3
  }
};

export default CalendarEntry;
