import { palette } from '../../palette/Palette';

const OverlayPlayButton = {
  button: {
    height: 76,
    width: 76,
    backgroundColor: palette.primary.second
  },

  hoverBefore: {
    opacity: '50%'
  },

  svgControls: {
    '& g:first-child': {
      '& rect': {
        fill: palette.primary.first
      }
    },
    '& g': {
      fill: palette.default.black
    }
  },

  hover: {
    height: 18,
    width: 18
  },

  lastListened: {
    height: 40,
    width: 40
  }
};

export default OverlayPlayButton;
