import { palette } from '../../palette/Palette';

const PlaylistLink = {
  link: {
    color: palette.primary.second,
    fontFamily: 'QanelasSoftBlack',
    lineHeight: 1.33,
    fontSize: 18,
    textTransform: 'uppercase'
  },
  linkHover: {
    color: palette.secondary.second
  }
};

export default PlaylistLink;
