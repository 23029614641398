import { palette } from '../palette/Palette';

const ChartListTop50Header = {
  newTrackSvg: {
    borderColor: palette.default.black
  },

  newBottomTrackSvg: {
    borderColor: palette.default.black
  },

  trackInfoOverlay: {
    color: palette.default.black
  },

  trackInfoBackground: {
    backgroundColor: palette.default.white
  },

  trackInfoText: {
    backgroundColor: palette.default.black,
    color: palette.default.white,
    textTransform: 'uppercase',

    '& div:nth-of-type(1)': {
      paddingBottom: 10
    }
  },

  trackInfoTextSm: {
    '& div:nth-of-type(1)': {
      paddingBottom: 5
    }
  },

  arrow: {
    '& g': {
      fill: palette.default.black
    }
  },

  bar: {
    backgroundColor: palette.default.black
  },

  previous: {
    color: palette.default.black,
    borderColor: palette.default.black
  },

  bottomPrevious: {
    color: palette.default.black,
    borderColor: palette.default.black
  },

  nmbOfWeeks: {
    color: palette.default.black,
    borderColor: palette.default.black
  },

  bottomNmbOfWeeks: {
    color: palette.default.black,
    borderColor: palette.default.black
  },

  bottomPositionInfo: {
    backgroundColor: palette.default.white,
    color: palette.default.black
  },

  bottomInfoText: {
    backgroundColor: palette.default.black,
    color: palette.default.white,
    textTransform: 'uppercase',

    '& div:nth-of-type(1)': {
      paddingBottom: 10
    }
  },

  bottomIconControlPlaying: {
    '& svg': {
      fill: palette.primary.first
    }
  },

  bottomNumberBiggestJump: {
    backgroundColor: palette.primary.first
  },

  iconControlPlaying: {
    '& svg': {
      fill: palette.primary.first
    }
  },

  bottomPreviousBorderP: {
    borderColor: palette.default.black
  },

  previousBorderH3: {
    borderColor: palette.default.black
  },

  bottomInfoTextSm: {
    '& div:nth-of-type(1)': {
      paddingBottom: 5
    }
  },

  bottomNumber: {
    backgroundColor: palette.default.black
  },

  bottomNumberNew: {
    backgroundColor: palette.primary.second
  },

  trackInfoBar: {
    backgroundColor: palette.default.black,
    color: palette.default.white
  },

  progressFirstTrack: {
    backgroundColor: palette.primary.first
  },

  progress: {
    backgroundColor: palette.primary.first
  },

  progressGrey: {
    backgroundColor: palette.grey.third
  },

  fastForwardSvg: {
    borderColor: palette.default.black
  },

  fastForwardFirstSvg: {
    borderColor: palette.default.black
  },

  reenteringIcon: {
    fill: palette.primary.second
  },

  bottomReenteringWrapper: {
    borderColor: palette.default.black
  },

  playWhite: {
    '& rect': {
      fill: palette.default.white
    },
    '& g': {
      fill: palette.default.black
    }
  }
};

export default ChartListTop50Header;
