import React from 'react';

import { palette } from '@theme/palette/Palette';

const iconStop = (
  <>
    <g fill={palette.primary.first}>
      <rect width="64" height="64" rx="32" />
      <rect fill={palette.primary.first} width="64" height="64" rx="32" />
      <rect opacity=".302" width="64" height="64" rx="32" />
    </g>
    <g transform="translate(18.875 18.875)" fill={palette.default.black}>
      <rect width="26.25" height="26.25" rx="2" />
      <rect fill={palette.default.black} width="26.25" height="26.25" rx="2" />
      <rect opacity=".302" width="26.25" height="26.25" rx="2" />
    </g>
  </>
);

export default iconStop;
