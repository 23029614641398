import { palette } from '../palette/Palette';

const UniversalVotingShare = {
  instagramButton: {
    backgroundColor: palette.primary.first,
    '& p': {
      textTransform: 'uppercase',
      color: palette.default.black,
      paddingLeft: 12
    },
    borderRadius: 39
  },

  hoverInstagramButtons: {
    '&:hover': {
      backgroundColor: palette.secondary.first
    }
  },

  iconShareInstagram: {
    '& g': {
      fill: palette.default.black
    }
  },

  iconShareInstagramSm: {
    width: 14,
    height: 16
  }
};

export default UniversalVotingShare;
